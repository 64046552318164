<template>

  <section class="container ask">
    <NormalHeader :languageBtn="true" />
    <div class="conts">
      <div class="wapper" >
        <h2>{{ $t('약관_동의') }}</h2>
        <blockquote v-if="getInquiryYn" v-html="$t('병원은_중증질환_고난이도_감염관리의_전문성_강화_및_환자_증상_모니터링을_위해_이용자의_개인정보,_민감정보를_수집·이용합니다')" />
        <blockquote v-else v-html="$t('병원은_중증질환_고난이도_감염관리의_전문성_강화_및_환자_증상_모니터링을_위해_이용자의_개인정보를_수집·이용합니다.')" />
          <AgreeTerms ref="terms" @input="checkNext" />
          <div class="btn_area">
            <button :class="{ disabled: !canNext }" @click="goNext">
              {{ $t('다음') }}
            </button>
          </div>
      </div>
    </div>
  </section>

</template>

<script>
  // import { mapGetters, mapState } from 'vuex';
  import NormalHeader from '../../components/v2/NormalHeader.vue';
  import AgreeTerms from '../../components/v2/AgreeTerms.vue';
  import {mapActions, mapGetters, mapState} from "vuex";
  export default {
    name: 'infoAgree',
    components: {
      NormalHeader,
      AgreeTerms,
    },
    props: {
      fixed: {
        type: Boolean,
        default: true
      },
      ruleText: {
        type: Boolean,
        default: false
      },
      pvc: {
        type: String,
        default: null
      },
      ssc: {
        type: String,
        default: null
      },
    },
    computed: {
      ...mapState(['USER']),
      ...mapGetters(['getUserType', 'getLanguage', 'getInquiryYn', 'getCnnHome']),
    },
    data() {
      return {
        canNext: false,
        inqType: null,
        identityNo: null,
        visitDate: null,
        refId: null,
      };
    },
    async created(){
      try {
        this.refId = this.$route.query.refId ? window.atob(this.$route.query.refId) : null
        this.inqType = window.atob(this.$route.query.itype);
        this.identityNo = window.atob(this.$route.query.idn);
        this.visitDate = window.atob(this.$route.query.vd);
        console.log(this.inqType, this.identityNo, this.visitDate);
        const response = await this.InquiryUseCheck({inqType: this.inqType, ptno: this.identityNo, vd: this.visitDate});
        this.inqType = response.data.result.masterId;
      } catch (e) {
        await this.$router.replace({
          name: "error",
          params: {
            titleText: "비정상적인_접근_입니다"
          }
        });
      }
    },
    methods: {
      ...mapActions(['InquiryUseCheck']),
      checkNext(status) {
        console.log(status)
        this.canNext = status
      },
      useInquiry(){  // 문진 사용
        const {
          privacyCode,
          sensitiveCode
        } = this.$refs.terms.getTermsCode();

        const param = {};
        if(this.getUserType === 'EMP') {
          param.type = this.getUserType
        }

        this.$router.push({
          name: 'inquiry',
          query: {
            type: this.$route.query.type,
            itype: window.btoa(this.inqType),
            idn: window.btoa(this.identityNo),
            vd: this.visitDate ? window.btoa(this.visitDate) : null,
            pid: this.$route.query.pid,
            peid: this.$route.query.peid, //협력업체 pid값, uuid이므로 이전페이지에서 값 넘어올 때 암호화 하지 않기때문에 그대로 넘긴다.
            pvc: privacyCode ? window.btoa(privacyCode) : null,
            ssc: sensitiveCode ? window.btoa(sensitiveCode) : null,
            pidn: this.refId ?  window.btoa(this.refId) : null,
            vcnt: this.$route.query.vcnt ? this.$route.query.vcnt : null,
          },
          params : param
        });
      },
      async noInquiry(){  // 문진 미사용
        try {
          this.$modal.loading(true);
          let conn = this.getCnnHome;
          const {
            privacyCode,
            sensitiveCode
          } = this.$refs.terms.getTermsCode();
          const response = await this.$axios.post('/api/pre/v1/no-answer', {
            identityNo: this.identityNo,
            deviceType: 'M',
            answerType: window.atob(this.$route.query.type),
            visitDate: this.visitDate,
            refId: this.refId,
            completePlace: this.getLanguage === 'ko' || this.getLanguage === 'en',
            inqType: this.inqType,
            privacyCode: privacyCode,
            sensitiveCode: sensitiveCode,
            partnerEmpId: this.$route.query.peid ? this.$route.query.peid : null,
            pid: this.$route.query.pid || null,
            kioskType: null,
            conn: conn ? conn : null,
            vistCntAES: this.$route.query.vcnt ? this.$route.query.vcnt:null,
          });

          switch (response.data.resultCd) {
            case 300:
            case 201:
              this.$router.replace({
                name: 'passForbiden',
                query: {
                  pass: response.data.result.passCheck,
                },
              });
              break;
            case 0:
              // 출입증 발급
              if (this.answerType === this.USER.EMP ||  this.answerType === this.USER.RNOK ) {
          // 내부 직원은 결과에 상관없이 항상 완료페이지로 이동
                this.$router.replace({
                  name: 'thankyou',
                  query: {
                    pass: response.data.result.passCheck,
                  },
                });
              } else {
                this.$router.replace({
                  name: 'pass',
                  query: {
                    pass: response.data.result.passCheck,
                    isDirect: 'N',
                    inqType: this.inqType
                  },
                });
              }
            
              break;
            default:
              throw new Error('정의되지 않은 resultCd 오류');
          }
        } catch (error) {
          this.$router.replace({
            name: 'error',
            params: {
              titleText: this.$t('유효하지_않은_접근입니다'),
            },
          });
        } finally {
          this.$modal.loading(false);
        }
      },
      async goNext() {
        if(!this.canNext) return
        if(this.getInquiryYn) {
          this.useInquiry();
        } else {
          await this.noInquiry();
        }
      },
    },
    mounted() {

    },
  };
</script>
<style >
:lang(ja) .wapper {
  word-break: break-all;
}
</style>

