var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pop_full"},[_c('div',{staticClass:"wrap"},[_c('h2',{domProps:{"innerHTML":_vm._s(_vm.$t('문진표를_재작성하시겠습니까'))}}),_c('div',{staticClass:"pop_conts",domProps:{"innerHTML":_vm._s(
        _vm.$t(
          '문진표_재작성을_원하시는_경우_아래_재작성_버튼을_눌러주세요' +
            '_저장된_문진_기록을_삭제하고_문진표를_새로_작성하게_됩니다'
        )
      )}}),_c('div',{staticClass:"pop_conts_bottom",domProps:{"innerHTML":_vm._s(
        _vm.$t(
          '문진표를_허위로_작성할_경우_보건_관련_' +
            '법률_형법_등에_의해_처벌_또는_과태료_1천만원_처분_대상이_될_수_있습니다'
        )
      )}}),_c('div',{staticClass:"btn_area"},[_c('div',{staticClass:"btn outlined",on:{"click":_vm.closePopup}},[_vm._v(_vm._s(_vm.$t('취소')))]),_c('div',{staticClass:"btn",on:{"click":_vm.okBtn}},[_vm._v(_vm._s(_vm.$t('재작성')))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }