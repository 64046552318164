<template>
  <div class="router-view" :style="{ paddingBottom: nativeCallpadding }">
    <router-view></router-view>
    <AlertWithIcon />
    <AlertWithoutIcon />
    <LoadingSpinner />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AlertWithIcon from './components/v2/modal/AlertWithIcon.vue';
import AlertWithoutIcon from './components/v2/modal/AlertWithoutIcon.vue';
import LoadingSpinner from './components/v2/modal/LoadingSpinner.vue';
export default {
  components: {
    AlertWithIcon,
    AlertWithoutIcon,
    LoadingSpinner,
  },
  computed: {
    ...mapState(['USER']),
    ...mapGetters(['getUserType']),
    nativeCallpadding() {
      return this.getUserType === this.USER.EMP ? '60px' : '0px';
    },
  },
};
</script>

<style>
@import './assets/css/common.css';
.router-view {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>