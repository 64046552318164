<template>
  <div class="pop_bg" style="display: block">
    <div id="pop_GuideAdd" class="pop pop_md">
      <!-- 신규직원 등록 팝업 -->
      <div class="pop_body">
        <div class="pop_url">
          <h2>
            {{ $t('직원_추가_등록') }}
            <a
              href="javascript:void(0);"
              class="btn_pop_close"
              @click="closePop"
              >{{ $t('닫기') }}</a
            >
          </h2>
          <p>
            {{
              $t('등록한_번호로_병원_출입증_발급_링크를_자동으로_발송합니다')
            }}
          </p>
          <div class="staff_name">
            <input type="text" :placeholder="$t('이름')" v-model="name" />
          </div>
          <div class="num_wrap">
            <div class="num_select">
              <select v-model="prePhoneNum">
                <option>010</option>
                <option>011</option>
                <option>017</option>
                <option>018</option>
                <option>019</option>
              </select>
            </div>
            <div class="num_input">
              <input
                type="number"
                placeholder="76543210"
                v-model="postPhoneNum"
              />
            </div>
          </div>
          <div class="btn_area">
            <div
              class="btn"
              id="btn_pop_close"
              :class="{ disabled: !phoneNumChk || !nameChk }"
              @click="addPartnerEmployee"
            >
              {{ $t('확인') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    partnerEmpId: String,
    partnerGroupId: String,
  },
  data: () => {
    return {
      prePhoneNum: '010',
      postPhoneNum: '',
      name: '',
    };
  },
  computed: {
    phoneNumChk() {
      return this.postPhoneNum !== '' && this.postPhoneNum.length > 6;
    },
    nameChk() {
      return this.name !== '';
    },
  },
  methods: {
    async addPartnerEmployee() {
      if (!this.phoneNumChk || !this.nameChk) return;
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post('/api/v1/identity/pemp-add', {
          partnerEmpId: this.partnerEmpId,
          partnerGroupId: this.partnerGroupId,
          partnerEmpName: this.name,
          phoneNum: `${this.postPhoneNum === '' ? '' : this.prePhoneNum}${
            this.postPhoneNum
          }`,
        });
        // if (!response.data.success) throw new Error('error');
        switch (response.data.resultCd) {
          case 308:
            // 해당 업체에 해당번호로 기등록된 사용자가 있습니다
              const duplicateList = response.data.result;
              if(duplicateList.length > 0) {
                let msg = this.$t('해당번호는_이미_등록된_사용자_입니다');
                msg += '<br/> 업체명 : ' + duplicateList[0].groupName;
                msg += '<br/> 이름 : ' + duplicateList[0].empName;
                this.$modal.error(msg);
              } else {
                this.$modal.error(this.$t('해당번호는_이미_등록된_사용자_입니다'));
              }
            break;
          case 401:
            // 권한 없음
            this.$modal.error(this.$t('직원정보_등록_권한이_없습니다'));
            break;
          case 0:
            // 정상 -> Reload
            this.$emit('update');
            this.closePop();
            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$modal.loading(false);
        this.clearInput();
      }
    },
    clearInput() {
      this.prePhoneNum = '010';
      this.postPhoneNum = '';
      this.name = '';
    },
    closePop() {
      this.$emit('closePopup');
    },
  },
  watch: {
    postPhoneNum: function (newVal) {
      // 숫자만 최대 8자리
      this.postPhoneNum = newVal.replace(/[^0-9]/gi, '').substring(0, 8);
    },
  },
};
</script>

<style scoped>
.btn_area {
  position: relative;
}
</style>