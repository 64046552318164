import axios from 'axios';

const Request = {
  install(Vue) {
    const request = axios.create();
    // interceptor
    request.interceptors.request.use(
      function(config) {
        Vue.prototype.$modal.loading(true);
        return config;
      },
      function(error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
    request.interceptors.response.use(
      function(response) {
        Vue.prototype.$modal.loading(false);
        // if (!response.data.success) throw new Error('error');
        return response;
      },
      function(error) {
        Vue.prototype.$modal.loading(false);
        console.log(error);
        return Promise.reject(error);
      }
    );
    const requests = {
      async api_v1_inquiry({ answerType, language }) {
        return await request.get('/api/v1/inquiry', {
          params: { answerType, language }
        });
      }
    };

    Vue.prototype.$request = requests;
  }
};

export default Request;
