<template>
  <!-- 방문력 -->
  <div>
    <h3>
      {{ questionContents }}
      <p>{{ categoryDesc }}</p>
      <!-- <p>아래 장소를 읽고 해당되는 방문력이 있다면 "해당 있음"을 눌러주세요.</p> -->
    </h3>
    <ul>
      <table>
        <template v-for="(item, index) in placeList">
          <tr
            v-if="isThIndex(item.local1, index)"
            :key="`${item.historyId}_th`"
          >
            <th>
              {{
                getLanguage === 'ko' || !item.localEn1
                  ? item.local1
                  : item.localEn1
              }}
            </th>
          </tr>
          <tr :key="`${item.historyId}_tr`" @click="onClick($event, item)">
            <td :class="{ selected: isIndvdCheck && item.placeDate !== null }">
              <span v-if="newBadgeChk(item)" class="label_new">{{
                $t('신규')
              }}</span>
              <span v-if="item.grade === 1" class="label_important">{{
                $t('중요')
              }}</span>
              {{ tdTextValue(item) }}
              <div
                v-if="item.placeDate !== null && isIndvdCheck"
                class="pic_date"
                @click.stop="showSelectDatePopup(item)"
              >
                {{ picDateFormat(item.placeDate) }}
              </div>
              <div v-if="isIndvdCheck" class="chk"></div>
            </td>
          </tr>
        </template>
      </table>

      <!-- 버튼 영역 -->
      <!-- 개별입력이 체크된 경우 -->
      <li v-if="isIndvdCheck" class="btn_area">
        <div
          :class="[
            !initState && !existCheckedContents ? 'btn_chk' : 'btn_unchk',
          ]"
          @click="allCheck(false)"
        >
          <i></i
          ><!-- 체크모양 아이콘 -->
          {{ $t('해당없음') }}
        </div>
      </li>
      <!-- 개별입력이 체크되지 않은 경우 -->
      <li v-else class="btn_area type2">
        <div
          class="btn"
          :class="{ outlined: initState || !existCheckedContents }"
          @click="allCheck(true)"
        >
          <i></i
          ><!-- 체크모양 아이콘 -->
          {{ $t('해당있음') }}
        </div>
        <div
          class="btn"
          :class="{ outlined: initState || existCheckedContents }"
          @click="allCheck(false)"
        >
          <i></i
          ><!-- 체크모양 아이콘 -->
          {{ $t('해당없음') }}
        </div>
      </li>
    </ul>
    <DatePickerPopup
      v-if="datePickerPopup"
      @closeDatePickerPopup="closeDatePickerPopup"
      :selectDateTarget="selectDateTarget"
    />
  </div>
</template>

<script>
import moment from 'moment';
import lodash from 'lodash';
import { mapGetters, mapState } from 'vuex';
import DatePickerPopup from '../../../components/v2/popup/DatePickerPopup.vue';

export default {
  components: {
    DatePickerPopup,
  },
  props: {
    contents: Object,
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initState: true,
      selectDateTarget: null,
      placeList: [],
      datePickerPopup: false,
      btnLock: false,
    };
  },
  computed: {
    ...mapState(['USER', 'InqType']),
    ...mapGetters(['getUserType', 'getLanguage']),
    isIndvdCheck() {
      return this.contents.indvdCheck === true;
    },
    questionContents() {
      if (this.contents.langQuestion && this.contents.langQuestion !== '')
        return this.contents.langQuestion;
      else if (
        this.contents.questionContents &&
        this.contents.questionContents !== ''
      )
        return this.contents.questionContents;
      else return this.$t('장소_방문력');
    },
    categoryDesc() {
      if (
        this.contents.langCategoryDesc &&
        this.contents.langCategoryDesc !== ''
      )
        return this.contents.langCategoryDesc;
      else if (this.contents.categoryDesc && this.contents.categoryDesc !== '')
        return this.contents.categoryDesc;
      else return '';
    },
    existCheckedContents() {
      for (const item of this.placeList) {
        if (item.placeDate !== null) return true;
      }
      return false;
    },
  },
  mounted() {
    if (this.isUpdate) {
      this.initState = false;
    }
    if (this.contents.placeList !== null) {
      this.placeList = lodash.cloneDeep(this.contents.placeList);
    }
  },
  methods: {
    closeDatePickerPopup() {
      this.datePickerPopup = false;
    },
    saveData() {
      // 데이터 입력 체크
      if (this.initState) return false;
      // this.contents.placeList = this.placeList.filter(
      //   (item) => item.placeDate !== null
      // );
      this.contents.placeList = this.placeList;
      return true;
    },
    newBadgeChk(item) {
      const today = moment(moment().format('YYYYMMDD'));
      const updDate = moment(moment(item.updDate).format('YYYYMMDD'));
      const regDate = moment(moment(item.regDate).format('YYYYMMDD'));
      return (
        (item.updDate && today <= updDate.add(1, 'day')) ||
        (item.regDate && today <= regDate.add(1, 'day'))
      );
    },
    picDateFormat(str) {
      return moment(str).format('M/D');
    },
    tdTextValue(item) {
      if (this.getLanguage === 'ko') {
        return `${item.local2 === '-' ? '' : item.local2} ${
          item.local3 === '-' ? '' : item.local3
        } ${item.storeName} (${item.boundaryDate})`;
      } else {
        const loc2 =
          item.localEn2 && item.localEn2 !== '' && item.localEn2 !== '-'
            ? item.localEn2
            : `${item.local2 === '-' ? '' : item.local2}`;
        const loc3 =
          item.localEn3 && item.localEn3 !== '' && item.localEn3 !== '-'
            ? item.localEn3
            : `${item.local3 === '-' ? '' : item.local3}`;
        const storeNm =
          item.storeNameEn && item.storeNameEn !== ''
            ? item.storeNameEn
            : item.storeName;

        return `${loc2} ${loc3} ${storeNm} (${item.boundaryDate})`;
      }
    },
    isThIndex(text, index) {
      for (let i = 0; i < this.placeList.length; i++) {
        if (text === this.placeList[i].local1) {
          return i === index ? true : false;
        }
      }
      return false;
    },
    showSelectDatePopup(content) {
      this.selectDateTarget = content;
      this.datePickerPopup = true;
    },
    onClick(_e, content) {
      // 개별입력 이 아닌 경우에는 아무것도 하지 않음
      if (!this.isIndvdCheck) return;

      this.initState = false;
      if (content.placeDate === null) {
        this.showSelectDatePopup(content);
      } else {
        content.placeDate = null;
      }
    },
    onClear() {
      this.allCheck(false);
    },
    allCheck(input) {
      let check = input;
      if (check) {
        check = moment().format('YYYY-MM-DD');
      } else {
        check = null;
      }
      this.initState = false;
      for (const item of this.placeList) {
        item.placeDate = check;
      }
    },
  },
  watch: {
    existCheckedContents: function (newV) {
      // check = true / false 셋팅 시 연관질문 정보 업데이트 필요
      if (this.contents.targetCategoryId !== null) {
        this.$emit('setShow', {
          targetCategoryId: this.contents.targetCategoryId,
          isShow: newV,
          turnOfftargetCategoryId: null,
        });
      }
    },
  },
};
</script>
<style scoped>
.question_list .btn_area {
  position: relative;
  padding: 10px 20px 20px 20px;
  display: flex;
}
</style>
