<template>
  <div class="pop_full">
    <div class="wrap">
      <h2 v-html="$t('문진표를_재작성하시겠습니까')" />
      <div
        class="pop_conts"
        v-html="
          $t(
            '문진표_재작성을_원하시는_경우_아래_재작성_버튼을_눌러주세요' +
              '_저장된_문진_기록을_삭제하고_문진표를_새로_작성하게_됩니다'
          )
        "
      ></div>
      <div
        class="pop_conts_bottom"
        v-html="
          $t(
            '문진표를_허위로_작성할_경우_보건_관련_' +
              '법률_형법_등에_의해_처벌_또는_과태료_1천만원_처분_대상이_될_수_있습니다'
          )
        "
      ></div>
      <div class="btn_area">
        <div @click="closePopup" class="btn outlined">{{ $t('취소') }}</div>
        <div @click="okBtn" class="btn">{{ $t('재작성') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckReInquiryPopup',
  methods: {
    closePopup() {
      this.$emit('closePopup');
    },
    okBtn() {
      this.$emit('okBtn');
    },
  },
};
</script>

<style scoped>
.pop_full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f6f6f6;
  display: flex;
  justify-content: center;
}
:lang(ja) .pop_full{
  word-break: break-all;
}
.pop_full h2 {
  margin-bottom: 10px;
  font-size: 30px;
  color: #006769;
  font-family: 'NEXONLv1GothicB';
  line-height: 1.2;
}
.pop_full .wrap {
  position: relative;
  margin-top: 60px;
  padding: 0 30px;
  width: 100%;
  max-width: 440px;
  background: #f6f6f6;
}
.btn_area {
  padding: 0 0 20px 0;
  display: flex;
  width: calc(100% - 60px);
  max-width: 440px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  left: auto;
}
.btn_area:last-child .outlined {
  margin-top: 0;
  margin-right: 10px;
}
.pop_conts_bottom {
  position: absolute;
  bottom: 80px;
  width: calc(100% - 60px);
  max-width: 440px;
  margin: 0 auto;
  justify-content: center;
}
</style>