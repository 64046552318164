<template>
  <section class="container ask">
    <NormalHeader :languageBtn="true" />
    <div class="conts">
      <div class="wapper">
        <h2>{{ $t('방문목적_선택') }}</h2>

        <div class="purpose_list">
          <ul>
            <h3 class="chk selected">{{ $t('기타방문') }}</h3>
          </ul>
        </div>
        <div class="btn_area">
          <div class="btn" @click="goNext">{{ $t('다음') }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import NormalHeader from '../../components/v2/NormalHeader.vue';
import { mapGetters, mapState, mapMutations } from 'vuex';
export default {
  components: {
    NormalHeader,
  },
  props: {
    identityNo: String,
    privacyCode: String,
    sensitiveCode: String,
    visitDate: String,
  },
  computed: {
    ...mapState(['USER']),
    ...mapGetters(['getUserType', 'getInquiryYn', 'getLanguage', 'getKioskType', 'getCnnHome']),
    
  },
  methods: {
    ...mapMutations(['setUserType']),
    async goNext() {
      if (this.btnLock) return;
      this.btnLock = true;
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post(
          '/api/v2/identity/gst-list/tmp',
          {
            phoneNum: this.identityNo,
            visitDate: this.visitDate,
          }
        );
        // if (!response.data.success) throw new Error('Error');
        if(response.data.result.answerType == "WGST") {
          this.setUserType(this.USER.WGST);
        }

        switch (response.data.resultCd) {
          
          case 201:
            // 증상있음 출입 금지.
            this.$router.replace({
              name: 'passForbiden',
              query: {
                pass: response.data.result.passCheck,
              },
            });
            break;
          case 200:
            //방문일에 해당하는 문진을 이미 완료했을 경우 출입증 페이지로 이동한다.
            this.$router.replace({
              name: 'pass',
              query: {
                pass: response.data.result.passCheck,
                isDirect: 'N',
              },
            });
            break;
          case 301:
            // api호출일 기준으로 해당 방문예약이 잡혀있지 않음
            this.$router.replace({ name: 'error' });
            break;
          case 0:
            const res = response.data.result;
             //문진을 사용하던 안하던 일단 agreeTerm페이지 이동 2023-06-29
             this.$router.replace({
              name: 'infoAgree',
              query: {
                type: window.btoa(response.data.result.answerType),
                itype: window.btoa(response.data.result.inquiryType),
                idn: window.btoa(response.data.result.identityNo),
                vd: window.btoa(this.getUserType===this.USER.TMP? res.visitDate : (this.visitDate ? this.visitDate : res.visitDate)),
              },
            });
            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        console.log(error);
        this.$router.replace({ name: 'error' });
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    
  },
};
</script>
