<template>
  <section class="container ask">
    <NormalHeader :languageBtn="true" />
    <pid-check v-if="!pidCheck" :identityNo="identityNo" @check="setPidCheck" />
    <div v-else class="conts">
      <div class="wapper">
        <h2>{{ $t('출입등록') }}</h2>
        <blockquote v-html="$t('본인의_휴대전화_번호를_입력해주세요')" />
        <div class="ask_conts">
          <dl>
            <dd>
              <div class="num_box">
                <input
                  type="number"
                  placeholder="01012345678"
                  v-model="identityNo"
                  pattern="\d*"
                  maxlength="11"
                  :readonly="inputLock"
                />
              </div>
            </dd>
          </dl>
        </div>
        <!-- 수집 동의 -->
        <!-- <Terms ref="terms" v-model="isTermChecked" /> -->
      </div>
      <div class="btn_area">
        <div class="btn" :class="{ disabled: !canNext }" @click="submit">
          {{ $t('확_인') }}
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import NormalHeader from '../../components/v2/NormalHeader.vue';
// import Terms from '../../components/v2/Terms.vue';
import PidCheck from '../../components/v2/PidCheck.vue';
import moment from 'moment';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name:'PhoneNum',
  components: {
    // Terms,
    NormalHeader,
    PidCheck,
  },
  data: () => {
    return {
      isTermChecked: true, // 약관 동의 여부(2개)
      identityNo: null,
      visitDate: null,
      refId: null,
      inputLock: false, // identityNo 값을 URL에 가지고 들어오면 Lock
      btnLock: false,
      pidCheck: true,
      date_in: null,
    };
  },
  computed: {
    ...mapState(['USER']),
    ...mapGetters(['getUserType', 'getLanguage', 'getKioskType', 'getCnnHome','getUserType']),
    canNext: function () {
      return (
        // 전화번호 정상 여부 확인
        this.identityNo !== null &&
        this.identityNo !== '' &&
        this.identityNo.length > 9 &&
        // 약관 동의 여부확인
        this.isTermChecked
      );
    },
  },
  async beforeMount() {
    //test URL : http://localhost:8080/#/phone-num?idn=MDEwNzMwMDMzMTE=&vd=MjAyMTEwMTk=&refId=ODc2NTQzMjE=
    if(this.getUserType == null){
      if(this.$route.query.type) {
        this.setUserType(window.atob(this.$route.query.type || 'NOK'))
      } else {
        this.setUserType(this.USER.NOK);
      }
    }
    // idn = MDEwNzMwMDMzMTE=
    this.identityNo = this.$route.query.idn
      ? window.atob(this.$route.query.idn)
      : null;
    if (this.identityNo) {
      this.inputLock = true;
      this.pidCheck = false;
    }
    // vd = MjAyMTA3Mjg=
    this.visitDate = this.$route.query.vd
      ? window.atob(this.$route.query.vd)
      : null;
    if (this.visitDate < moment().format('YYYYMMDD')) {
      this.$router.replace({ name: 'error' });
    }
    // refId = ODc2NTQzMjE=
    this.refId = this.$route.query.refId
      ? window.atob(this.$route.query.refId)
      : null;
  },
  watch: {
    identityNo: function (newVal) {
      this.identityNo = newVal.replace(/[^0-9]/g, '').substring(0, 11);
    },
  },
  methods: {
    ...mapMutations(['setUserType']),
    setPidCheck() {
      this.pidCheck = true;
    },
    async submit() {
      if (!this.canNext || this.btnLock) return;
      this.btnLock = true;

      try {
        const response = await this.$axios.post('/api/v1/identity/nok', {
          identityNo: this.identityNo,
          answerType: this.getUserType,
          visitDate: this.visitDate,
          refId: this.refId,
          vistCntAES : this.$route.query.vcnt
        });
        // if (!response.data.success) throw new Error('error');
        // const { privacyCode, sensitiveCode } = this.$refs.terms.getTermsCode();
        switch (response.data.resultCd) {
          case 201:
            // 증상있음 출입 금지.
            this.$router.push({
              name: 'passForbiden',
              query: {
                pass: response.data.result.passCheck,
              },
            });
            break;
          case 200:
            // 방문일에 해당하는 문진을 이미 완료했을 경우 출입증 페이지로 이동한다.
            
            this.$router.push({
              name: 'pass',
              query: {
                pass: response.data.result.passCheck,
                isDirect: 'N',
              },
            });
            break;
          case 307:
            // 다른 보호자가 입장 중입니다.
            // this.date_in = new Date(response.data.result.pubPassTime);
            // if(this.getInquiryYn){
            //   this.$modal.error(this.$t('원내_출입_가능한_보호자는_환자당_1인으로_제한됩니다',{'0':`${this.date_in.getHours().toString().padStart(2, "0")}`, '1':`${this.date_in.getMinutes().toString().padStart(2, "0")}`}));
            // } else {
            //   this.$modal.error(this.$t('원내_출입_가능한_보호자는_환자당_1인으로_제한됩니다',{'0':`${this.date_in.getHours().toString().padStart(2, "0")}`, '1':`${this.date_in.getMinutes().toString().padStart(2, "0")}`}));
            // }
            this.$modal.error(this.$t('허용_가능한_동반보호자_수를_초과하여_출입이_불가합니다'));
            break;
          case 0:
              //문진을 사용하던 안하던 일단 agreeTerm페이지 이동 2023-06-29
             this.$router.replace({
              name: 'infoAgree',
              query: {
                type: window.btoa(this.getUserType),
                itype: window.btoa(response.data.result.inquiryType),
                idn: window.btoa(this.identityNo),
                vd: window.btoa(this.visitDate),
                pid: this.$route.query.pid,
                refId: window.btoa(this.refId),
                vcnt: response.data.result.vistCntAES ? response.data.result.vistCntAES:null,
              },
            });
            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        this.$router.replace({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_접근입니다'),
          },
        });
      } finally {
        this.btnLock = false;
      }
    },


  },
};
</script>
<style scoped>
.num_box input[type='number'] {
  width: 100%;
  margin-top: 20px;
  font-size: 1.4em;
  border: none;
  border-bottom: 2px solid #cccccc !important;
  text-align: center;
  font-family: 'NEXONLv1GothicB';
  background: #f6f6f6;
  color: #333333;
}
.num_box input[type='number']::placeholder {
  color: #ccc;
}
.num_box input[type='number']:focus {
  color: #333333;
  border-bottom: 2px solid #509d9c !important;
}
.ask blockquote {
  width: 100%;
  line-height: 1.4;

  font-weight: 300;
  letter-spacing: 0px;
}
.ask .ask_conts {
  margin-top: 25px;
  min-height: 350px;
}
.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.ask dt {
  margin-bottom: 10px;
  font-size: 1em;
  color: #006769;
  font-weight: bold;
}
.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.btn_no {
  float: left;
  width: 48% !important;
  color: #006769 !important;
  border: 1px solid #006769 !important;
  background: #fff !important;
}
.btn_yes {
  float: left;
  margin-right: 4%;
  width: 48% !important;
  color: #fff !important;
  background-color: #006769 !important;
}
.ask dd li {
  padding: 0 0 15px 15px;
  line-height: 1.3;
  background: url('../../assets/images/ico_dot.svg') no-repeat;
  background-size: 1%;
  background-position: 3px 9px;
}
.ask dd li:last-child {
  padding-bottom: 30px;
}
.ask dd li p {
  font-size: 0.8em;
  line-height: 1.3;
}

.btn_area .outlined {
  margin-top: 15px;
  border: 1px solid #006769;
  color: #006769;
  background: #ffffff;
}
.btn_area .btn:last-child {
  margin-top: 10px;
}
</style>