<template>
  <section class="container ask">
    <NormalHeader :languageBtn="true" />
    <div class="conts">
      <div class="wapper">
        <h2>{{ $t('출입등록') }}</h2>
        <blockquote v-if=" getUserType === USER.NOK || getUserType == USER.ENOK"
          v-html=" $t('동행할_환자의_병원등록번호_또는_휴대전화번호_생년월일을_입력해주세요')" />
        <blockquote v-else-if="getUserType === USER.PT"
          v-html="$t('병원등록번호_또는_휴대전화번호_생년월일을_입력해주세요')" />
        <blockquote v-else
          v-html="$t('방문할_환자의_병원등록번호_또는_휴대전화번호_생년월일을_입력해주세요')" />
        <div class="num_box">
          <div class="tabs">
            <div
              @click="switchTap($event, 'pid')"
              :class="{ selected: isPidTab }"
              class="item"
            >
              {{ $t('병원등록번호') }}
            </div>
            <div
              @click="switchTap($event, 'birth')"
              :class="{ selected: !isPidTab }"
              class="item"
              v-html="$t('휴대전화번호_생년월일_병원등록번호찾기')"
            />
          </div>
          <div v-if="isPidTab" id="num_id_cont" class="patient_cont">
            <input
              type="tel"
              placeholder="12345678"
              :readonly="isPidLock"
              v-model="identityNo"
              @keyup.enter="goSelectPopup"
            />
          </div>
          <div v-else id="num_pati_cont" class="patient_cont">
            <input type="tel" placeholder="010-1234-5678" v-model="phoneNum" />
            <input
              type="tel"
              class="small-placeholder"
              :placeholder="`${$t('생년월일')}(YYYYMMDD)`"
              v-model="birthDay"
            />
          </div>
        </div>
        <!-- <Terms
          v-if="getUserType !== USER.NOK && getUserType !== USER.ENOK"
          ref="terms"
          v-model="isTermChecked"
        /> -->
        <div class="btn_area">
          <button :class="{ disabled: !canNext }" @click="goSelectPopup">
            {{ $t('확_인') }}
          </button>
        </div>
      </div>
    </div>
    <SelectPopup
      v-if="popup.selectPopup"
      :userList="popup.selectPopupUserList"
      :byPid="isPidTab"
      @okBtn="goNext"
      @closePopup="closePopup"
    />
  </section>
</template>
<script>
// import Terms from '../../components/v2/Terms.vue';
import NormalHeader from '../../components/v2/NormalHeader.vue';
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import moment from 'moment';
import debounce from 'lodash/debounce';
import SelectPopup from '../../components/v2/popup/SelectPopup.vue';

export default {
  name: 'patient',
  components: {
    // Terms,
    NormalHeader,
    SelectPopup,
  },
  data: () => {
    return {
      isPidTab: true,
      isPidLock: false, // url query에 pid 를 달고 들어온 경우 true로 셋팅되면 입력필드 제한됨
      visitDate: null, //방문예정일
      isTermChecked: true, // 약관 동의 여부(2개)
      identityNo: null, //환자번호
      phoneNum: '', // 휴대전화번호
      birthDay: '', // 생년월일 YYYYMMDD
      popup: {
        selectPopup: false,
        selectPopupUserList: [],
      },
      answerType: null, //
    };
  },
  computed: {
    ...mapState(['USER']),
    ...mapGetters(['getUserType']),
    canNext: function () {
      if (this.isPidTab) {
        // 병원등록번호로 인증
        return (
          // 병원등록번호 정상 여부 확인
          this.identityNo !== null &&
          this.identityNo !== '' &&
          this.identityNo.length > 7 &&
          // 약관 동의 여부확인
          (this.getUserType === this.USER.NOK ||
            this.getUserType === this.USER.ENOK ||
            this.isTermChecked)
        );
      } else {
        return (
          // 핸드폰 번호 입력값 확인
          this.phoneNum !== null &&
          this.phoneNum !== '' &&
          this.phoneNum.length > 9 &&
          /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/.test(
            this.phoneNum
          ) &&
          // 생년월일 입력값 확인
          this.birthDay !== null &&
          this.birthDay !== '' &&
          this.birthDay.length === 8 &&
          moment(this.birthDay).format() !== 'Invalid date' &&
          // 약관 동의 여부 확인
          (this.getUserType === this.USER.NOK ||
            this.getUserType === this.USER.ENOK ||
            this.isTermChecked)
        );
      }
    },
  },
  async mounted() {
    if (this.getUserType === null) {
      if(this.$route.query.type) {
        this.setUserType(window.atob(this.$route.query.type || 'PT'))
      } else {
        this.setUserType(this.USER.PT);
      }
    }
    if (this.$route.query.ptno) {
      this.identityNo = window.atob(this.$route.query.ptno);
      this.isPidLock = true;
    }
    this.visitDate = this.$route.query.vd
      ? window.atob(this.$route.query.vd)
      : null;
    if (!this.visitDate || this.visitDate < moment().format('YYYYMMDD')) {
      this.$router.replace({ name: 'error' });
    }
  },
  watch: {
    identityNo: function (newVal) {
      this.identityNo = newVal
        .replace(/[ㄱ-힣~!@#$%^&*()_+|<>?:{}= ]/g, '')
        .substring(0, 8);
    },
    phoneNum: function (newVal) {
      this.phoneNum = this.phoneFormatter(
        newVal.replace(/[^0-9]/g, '').substring(0, 11)
      );
    },
    birthDay: function (newVal) {
      this.birthDay = newVal.replace(/[^0-9]/g, '').substring(0, 8);
    },
    isPidTab: function () {
      this.identityNo = '';
      this.phoneNum = '';
      this.birthDay = '';
    },
  },
  methods: {
    ...mapMutations(['setUserType']),
    async goSelectPopup() {
      if(this.isPidLock && this.isPidTab){  // pid 있는 링크로 접속했을 경우 본인확인 팝업 X
        this.goNext(this.identityNo);
      } else {      
        if (!this.canNext) return;
        if (this.isPidTab) await this.apiCheckPatient();
        else this.apiIdentityPhonebirth();
      }
    },
    switchTap(_e, type) {
      if (type === 'pid') this.isPidTab = true;
      else if (type === 'birth' && !this.isPidLock) this.isPidTab = false;
    },
    // 환자 번호가 존재하는지 체크 후 팝업 노출 Method
    apiCheckPatient: debounce(
      async function () {
        this.$modal.loading(true);
        try {
          
          const  response = await this.$axios.post(
            '/api/v1/identity/check-patient',
            {
              identityNo: this.identityNo,
            });
            
            switch (response.data.resultCd) {
              case 300:
                // 300 : 병원등록번호로 조회된 환자없음
                this.$modal.error(
                  `${this.$t(
                    '병원등록번호가_확인되지_않습니다'
                  )}<br /><br />${this.$t('병원등록번호를_다시_확인해주세요')}`
                );
                break;
              case 0:
                // 정상 조회 완료 (팝업노출)
                this.showSelectPopup([
                  {
                    patNum: response.data.result.patNo,
                    ptName: response.data.result.patNm,
                    birth: response.data.result.patBirth,
                  },
                ]);
                break;
              default:
                throw new Error('정의되지 않은 resultCd 오류');
            }
         
        } catch (error) {
          this.goError();
          console.log(error);
        } finally {
          this.$modal.loading(false);
        }
      },
      1000,
      { leading: true, trailing: false }
    ),
    async apiIdentityPhonebirth() {
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post(
          '/api/v1/identity/phone-birth',
          {
            phoneNum: this.phoneNum.replace(/-/g, ''),
            birth: this.birthDay,
          }
        );
        // if (!response.data.success) throw new Error("error");
        switch (response.data.resultCd) {
          case 0:
          case 303:
            // 0 : 생년월일+전화번호로 조회된 환자 1명
            // 303 : 생년월일로 조회된 환자 여러명
            this.showSelectPopup(
              response.data.result.map(({ patNum, ptName, birth }) => ({
                patNum: patNum,
                ptName: ptName,
                birth: birth,
              }))
            );
            break;
          case 300:
            // 300 : 생년월일+전화번호로 조회된 환자없음
            this.$modal.error(
              `${this.$t(
                '병원등록번호가_확인되지_않습니다'
              )}<br /><br />${this.$t('병원등록번호를_다시_확인해주세요')}`
            );
            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        this.$modal.error();
        console.log(error);
      } finally {
        this.$modal.loading(false);
      }
    },
    showSelectPopup(userList) {
      this.popup.selectPopupUserList = userList;
      this.popup.selectPopup = true;
    },
    closePopup() {
      this.popup.selectPopup = false;
    },
    goError() {
      this.$router.push({
        name: 'error',
        params: {
          titleText: '진료_일정이_확인되지_않습니다',
        },
      });
    },
    goNext: debounce(
        async function (patientId) {
          this.identityNo = patientId;
          try {
            let response = {}
            if (this.getUserType == this.USER.ICUGST) {
              response = await this.$axios.post("/api/v1/identity/check-icu-patient", {
                refId: this.identityNo,
                answerType: this.getUserType,
                visitDate: moment().format("YYYYMMDD"),
              })
            } else {
              response = await this.$axios.post('/api/v3/identity/patient', {
                identityNo: patientId,
                answerType: this.getUserType,
                visitDate: this.visitDate,
              });

            }
            switch (this.getUserType) {
              case this.USER.PT:
                switch (response.data.resultCd) {
                  case 301:
                    // 방문일에 조회된 환자 없음 오류
                    this.goError();
                    break;
                  case 201:
                    // 증상있음 출입 금지.
                    this.$router.push({
                      name: 'passForbiden',
                      query: {
                        pass: response.data.result.passCheck,
                      },
                    });
                    break;
                  case 200:
                    //방문일에 해당하는 문진을 이미 완료했을 경우 출입증 페이지로 이동한다.
                    this.$router.push({
                      name: 'pass',
                      query: {
                        pass: response.data.result.passCheck,
                        isDirect: 'N',
                        inqType: response.data.result.inquiryType
                      },
                    });
                    break;
                  case 0:
                    this.$router.replace({
                      name: 'infoAgree',
                      query: {
                        type: window.btoa(this.getUserType),
                        itype: window.btoa(response.data.result.inquiryType),
                        idn: window.btoa(this.identityNo),
                        vd: window.btoa(this.visitDate),
                        pid: this.$route.query.pid,

                      },
                    });
                    break;
                }
                break;
              case this.USER.NOK:
              case this.USER.ICUGST:
                this.goPhone(response, patientId);
                break;
              default:
                throw new Error('정의되지 않은 UserType 오류');
            }
          } catch (error) {
            this.goError();
            console.log(error);
          } finally {
            this.closePopup();
          }
        },
        1000,
        {leading: true, trailing: false}
    ),
    async goInquiry(response) {
      // const { privacyCode, sensitiveCode } = this.$refs.terms.getTermsCode();
      // const { sensitiveCheck } = this.$refs.terms.getSensitiveChecked();

      switch (response.data.resultCd) {
        case 301:
          // 방문일에 조회된 환자 없음 오류
          this.goError();
          break;
        case 201:
          // 증상있음 출입 금지.
          this.$router.push({
            name: 'passForbiden',
            query: {
              pass: response.data.result.passCheck,
            },
          });
          break;
        case 200:
          //방문일에 해당하는 문진을 이미 완료했을 경우 출입증 페이지로 이동한다.
          this.$router.push({
            name: 'pass',
            query: {
              pass: response.data.result.passCheck,
              isDirect: 'N',
              inqType: response.data.result.inquiryType,
            },
          });
          break;
        case 0:
          //문진을 사용하던 안하던 일단 agreeTerm페이지 이동 2023-06-26
          this.$router.push({
            name: 'infoAgree',
            query: {
              type: window.btoa(this.getUserType),
              itype: window.btoa(response.data.result.inquiryType),
              idn: window.btoa(this.identityNo),
              vd: window.btoa(this.visitDate),
              pid: this.$route.query.pid,
            },
          });
          break;
        default:
          throw new Error('정의되지 않은 resultCd 오류');
      }
    },
    goPhone(response, refId) {
      switch (response.data.resultCd) {
        case 301:
          // 방문일에 조회된 환자 없음 오류
          this.goError();
          break;
        case 311: //중환자실 재원 정보가 없습니다.
          this.$modal.error(
            `${this.$t(
              "환자_재원정보가_확인되지_않습니다"
            )}<br /><br />${this.$t("병원등록번호를_다시_확인해주세요")}`
          );
          break;
        case 201:
        case 200:
        case 0:
          // 201 - 환자가 증상있음
          // 200 - 환자가 문진 기완료
          this.$router.push({
            name: 'phoneNum',
            query: {
              refId: window.btoa(refId),
              vd: window.btoa(this.visitDate),
              vcnt: response.data.result.vistCntAES ? response.data.result.vistCntAES:null,
              type: window.btoa(this.getUserType),
            },
          });
          break;
        default:
          throw new Error('정의되지 않은 resultCd 오류');
      }
    },
    phoneFormatter(num, type) {
      let formatNum = '';
      try {
        if (num.length === 11) {
          if (type === 0) {
            formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
          } else {
            formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
          }
        } else if (num.length === 8) {
          formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2');
        } else {
          if (num.indexOf('02') === 0) {
            if (type === 0) {
              formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
            } else {
              formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
            }
          } else {
            if (type === 0) {
              formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
            } else {
              formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
            }
          }
        }
      } catch (e) {
        formatNum = num;
      }
      return formatNum;
    },
  },
};
</script>

<style scoped>
.num_box input[type='tel'] {
  width: 100%;
  margin-top: 20px;
  border: none;
  border-bottom: 2px solid #cccccc !important;
  background: #f6f6f6;
  text-align: center;
  color: #333333;
}
.num_box input[type='tel']:focus {
  color: #333333;
  border-bottom: 2px solid #509d9c !important;
}
</style>