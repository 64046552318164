<template>
  <div id="header">
    <div v-if="languageBtn" class="lang_select">
      <i class="ico_lang" />
      <select v-model="selectedLocale">
        <option value="ko">{{ $t('LANGUAGE', 'ko') }}</option>
        <option value="en">{{ $t('LANGUAGE', 'en') }}</option>
        <option value="ja">{{ $t('LANGUAGE', 'ja') }}</option>
        <option value="zh">{{ $t('LANGUAGE', 'zh') }}</option>
        <option value="ru">{{ $t('LANGUAGE', 'ru') }}</option>
        <option value="vi">{{ $t('LANGUAGE', 'vi') }}</option>
        <option value="ar">{{ $t('LANGUAGE', 'ar') }}</option>
        <option value="mn">{{ $t('LANGUAGE', 'mn') }}</option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
export default {
  props: {
    languageBtn: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      selectedLocale: 'ko',
    };
  },
  computed: {
    ...mapState(['language']),
  },
  methods: {
    ...mapMutations(['setLanguage']),
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    if (this.language !== '') this.selectedLocale = this.language;
  },
  watch: {
    selectedLocale: function (newV) {
      this.$i18n.locale = newV;
      this.setLanguage(this.$i18n);
    },
  },
};
</script>
<style scoped>
.ask .lang_select {
    left: 20px;  
    right: unset;
}

</style>