<template>
  <section class="container ask">
    <NormalHeader :languageBtn="true" />
    <div class="conts">
      <div class="wapper">
        <h2>{{ $t('출입등록') }}</h2>
        <blockquote v-html="$t('사번을_입력해주세요')" />
        <div class="ask_conts">
          <dl>
            <dd>
              <div class="num_box">
                <input
                  type="text"
                  placeholder="1234567"
                  v-model="empNo"
                  :readonly="inputLock"
                />
              </div>
            </dd>
          </dl>
        </div>
        <!-- 수집 동의 -->
<!--        <Terms ref="terms" v-model="isTermChecked" />-->
        <div class="btn_area">
          <div
            class="btn"
            @click="submit"
            :class="{ disabled: !this.canNext || this.btnLock }"
          >
            {{ $t('확_인') }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import NormalHeader from '../../components/v2/NormalHeader.vue';
import Terms from '../../components/v2/Terms.vue';
// import moment from 'moment';
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';

export default {
  components: {
    NormalHeader,
  },
  data: () => {
    return {
      isTermChecked: false, // 약관 동의 여부(2개)
      empNo: null,
      visitDate: null,
      inputLock: false, // identityNo 값을 URL에 가지고 들어오면 Lock
      btnLock: false,
    };
  },
  computed: {
    ...mapState(['USER']),
    ...mapGetters(['getUserType', 'getInquiryYn']),
    canNext: function () {
      return (
        // 전화번호 정상 여부 확인
        this.empNo !== null &&
        this.empNo !== '' &&
        this.empNo.length === 7
      );
    },
  },
  async mounted() {
    //test URL : http://localhost:8080/#/emp?empNo=RVQwMDYwNQ==&vd=MjAyMTA3Mjg=
    this.setUserType(this.USER.EMP);
    await this.InquiryUseCheck();
    if(!this.getInquiryYn){
      this.$modal.error(
          `${this.$t(
              "문진 시행기간이 아닙니다."
          )}`
      );
    }
    // empNo = RVQwMDYwNQ==
    // this.empNo = this.$route.query.empNo
    //   ? window.atob(this.$route.query.empNo).toUpperCase()
    //   : null;
    // if (this.empNo) this.inputLock = true;
    // // vd = MjAyMTA3Mjg=
    // this.visitDate = this.$route.query.vd
    //   ? window.atob(this.$route.query.vd)
    //   : moment().format('YYYYMMDD');
    // if (this.visitDate < moment().format('YYYYMMDD')) {
    //   this.$router.replace({ name: 'error' });
    // }
    this.decodeEmpDate();
  },
  methods: {
    ...mapMutations(['setUserType']),
    ...mapActions(['InquiryUseCheck']),

    async decodeEmpDate() {
      try{
          const response = await this.$axios.post('/api/v1/identity/find-emp', {
            patNo: this.$route.query.empNo,
            visitDate: this.$route.query.date
          });
          switch (response.data.resultCd) {
            case 0:
              this.empNo = response.data.result.patNo;
              this.visitDate = response.data.result.visitDate;
              break;

            case 401:
              console.log(response.data.resultMsg);
              this.$router.replace({
                name: 'error',
                params: {
                  titleText: this.$t('접속이 만료되었습니다. 다시 시도 부탁드립니다.'),
                },
              });
              break;
            default:
              console.log(response.data.resultMsg);
              this.$router.replace({
                name: 'error',
                params: {
                  titleText: this.$t('유효하지_않은_접근입니다'),
                },
              });
          }

      } catch (error) {
        console.log(error);
        this.$router.replace({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_접근입니다'),
          },
        });
      } finally {
        this.btnLock = false;
      }
    },

    async submit() {

      //문진시행이 아니면 문진 할 수 없게 한다.
      if(!this.getInquiryYn){
        this.$modal.error(
            `${this.$t(
                "문진 시행기간이 아닙니다."
            )}`
        );
        return;
      }

      if (!this.canNext || this.btnLock) return;
      this.btnLock = true;

      try {
        const response = await this.$axios.post('/api/v1/identity/emp', {
          identityNo: this.empNo,
          answerType: this.getUserType,
          visitDate: this.visitDate,
        });
        // if (!response.data.success) throw new Error('error');
        switch (response.data.resultCd) {
          case 300:
            // 존재하지 않는 사번 오류
            this.$modal.error(
              this.$t('출입_권한이_없거나_입력하신_사번이_확인되지_않습니다')
            );
            return;
          case 201:
            // 증상있음 출입 금지.
            this.$router.push({
              name: 'passForbiden',
              query: {
                pass: response.data.result.passCheck,
              },
            });
            break;
          case 200:
            // 방문일에 해당하는 문진을 이미 완료했을 경우 출입증 페이지로 이동한다.
            this.$router.push({
              name: 'pass',
              query: {
                pass: response.data.result.passCheck,
                isDirect: 'N',
              },
            });
            break;
          case 0:
            //정보동의로 이동.
            this.$router.push({
              name: 'infoAgree',
              query: {
                type: window.btoa(this.getUserType),
                itype: window.btoa(response.data.result.inquiryType),
                idn: window.btoa(this.empNo),
                vd: window.btoa(this.visitDate),
              },
            });
            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        console.log(error);
        this.$router.replace({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_접근입니다'),
          },
        });
      } finally {
        this.btnLock = false;
      }
    },
  },
  watch: {
    empNo: function (newVal) {
      this.empNo = newVal
        .substring(0, 7)
        .replace(/[ㄱ-힣~!@#$%^&*()_+|<>?:{}= ]/g, '')
        .replace(/[A-Za-z0-9]{11}/g, '')
        .toUpperCase();
    },
  },
};
</script>
<style scoped>
.num_box input[type='text'] {
  width: 100%;
  margin-top: 20px;
  font-size: 1.4em;
  border: none;
  border-bottom: 2px solid #cccccc !important;
  text-align: center;
  font-family: 'NEXONLv1GothicB';
  background: #f6f6f6;
  color: #333333;
}
.num_box input[type='text']::placeholder {
  color: #ccc;
}
.num_box input[type='text']:focus {
  color: #333333;
  border-bottom: 2px solid #509d9c !important;
}


.btn_no {
  float: left;
  width: 48% !important;
  color: #006769 !important;
  border: 1px solid #006769 !important;
  background: #fff !important;
}
.btn_yes {
  float: left;
  margin-right: 4%;
  width: 48% !important;
  color: #fff !important;
  background-color: #006769 !important;
}
.ask dd li {
  padding: 0 0 15px 15px;
  line-height: 1.3;
  background: url('../../assets/images/ico_dot.svg') no-repeat;
  background-size: 1%;
  background-position: 3px 9px;
}
.ask dd li:last-child {
  padding-bottom: 30px;
}
.ask dd li p {
  font-size: 0.8em;
  line-height: 1.3;
}

.error_page h2 {
  height: 110px;
  line-height: 70px;
  padding-top: 30px;
  background: url('../../assets/images/ico_error.svg') no-repeat;
}
.btn_area .btn {
  width: 100%;
  height: 40px;
  font-size: 1em;
  color: #fff;
  background: #006769;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'NEXONLv1GothicB';
}
.btn_area .outlined {
  margin-top: 15px;
  border: 1px solid #006769;
  color: #006769;
  background: #ffffff;
}
.btn_area .btn:last-child {
  margin-top: 10px;
}
</style>