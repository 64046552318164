import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../view/Home.vue';
import PhonePatient from '../view/PhonePatient.vue';
import patient from '../view/inqtype/patient.vue';
import phoneNum from '../view/inqtype/phoneNum.vue';
import RnokAuth from '../view/inqtype/RnokAuth.vue';
import EmerAuth from '../view/inqtype/EmerAuth.vue';
import employee from '../view/inqtype/employee.vue';
import GuestNew from '../view/inqtype/Guest_New.vue';
import GuestList_ETC from '../view/inqtype/GuestList_ETC.vue';
import GuestList_PHONE from '../view/inqtype/GuestList_PHONE.vue';
import GuestList_CODE from '../view/inqtype/GuestList_CODE.vue';
import Pemployee from '../view/inqtype/Pemployee.vue';
import inquiry from '../view/inquiry/inquiry.vue';
import EmerInvestigation from '../view/inquiry/EmerInvestigation.vue';
import pass from '../view/pass/pass.vue';
import passForbiden from '../view/info/passForbiden.vue';
import passForbidenNormal from '../view/info/passForbiden_normal.vue';
import thankyou from '../view/info/thankyou.vue';
import Error from '../view/info/error.vue';
import RetryInfo from '../view/info/RetryInfo.vue';
import EmployeeManage from '../view/info/EmployeeManage.vue';
import infoAgree from '../view/inquiry/infoAgree.vue'
import InquiryInfo from '../view/inquiry/InquiryInfo.vue';
import InquiryInfoEmma from '../view/inquiry/InquiryInfoEmma.vue';

import InquiryResearch from '../view/inqtype/normal_inq.vue';
import inquiryNoPass from '../view/inquiry/inquiry_no_pass.vue';
import thankyouNoPass from '../view/info/normal_thankyou.vue';

Vue.use(VueRouter);

export const router = new VueRouter({
    // mode: 'history',
    routes: [
        { name: 'home', path: '/', component: Home }, // 홈페이지 진입점 페이지 (링크 최초 진입 페이지)
        { name: 'homeChart', path: '/chart', component: Home }, // 내손안의차트 진입점 (링크 최초 진입 페이지)
        { name: 'phonePt', path: '/phone-pt', component: PhonePatient }, // 환자 모바일출입증 발급 페이지 (링크 최초 진입 페이지)
        { name: 'pt', path: '/pt', component: patient },

        { name: 'nok', path: '/nok', component: patient }, // 보호자 진입 경로
        { name: 'phoneNum', path: '/phone-num', component: phoneNum }, // 보호자 진입 경로

        { name: 'nok', path: '/nok', component: patient }, // 면회객 진입 경로
        { name: 'phoneNum', path: '/phone-num', component: phoneNum }, // 면회객 진입 경로

        { name: 'rnokPhone', path: '/rnok', component: PhonePatient }, // 상주보호자 모바일출입증 발급 페이지 (링크 최초 진입 페이지)
        { name: 'rnokAuth', path: '/rnok-auth', component: RnokAuth },
        { name: 'emerAuth', path: '/emer-auth', component: EmerAuth }, // 긴급방문력 Direct URL 진입 시 본인인증(뒷4자리)을 위해 사용되는 화면

        { name: 'emp', path: '/emp', component: employee }, // 내부직원 진입 페이지

        { name: 'guest', path: '/gst/grpo', component: GuestNew }, // 집합행사 및 방문객 진입 경로
        {
            name: 'guestEtc',
            path: '/gst/etc',
            component: GuestList_ETC,
            props: true
        },
        {
            name: 'guestPhone',
            path: '/gst/phone',
            component: GuestList_PHONE,
            props: true
        },
        {
            name: 'guestCode',
            path: '/gst/code',
            component: GuestList_CODE,
            props: true
        },
        { name: 'pempAuth', path: '/pemp', component: Pemployee }, // 협력업체 진입 경로

        // 문진 정보 동의 화면
        { name: 'infoAgree', path: '/info-agree', component: infoAgree },

        // 문진 작성 화면
        { name: 'inquiry', path: '/inquiry', component: inquiry },

        { name: 'inquiryNoPass', path: '/inq', component: inquiryNoPass },
        {
            name: 'inquiryPreview',
            path: '/inquiry/:refreshIdx',
            component: inquiry
        }, // 미리보기용 URL
        {
            name: 'emerInvestigation',
            path: '/emer-invst',
            component: EmerInvestigation,
            props: true
        },
        {
            name: 'emerInvestigation_preview',
            path: '/emer-invst/:refreshIdx',
            component: EmerInvestigation
        },

        // 작성 결과 화면
        { name: 'pass', path: '/pass', component: pass }, // 출입증 화면
        { name: 'passForbiden', path: '/pass-forbiden', component: passForbiden }, // 증상있음 출입불가 화면
        { name: 'passForbidenNormal', path: '/pass-forbiden-normal', component: passForbidenNormal }, // 일반문진표 증상있음 출입불가 화면        
        { name: 'thankyou', path: '/thankyou', component: thankyou }, // 임직원 상주보호자 용 종료화면
        { name: 'thankyouNoPass', path: '/thankyou-normal', component: thankyouNoPass },
        { name: 'error', path: '/error', component: Error, props: true }, // 에러 처리 화면
        { name: 'retryInfo', path: '/retryInfo', component: RetryInfo }, // 문진 재작성 완료 화면

        // 협력업체 관리 메뉴
        {
            name: 'employeeManage',
            path: '/emp-manage',
            component: EmployeeManage,
            props: true
        },
        // 환자상세 (기등록문진 조회/변경 용 화면)
        {
            name: 'inquiryInfo',
            path: '/inquiry-info',
            component: InquiryInfo
        },
        // 응급환자/응급보호자 상세 (기등록문진 조회/변경 용 화면)
        {
            name: 'inquiryInfoEmma',
            path: '/inquiry-info-emma',
            component: InquiryInfoEmma
        },
        //출입증 발급이 없는 일반 문진 (장례식장, 융합연구관 등)    
        {
            name: 'inquiryResearch',
            path: '/inquiry-research/:inqCode',
            component: InquiryResearch
        }
    ]
});