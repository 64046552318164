<template>
  <section class="container ask">
    <NormalHeader :languageBtn="true" />
    <div class="conts">
      <div class="wapper">
        <h2>{{ $t('방문목적_선택') }}</h2>
        <!-- 집합행사 or 기타 -->
        <div v-if="!getWithTalk || getCnnHome === 'C' " class="purpose_list">
          <blockquote>{{ $t('방문목적을_선택해주세요') }}</blockquote>

          <div class="purpose_list">
            <ul>
              <h3
                class="chk"
                :class="{ selected: selectedMeetingType === 'code' }"
                @click="selectType('code')"
              >
                {{ $t('집합행사') }}
              </h3>
              <li
                v-if="
                  selectedMeetingType === 'code' && meetingInfo.length === 0
                "
              >
                {{ $t('금일_예정된_집합_행사가_없습니다') }}
              </li>
              <div class="list-wrapper" v-if="selectedMeetingType === 'code'">
                <li
                  v-for="item in meetingInfo"
                  :key="item.meetingId"
                  @click="selectMeeting(item)"
                  class="chk"
                  :class="{
                    selected:
                      selectedMeeting &&
                      selectedMeeting.meetingId === item.meetingId,
                  }"
                >
                  {{ item.meetingName }}
                </li>
              </div>
            </ul>
            <ul @click="selectType('etc')">
              <h3
                class="chk"
                :class="{ selected: selectedMeetingType === 'etc' }"
              >
                {{ $t('기타방문') }}
              </h3>
            </ul>
          </div>
        </div>

        <div v-else class="purpose_list">
          <div class="purpose_list">
            <ul>
              <h3 class="chk selected">{{ $t('집합행사') }}</h3>

              <div class="list-wrapper">
                <li v-if="meetingInfo.length === 0">
                  {{ $t('금일_예정된_집합_행사가_없습니다') }}
                </li>
                <li
                  v-for="item in meetingInfo"
                  :key="item.meetingId"
                  @click="selectMeeting(item)"
                  class="chk"
                  :class="{
                    selected:
                      selectedMeeting &&
                      selectedMeeting.meetingId === item.meetingId,
                  }"
                >
                  {{ item.meetingName }}
                </li>
              </div>
            </ul>
          </div>
        </div>
        <div class="btn_area">
          <div class="btn" :class="{ disabled: !canNext }" @click="goNext">
            {{ $t('다음') }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import NormalHeader from '../../components/v2/NormalHeader.vue';
import { mapGetters, mapState, mapMutations } from 'vuex';
import moment from 'moment';

export default {
  name: 'guestList_Phone',
  components: {
    NormalHeader,
  },
  props: {
    identityNo: String,
    privacyCode: String,
    sensitiveCode: String,
    meetingInfo: Array,
  },
  data() {
    return {
      selectedMeeting: null,
      selectedMeetingType: null,
      visitDate: null,
    };
  },
  computed: {
    ...mapState(['USER']),
    ...mapGetters(['getUserType', 'getWithTalk','getCnnHome', 'getLanguage', 'getKioskType']),
    canNext() {
      if (this.selectedMeetingType === null) return false;
      if (this.selectedMeetingType === 'code' && this.selectedMeeting === null)
        return false;
      return true;
    },
  },
  mounted() {
    this.visitDate = this.$route.query.vd
      ? window.atob(this.$route.query.vd)
      : moment().format('YYYYMMDD');
    if (this.visitDate < moment().format('YYYYMMDD')) {
      this.$router.replace({ name: 'error' });
    }
    this.getWithTalk && this.selectType('code');
  },
  methods: {
    ...mapMutations(['setUserType']),

    goNext() {
      if (this.btnLock || !this.canNext) return;

      // 기타방문자
      if (this.selectedMeetingType === 'etc') this.goNext_ETC();
      // 공개 집합행사
      else if (
        this.selectedMeetingType === 'code' &&
        this.selectedMeeting &&
        this.selectedMeeting.openYn === 'Y'
      ) {
        this.$router.replace({
          name: 'guestCode',
          params: {
            identityNo: this.identityNo,
            privacyCode: this.privacyCode,
            sensitiveCode: this.sensitiveCode,
            meetingItem: this.selectedMeeting,
            visitDate: this.visitDate,
          },
        });
      }
      // 비공개 집합행사
      else if (
        this.selectedMeetingType === 'code' &&
        this.selectedMeeting &&
        this.selectedMeeting.openYn === 'N'
      ) {
        this.goNext_OPEN_N();
      }
    },
    selectMeeting(item) {
      this.selectedMeeting = item;
    },
    selectType(item) {
      if (item === 'code') {
        if (this.meetingInfo.length > 0) {
          this.selectedMeeting = this.meetingInfo[0];
        }
      } else if (item === 'etc') {
        this.selectedMeeting = null;
      }
      this.selectedMeetingType = item;
    },
    async goNext_OPEN_N() {
      if (this.btnLock) return;
      try {
        this.btnLock = true;
        this.$modal.loading(true);
        const response = await this.$axios.post(
          '/api/v2/identity/gst-list/gst',
          {
            phoneNum: this.identityNo,
            meetingId: this.selectedMeeting.meetingId,
          }
        );
        // if (!response.data.success) throw new Error('error');
        switch (response.data.resultCd) {
          case 201:
            // 증상있음 출입 금지.
            this.$router.replace({
              name: 'passForbiden',
              query: {
                pass: response.data.result.passCheck,
              },
            });
            break;
          case 200:
            //방문일에 해당하는 문진을 이미 완료했을 경우 출입증 페이지로 이동한다.
            this.$router.replace({
              name: 'pass',
              query: {
                pass: response.data.result.passCheck,
                isDirect: 'N',
              },
            });
            break;
          case 301:
          case 101:
            // 101 요청한 번호가 미팅아이디에 없음.
            // 301 api호출일 기준으로 해당 행사진행 예정일에 해당되지 않음. ??무슨case?
            this.$router.replace({ name: 'error' });
            break;
          case 0:
            const res = response.data.result;
            this.$router.replace({
              name: 'infoAgree',
              query: {
                type: window.btoa(res.answerType),
                itype: window.btoa(res.inquiryType),
                idn: window.btoa(res.identityNo),
                // 기타방문자인가 비공개 집합행사인가에 따라 보내는 visitDate가 다름
                vd: window.btoa(this.getUserType===this.USER.TMP? res.visitDate
                    : (this.visitDate ? this.visitDate : res.visitDate)),
                pid: this.$route.query.pid,

              },
            });
            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        console.log(error);
        this.$router.replace({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_접근입니다'),
          },
        });
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    async goNext_ETC() {
      if (this.btnLock) return;
      this.btnLock = true;
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post(
          '/api/v2/identity/gst-list/tmp',
          {
            phoneNum: this.identityNo,
            visitDate: this.visitDate,
          }
        );
        // if (!response.data.success) throw new Error('Error');
        if(response.data.result.answerType == "WGST") {
          this.setUserType(this.USER.WGST);
        }

        switch (response.data.resultCd) {
          case 201:
            // 증상있음 출입 금지.
            this.$router.replace({
              name: 'passForbiden',
              query: {
                pass: response.data.result.passCheck,
              },
            });
            break;
          case 200:
            //방문일에 해당하는 문진을 이미 완료했을 경우 출입증 페이지로 이동한다.
            this.$router.replace({
              name: 'pass',
              query: {
                pass: response.data.result.passCheck,
                isDirect: 'N',
              },
            });
            break;
          case 301:
            // api호출일 기준으로 해당 방문예약이 잡혀있지 않음
            this.$modal.error(`${this.$t('방문_신청_내용이_확인되지_않습니다')}
                <br /><br />${this.$t('방문담당부서에_문의해_주세요')}`);
            break;
          case 0:
            const res = response.data.result;
           this.$router.push({
              name: 'infoAgree',
              query: {
                type: window.btoa(res.answerType),
                itype: window.btoa(res.inquiryType),
                idn: window.btoa(res.identityNo),
                vd: window.btoa(this.getUserType===this.USER.TMP? res.visitDate : (this.visitDate ? this.visitDate : res.visitDate)),
                pid: this.$route.query.pid,
              },
            });
            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        console.log(error);
        this.$router.replace({ name: 'error' });
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },

  },
};
</script>
