import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";
import i18n from '../i18n';
import moment from 'moment';
import { router } from '../router/index.js';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        language: '',
        userType: null,
        USER: {
            PT: 'PT', // 환자
            RPT: 'RPT', // 재원환자 (긴급방문 DirectUrl 만 받는 Case, EmerInvestigation만 연관있음)
            NOK: 'NOK', // 보호자
            RNOK: 'RNOK', // 상주보호자
            GST: 'GST', // 방문자
            TMP: 'TMP', // 방문자(기타방문자)
            EMP: 'EMP', // 내부직원
            PEMP: 'PEMP', // 협력업체 직원
            EMMA: 'EPT', // 응급환자
            ENOK: 'ENOK', // 응급환자 보호자
            ICUGST: "ICUGST", // 중환자실 면회객
            WGST: "WGST", // 병동 면회객 (아직 미사용)
        },
        InqType: {
            Normal: 'N', // 일반문항(기본)
            NormalImg: 'I', // 일반문항(표/이미지)
            VisitHistory: 'H', // 방문력
            Etc: 'P', // 기타(종이문진 전용)
            MultiChoice: 'M', // 객관식형
            ShortAnswer: 'T', // 주관식형
            Editor: 'E', // 안내문구(에디터)
            Housemate: 'FS', // 동거가족 증상 (직원전용)
            HousemateRelation: 'FD', // 동거가족 - 역학적 연관성 (직원전용)
            HousemateDetail: 'FT', // 동거가족 - 주관식 상세
            OverseasVisitHistory: 'OV', //해외방문력 (응급실용)
            HospitalVisitHistory: 'HV', //타원방문력 (응급실용)
        },
        kioskType: null,
        withTalk: true, // Home 화면을 통해 진입하는 경우 false로 셋팅됨 (이외 알림톡으로 실행되는 모든 케이스는 true)
        modalDialog: false,
        cnnHome: null,
        inquiryYn: null,
        validInquiryCnt: 0,
        visitReason: null,
    },
    getters: {
        getLanguage: state => {
            return state.language === '' ? 'ko' : state.language;
        },
        isRTL: state => {
            return state.language === 'ar';
        },
        getKioskType: state => state.kioskType,
        getWithTalk: state => state.withTalk,
        getPageDepth: state => state.pageDepth,
        getUserType: state => state.userType,
        getUserTypeText: state => {
            switch (state.userType) {
                case state.USER.PT:
                    return i18n.t('환자');
                case state.USER.NOK:
                    return i18n.t('보호자');
                case state.USER.RNOK:
                    return i18n.t('보호자');
                case state.USER.GST:
                    return i18n.t('방문객');
                case state.USER.TMP:
                case state.USER.WGST:
                    if(state.visitReason){
                        return i18n.t('방문객') + '(' + state.visitReason + ')';
                    } else {
                        return i18n.t('방문객');
                    }
                case state.USER.EMP:
                    return i18n.t('직원');
                case state.USER.PEMP:
                    return i18n.t('직원');
                case state.USER.ICUGST:
                    return state.visitReason;
                case state.USER.EMMA:
                    return '응급환자'; // 실제 사용될 일은 없는 값이라 번역 미반영
                case state.USER.ENOK:
                    return '응급환자'; // 실제 사용될 일은 없는 값이라 번역 미반영
                default:
                    return '';
            }
        },
        getModalDialog: state => state.modalDialog,
        getCnnHome: state => state.cnnHome,
        getInquiryYn: state => state.inquiryYn,
        getValidInquiryCnt: (state) => state.validInquiryCnt,
    },
    mutations: {
        initUserType(state) {
            state.userType = null;
        },        
        initInquiryYn(state) {
            state.inquiryYn = false;
        },
        setKioskType(state, payload) {
            state.kioskType = payload;
        },
        setLanguage(state, payload) {
            const localeInfo = payload.locale === 'ko' ? '' : payload.locale;
            document.documentElement.setAttribute('lang', localeInfo);
            moment.locale(payload.locale);
            state.language = payload.locale;
        },
        setUserType(state, payload) {
            state.userType = payload;
        },
        setModalDialog(state, payload) {
            state.modalDialog = payload.modalDialog;
        },
        setWithTalk(state, payload) {
            state.withTalk = payload;
        },
        setCnnHome(state, payload) {
            state.cnnHome = payload;
        },
        setInquiryYn(state, payload) {
            state.inquiryYn = payload;
        },
        setValidInquiryCnt(state, payload) {
            state.validInquiryCnt = payload;
        },
        setUserTypeText(state, payload) {
            state.visitReason = payload;
        },
    },
    actions: {
        // async setup({ commit }, param) {
        //   try {
        //     commit("setModalDialog", { modalDialog: true });
        //   } catch (error) {
        //     console.log(`Store Setup Fail - ${error}`);
        //   }
        // },
        async InquiryUseCheck ({commit, state}, payload) {
            let params = {inqType: state.userType == 'RPT'? 'ADMINER' : state.userType};

            if(state.userType == 'PT' && payload) {
                params = {inqType: payload.inqType, ptNo: payload.ptno, visitDate: payload.vd};
            }

            if(state.userType == 'TMP'){
                params = {inqType: 'GST'};
            } else if(state.userType == 'PEMP') {
                params = {inqType: 'EMP'};
            }
            let response = await axios.get("/api/v1/answerUseYn", {params: params});
            if(response.data.resultCd === 0){
                console.log(response.data);
                if(response.data.result.useYn == "Y"){
                    commit('setInquiryYn', true);
                } else if(response.data.result.useYn == "N"){
                    commit('setInquiryYn', false);
                }
            } else {
                router.replace({
                    name: "error",
                    params: {
                        titleText: "비정상적인_접근_입니다"
                    }
                });
            }
            return response;
        }
    }
});

export default store;