<template>
  <section class="container ask">
    <div style="height: 30px; width: 100%" />
    <div class="conts">
      <div class="wapper">
        <h2>{{ $t('문진') }}</h2>
        <div class="question_list">
          <div v-for="(item, index) in categoryListFilter" :key="index">
            <!-- 일반문항(기본) 형 -->
            <Normal
              v-if="item.categoryType === 'N'"
              :contents="item"
              :ref="`inquiry_${index}`"
              :isUpdate="true"
              @setShow="setShow"
            />
            <!-- 일반문항(이미지) 형 -->
            <Normal
              v-else-if="item.categoryType === 'I'"
              :contents="item"
              :ref="`inquiry_${index}`"
              :isUpdate="true"
              @setShow="setShow"
            />
            <!-- 객관식 형 -->
            <MultiChoice
              v-else-if="item.categoryType === 'M'"
              :contents="item"
              :ref="`inquiry_${index}`"
              :isUpdate="true"
              @setShow="setShow"
            />
            <!-- 주관식 형 -->
            <ShortAnswer
              v-else-if="item.categoryType === 'T'"
              :contents="item"
              :isUpdate="true"
              :ref="`inquiry_${index}`"
            />
            <!-- 안내문구 형 -->
            <Editor
              v-else-if="item.categoryType === 'E'"
              :contents="item"
              :isUpdate="true"
              :ref="`inquiry_${index}`"
            />
            <!-- 방문력 형 -->
            <VisitHistory
              v-else-if="item.categoryType == 'H'"
              :ref="`inquiry_${index}`"
              :isUpdate="true"
              :contents="item"
            />
          </div>
        </div>
        <div class="btn_area scroll">
          <div class="btn" @click="submit">{{ $t('저장') }}</div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Normal from '../../components/v2/inquiryType/Normal.vue';
import VisitHistory from '../../components/v2/inquiryType/VisitHistory.vue';
import MultiChoice from '../../components/v2/inquiryType/MultiChoice.vue';
import ShortAnswer from '../../components/v2/inquiryType/ShortAnswer.vue';
import Editor from '../../components/v2/inquiryType/Editor.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'InquiryInfoEmma',
  components: {
    Normal,
    VisitHistory,
    MultiChoice,
    ShortAnswer,
    Editor,
  },
  data: () => {
    return {
      answerType: null,
      inquiryType: null,
      detailId: null,
      deviceId: null,
      privacyCode: null,
      sensitiveCode: null,
      identityNo: null,
      categoryList: [],
      newPtYn: null,
      identityBirth: null,
      identityPhone: null,
      refPhone: null,
      refBirth: null,
      refId: null,
    };
  },
  computed: {
    ...mapState(['USER', 'InqType']),
    ...mapGetters(['getUserType', 'getLanguage', 'getKioskType']),
    categoryListFilter() {
      return this.categoryList.filter(
        (item) =>
          // 동거인 관련 카테고리
          item.categoryType !== this.InqType.Housemate &&
          item.categoryType !== this.InqType.HousemateRelation &&
          // 사전문진에서 제공 안하는 카테고리
          item.categoryType !== this.InqType.Etc &&
          item.show === true
      );
    },
  },
  mounted() {
    this.$route.query.kioskType &&
      this.setKioskType(this.$route.query.kioskType); // kioskType 정보가 query에 있는경우 셋팅
    if (this.$route.query.aid) {
      this.getPassCheck(this.$route.query.aid);
    } else {
      this.$router.replace({
        name: 'error',
        params: {
          titleText: this.$t('유효하지_않은_접근입니다'),
        },
      });
    }
  },
  methods: {
    ...mapMutations(['setUserType', 'setKioskType']),
    async getPassCheck(answerId) {
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post('/api/v1/emma-answer', {
          answerId,
        });
        // if (!response.data.success) throw new Error('error');

        switch (response.data.resultCd) {
          case 302:
          case 304:
            // 유효하지 않은 answerId
            this.$router.push({
              name: 'error',
              params: {
                titleText: '유효하지 않은 문진 정보 입니다.',
              },
            });
            break;
          case 0:
            this.answerType = response.data.result.answerType;
            this.inquiryType = response.data.result.inquiryType;
            this.detailId = response.data.result.detailId;
            this.privacyCode = response.data.result.privacyCode;
            this.sensitiveCode = response.data.result.sensitiveCode;
            this.identityNo = response.data.result.identityNo;
            this.categoryList = response.data.result.categoryList;
            this.newPtYn = response.data.result.newPtYn;
            this.identityBirth = response.data.result.identityBirth;
            this.identityPhone = response.data.result.identityPhone;
            this.refPhone = response.data.result.refPhone;
            this.refBirth = response.data.result.refBirth;
            this.refId = response.data.result.refId;
            break;

          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        console.log(error);
        this.$modal.error('문진이력 조회에 실패하였습니다.');
      } finally {
        this.$modal.loading(false);
      }
    },

    async submitPreAnswer() {
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post('/api/site/v1/emma/answer', {
          answerType: this.answerType,
          inqType: this.inquiryType,
          detailId: this.detailId,
          deviceType: 'A',
          deviceId: this.deviceId,
          privacyCode: this.privacyCode,
          sensitiveCode: this.sensitiveCode,
          identityNo: this.identityNo,
          categoryList: this.categoryList,
          newPtYn: this.newPtYn,
          identityBirth: this.identityBirth,
          identityPhone: this.identityPhone,
          refPhone: this.refPhone,
          refBirth: this.refBirth,
          refId: this.refId,
          kioskType: this.getKioskType,
        });
        // if (!response.data.success) throw new Error('error');
        this.$modal.info(this.$t('저장_완료'), this.$t('저장되었습니다'));
        window.parent.postMessage({ method: 'refresh' }, '*');
      } catch (error) {
        console.log(error);
        this.$modal.error(this.$t('저장에_실패하였습니다'));
      } finally {
        this.$modal.loading(false);
      }
    },
    checkInput() {
      for (const key in this.$refs) {
        if (Object.hasOwnProperty.call(this.$refs, key)) {
          if (!key.includes('inquiry')) continue;
          const inquiryComponent = this.$refs[key][0];
          // 각 컴포넌트 별 데이터 업데이트 실행 후 true 리턴이 되는지 확인
          if (inquiryComponent?.saveData && !inquiryComponent?.saveData()) {
            inquiryComponent.$el.scrollIntoView({ behavior: 'smooth' }); // move scroll
            setTimeout(() => {
              this.$modal.error(
                this.$t('아직_응답하지_않은_질문이_남아있습니다')
              );
            }, 200);
            return false;
          }
        }
      }
      return true;
    },
    submit() {
      if (!this.checkInput()) return; // 데이터 정상 입력 여부 체크
      this.submitPreAnswer();
    },
    setShow({ targetCategoryId, isShow, turnOffTargetCategoryId }) {
      for (const item of this.categoryList) {
        if (item.categoryId === targetCategoryId) {
          item.show = isShow;
        }
        if (item.categoryId === turnOffTargetCategoryId) {
          item.show = false;
        }
      }
    },
  },
};
</script>
<style scoped="scoped">
.ask blockquote {
  width: 100%;
  line-height: 1.4;
  
  font-weight: 300;
  letter-spacing: 0px;
}
.ask .ask_conts {
  margin-top: 25px;
  min-height: 350px;
}
.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.ask dt {
  margin-bottom: 10px;
  font-size: 1em;
  color: #006769;
  font-weight: bold;
}
.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.btn_no {
  float: left;
  width: 48% !important;
  color: #006769 !important;
  border: 1px solid #006769 !important;
  background: #fff !important;
}
.btn_yes {
  float: left;
  margin-right: 4%;
  width: 48% !important;
  color: #fff !important;
  background-color: #006769 !important;
}
.ask dd li {
  padding: 0 0 15px 15px;
  line-height: 1.3;
  background: url('../../assets/images/ico_dot.svg') no-repeat;
  background-size: 1%;
  background-position: 3px 9px;
}
.ask dd li:last-child {
  padding-bottom: 30px;
}
.ask dd li p {
  font-size: 0.8em;
  line-height: 1.3;
}

.btn_area {
  position: fixed;
  bottom: 0;
  left: 0%;
  padding: 0 6% 20px 6%;
  width: 100%;
}
.btn_area .btn {
  width: 100%;
  height: 40px;
  font-size: 1em;
  color: #fff;
  background: #006769;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'NEXONLv1GothicB';
}
.btn_area blockquote {
  padding-bottom: 20px;
}
.btn_area .outlined {
  margin-top: 15px;
  border: 1px solid #006769;
  color: #006769;
  background: #ffffff;
}
.btn_area .btn:last-child {
  margin-top: 10px;
}
.btn_area.scroll {
  position: relative;
  padding: 0 0 20px 0;
}
.btn_area.inside {
  position: relative;
  padding: 0;
}

/** 문진목록 **/
.question_list {
  margin-top: 10px;
  padding-bottom: 10px;
}
.question_list h3 {
  word-break: break-all;
  margin-bottom: 5px;
  font-size: 1em;
  font-weight: bold;
  color: #222222;
}
.question_list h3 p {
  word-break: break-all;
  margin-top: 5px;
  margin-bottom: 15px;
  line-height: 1.2;
  
  font-weight: normal;
}

.question_list h5 {
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  color: #222222;
  border-bottom: 1px solid #eeeeee;
}
.question_list h5 p {
  
  font-weight: normal;
}
.question_list ul {
  width: 100%;
  margin-bottom: 20px;
  padding: 0px;
  border-radius: 11px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  overflow: hidden;
}
.question_list ul .family_q {
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
  display: none;
  border-top: 1px solid #eeeeee;
}

.question_list ul .family_q ul {
  width: 100%;
  padding: 0;
  margin: 0 auto 30px auto;
  box-shadow: none;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-radius: 0;
}
.question_list ul .family_q ul:last-child {
  border-bottom: none;
}
/* .question_list ul:last-child{box-shadow:none;background-color: #f6f6f6; } */
.question_list li {
  position: relative;
  width: 100%;
  padding: 13px 45px 13px 20px;
  
  line-height: 1.4;
  border-bottom: 1px solid #efefef;
}
.question_list li:last-child {
  border-bottom: none;
  overflow: hidden;
}
.question_list input[type='checkbox'] {
  display: none;
}
.question_list input[type='checkbox'] + label {
  padding: 10px 20px;
  background: url(../../assets/images/ico_uncheck.svg) no-repeat 94% 12px;
  cursor: pointer;
  display: block;
  vertical-align: middle;
}
.question_list input[type='checkbox']:checked + label {
  background: url(../../assets/images/ico_checked.svg) no-repeat 94% 12px
    #eaffff;
  display: block;
}
.question_list input[type='checkbox'] + label span {
  padding: 0 20px 0 0;
  width: 100%;
  display: block;
}
.question_list li button {
  margin: 10px auto;
  width: 90%;
  height: 40px;
  font-size: 1em;
  color: #fff;
  background: #006769;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.question_list li button:disabled {
  background: #cccccc;
}
.question_list .btn_area {
  position: relative;
  padding: 10px 20px 20px 20px;
  display: flex;
}
.question_list .btn_area .btn:last-child {
  margin: 15px 0 0 15px;
}
.question_list li span > span {
  font-size: 0.8em;
}
.question_list .sm_txt {
  font-size: 0.8em;
  line-height: 1.2;
}
.question_list .chk {
  position: absolute;
  top: 12px;
  right: 20px;
  width: 21px;
  height: 21px;
  background: url(../../assets/images/ico_uncheck.svg) no-repeat right;
  cursor: pointer;
  display: block;
  vertical-align: middle;
}
.question_list .selected {
  background: #eaffff;
}
.question_list .selected .chk {
  width: 21px;
  height: 21px;
  background: url(../../assets/images/ico_checked.svg) no-repeat right;
  display: block;
}
.family_chk {
  position: relative;
  padding: 13px 20px 13px 50px;
  line-height: 1.3;
  font-size: 1em;
  font-weight: bold;
  color: #006769;
}
.family_chk .chk {
  top: 12px;
  left: 20px;
  background: url(../../assets/images/ico_pri_unchecked.svg) no-repeat 0 0 !important;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}
.family_chk.selected .chk {
  top: 12px;
  left: 20px;
  background: url(../../assets/images/ico_pri_check.svg) no-repeat 0 0 !important;
  display: block;
}
</style>