<template>
  <!-- 일반문항(기본) -->
  <div v-if="contents">
    <h3>
      {{ questionContents }}
      <p>
        {{ categoryDesc }}
      </p>
    </h3>
    <ul class="multi_c">
      <li
        v-for="(item, index) in contents.inquiryList"
        :key="item.inquiryMappingId"
        :class="{ selected: selectedItem === item ,  pointer : $route.query.wide == 'Y'}"
        @click="onClick($event, index)"
      >
        <div class="radio"></div>
        <div class="txt">{{ item.langContents || item.inquiryContents }}</div>
      </li>
      <!-- <li class="btn_area"><div class="btn_unchk">해당없음</div></li>
      <li class="btn_area"><div class="btn_chk">해당없음</div></li> -->
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'MultiChoice',
  props: {
    contents: Object,
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initState: true,
      selectedItem: null,
    };
  },
  computed: {
    ...mapState(['USER', 'InqType']),
    isIndvdCheck() {
      return true;
    },
    targetCategoryIdInfo() {
      if (this.selectedItem === null) return null;
      return this.selectedItem.targetCategoryId;
    },
    questionContents() {
      if (this.contents.langQuestion && this.contents.langQuestion !== '')
        return this.contents.langQuestion;
      else if (
        this.contents.questionContents &&
        this.contents.questionContents !== ''
      )
        return this.contents.questionContents;
      else return '';
    },
    categoryDesc() {
      if (
        this.contents.langCategoryDesc &&
        this.contents.langCategoryDesc !== ''
      )
        return this.contents.langCategoryDesc;
      else if (this.contents.categoryDesc && this.contents.categoryDesc !== '')
        return this.contents.categoryDesc;
      else return '';
    },
    existCheckedContents() {
      return this.selectedItem !== null;
    },
  },
  mounted() {
    if (this.isUpdate) {
      // 기 입력정보 확인 및 업데이트 시
      for (let i = 0; i < this.contents.inquiryList.length; i++) {
        if (
          this.contents.inquiryList[i].inquiryMappingId ===
          this.contents.answerId
        ) {
          this.selectedItem = this.contents.inquiryList[i];
          this.initState = false;
        }
      }
      // 기입력된 정보를 찾지 못했다면,
      if (this.selectedItem === null) {
        this.clear();
      }
      // 연관 문항 정보 업데이트
      if (this.targetCategoryIdInfo !== null) {
        // show
        this.$emit('setShow', {
          targetCategoryId: this.targetCategoryIdInfo,
          isShow: true,
          turnOffTargetCategoryId: null, // show = false
        });
      }
    } else {
      this.clear();
    }
  },
  beforeDestroy() {
    // show : false 로 변경 시 연계된 질문 show 정보 초기화
    for (let i = 0; i < this.contents.inquiryList.length; i++) {
      const item = this.contents.inquiryList[i];
      if (item.targetCategoryId !== null) {
        this.$emit('setShow', {
          targetCategoryId: item.targetCategoryId,
          isShow: false,
        });
      }
    }
  },
  methods: {
    saveData() {
      // 데이터 입력 체크
      if (this.initState) return false;

      this.contents.answerId = this.selectedItem.inquiryMappingId;
      return true;
    },
    onClick(_e, index) {
      // 개별입력 이 아닌 경우에는 아무것도 하지 않음
      if (!this.isIndvdCheck) return;
      this.initState = false;
      this.selectedItem = this.contents.inquiryList[index];
    },
    clear() {
      this.initState = true;
      this.selectedItem = null;
      if (this.contents) this.contents.answerId = null;
    },
  },
  watch: {
    targetCategoryIdInfo: function (newV, oldV) {
      // 연관질문 정보 업데이트 필요
      // show
      this.$emit('setShow', {
        targetCategoryId: newV,
        isShow: true,
        turnOffTargetCategoryId: oldV, // show = false
      });
    },
  },
};
</script>

<style scoped>
.ask blockquote {
  width: 100%;
  line-height: 1.4;
  
  font-weight: 300;
  letter-spacing: 0px;
}
.ask .ask_conts {
  margin-top: 25px;
  min-height: 350px;
}
.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.ask dt {
  margin-bottom: 10px;
  font-size: 1em;
  color: #006769;
  font-weight: bold;
}
.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.btn_no {
  float: left;
  width: 48% !important;
  color: #006769 !important;
  border: 1px solid #006769 !important;
  background: #fff !important;
}
.btn_yes {
  float: left;
  margin-right: 4%;
  width: 48% !important;
  color: #fff !important;
  background-color: #006769 !important;
}
.ask dd li {
  padding: 0 0 15px 15px;
  line-height: 1.3;
  background: url('../../../assets/images/ico_dot.svg') no-repeat;
  background-size: 1%;
  background-position: 3px 9px;
}
.ask dd li:last-child {
  padding-bottom: 30px;
}
.ask dd li p {
  font-size: 0.8em;
  line-height: 1.3;
}

.btn_area {
  position: fixed;
  bottom: 0;
  left: 0%;
  padding: 0 6% 20px 6%;
  width: 100%;
}
.btn_area .btn {
  width: 100%;
  height: 40px;
  font-size: 1em;
  color: #fff;
  background: #006769;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'NEXONLv1GothicB';
}
.btn_area blockquote {
  padding-bottom: 20px;
}
.btn_area .outlined {
  border: 1px solid #006769;
  color: #006769;
  background: #ffffff;
}
.btn_area.scroll {
  position: relative;
  padding: 0 0 20px 0;
}
.btn_area.inside {
  position: relative;
  padding: 0;
}
.btn_area.type2 .btn:last-child {
  margin-left: 10px;
}
.pointer {
  cursor: pointer;
}
</style>