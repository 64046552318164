<template>
  <div>
    <!-- 수집 동의 -->
    <div v-if="ready" class="privacy_wrap" :style="fixedStyle">
      <p
        v-if="ruleText"
        class="rule_txt"
        v-html="
          $t(
            '본_문진표를_허위로_작성할_경우_보건_관련_법률_및_형법에_따라_처벌_또는_과태료_1천만원_가_부과됩니다_noBr'
          )
        "
      />
      <div
        @click="toggle($event, 'privacy')"
        class="chk noselect"
        :class="{ checked: privacyCheck }"
      >
        {{ $t('개인정보_수집_동의') }}
        <a
          href="javascript:void(0);"
          id="agree_detail"
          @click.stop="showModal($t('개인정보_수집_및_이용'), privacy)"
          >{{ $t('보기') }}</a
        >
      </div>
      <div v-show="this.getValidInquiryCnt > 0"
        @click="toggle($event, 'sensitive')"
        class="chk noselect"
        :class="{ checked: sensitiveCheck }"
      >
        {{ getUserType === USER.PT ? $t('민감정보_수집_동의_입원_예약환자에_한함') : $t('민감정보_수집_동의') }}
        <a
          href="javascript:void(0);"
          id="agree_detail"
          @click.stop="showModal($t('민감정보_수집_및_이용'), sensitive)"
          >{{ $t('보기') }}</a
        >
      </div>
    </div>
    <!-- Modal -->
    <TermsPopup
      v-if="modalFlag"
      :modalTitle="modalTitle"
      :modalText="modalText"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import TermsPopup from './popup/TermsPopup.vue';
export default {
  name: 'Terms',
  components: {
    TermsPopup,
  },
  props: {
    fixed: { type: Boolean, default: true },
    ruleText: { type: Boolean, default: false },
    pvc: { type: String, default: null },
    ssc: { type: String, default: null },
  },
  data() {
    return {
      ready: false,
      privacy: null,
      sensitive: null,
      privacyCode: null,
      sensitiveCode: null,
      modalFlag: false,
      modalTitle: '',
      modalText: '',
      privacyCheck: false,
      sensitiveCheck: false,
    };
  },
  computed: {
    ...mapState(['USER']),
    ...mapGetters(['getUserType', 'getLanguage', 'getValidInquiryCnt']),
    fixedStyle() {
      if (!this.fixed)
        return {
          position: 'static',
          width: '100%',
        };
      return {};
    },
  },
  mounted() {
    if (this.pvc && this.ssc) {
      // 특정 약관 조회 (props 로 약관 조회 정보가 들어온 경우)
      this.getAgreeTermById();
    } else {
      // 최신 약관 조회 시
      this.getAgreeTerm();
    }
  },
  methods: {
    toggle(_e, type) {
      if (type === 'privacy') this.privacyCheck = !this.privacyCheck;
      else if (type === 'sensitive') this.sensitiveCheck = !this.sensitiveCheck;
    },
    // ============================================================================================
    async getAgreeTerm() {
      try {
        if (!this.getUserType) return;        
        let answerType = this.getUserType;
        if (answerType === this.USER.EMMA) answerType = this.USER.PT; // 응급환자는 환자의 약관을 사용
        if (answerType === this.USER.ENOK) answerType = this.USER.NOK; // 응급보호자는 보호자의 약관을 사용
        // if (answerType === this.USER.RNOK) answerType = this.USER.NOK; // 상주보호자는 보호자의 약관을 사용
        if (answerType === this.USER.TMP) answerType = this.USER.GST; // 기타방문자는는 게스트 약관을 사용
        if (answerType === this.USER.PEMP) answerType = this.USER.EMP; // 협력업체(사번없는직원)은 내부직원 약관을 사용
        if (answerType === this.USER.RPT) answerType = this.USER.PT; // 재원환자는 환자의 약관 사용       
        const params = {
          answerType,
          language: this.getLanguage,
        };
        const response = await this.$axios.get('/api/v1/term', { params });
        this.ready = true;  
        if('ko' === this.getLanguage) {
          this.privacy = response.data.result.privacy.privacyContents;
          this.sensitive = response.data.result.sensitive.privacyContents; 
        } else {
          this.privacy = response.data.result.privacy.privacyContentsEng;
          this.sensitive = response.data.result.sensitive.privacyContentsEng;
        }    
        this.privacyCode = response.data.result.privacy.privacyCode;
        this.sensitiveCode = response.data.result.sensitive.privacyCode;
      } catch (error) {
        this.$modal.error(
          this.$t('개인정보_수집_정보를_불러오지_못하였습니다')
        );
      }
    },
    async getAgreeTermById() {
      try {
        const params = {
          privacyCode: this.pvc,
          sensitiveCode: this.ssc,
          language: this.getLanguage,
        };
        const response = await this.$axios.get('/api/v1/term-code', { params });
        this.ready = true;      
        if('ko' === this.getLanguage) {
           this.privacy = response.data.result.privacy.privacyContents;
           this.sensitive = response.data.result.sensitive.privacyContents;  
        } else {
          this.privacy = response.data.result.privacy.privacyContentsEng;
          this.sensitive = response.data.result.sensitive.privacyContentsEng; 
        }        
        this.privacyCode = response.data.result.privacy.privacyCode;
        this.sensitiveCode = response.data.result.sensitive.privacyCode;
      } catch (error) {
        this.$modal.error(
          this.$t('개인정보_수집_정보를_불러오지_못하였습니다')
        );
      }
    },
    closeModal() {
      this.modalFlag = false;
    },
    showModal(modalTitle, modalText) {
      this.modalTitle = modalTitle;
      this.modalText = modalText;
      this.modalFlag = true;
    },
    getTermsCode() {
      if (this.sensitiveCheck) {
        return {
          privacyCode: this.privacyCode,
          sensitiveCode: this.sensitiveCode,
        };
      } else {
        //문진 삭제에 따른 민감정보 수집 및 이용 내역 처리방법 변경
        return {
          privacyCode: this.privacyCode,
          sensitiveCode: null,
        };
      }
    },

    getSensitiveChecked() {
      return {
        sensitiveCheck: this.sensitiveCheck
      };
    },
  },
  watch: {
    getLanguage: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getAgreeTerm();
      }
    },
    getUserType: function () {
      this.getAgreeTerm();
    },
    privacyCheck: function (newV) {
      //민감정보 활성화 여부에 따라 값을 다르게 지정.
      if(this.getValidInquiryCnt > 0) {
        if(this.getUserType===this.USER.PT){
          this.$emit('input', newV);
        } else {
          this.$emit('input', newV && this.sensitiveCheck);
        }
      } else {
        this.$emit('input', newV);
      }
    },
    sensitiveCheck: function (newV) {
      //민감정보 활성화 여부에 따라 값을 다르게 지정.
      if(this.getValidInquiryCnt > 0) {
        if(this.getUserType===this.USER.PT){
          this.$emit('input', this.privacyCheck);
        } else {
          this.$emit("input", this.privacyCheck && newV);
        }
      } else {
        this.$emit('input', this.privacyCheck);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:lang(ru) {
  .privacy_wrap {
    width: auto;
  }
  .chk {
    width: 70%;
  }
  .rule_txt {
    font-size: 12px;
  }
}
:lang(mn) {
  .privacy_wrap {
    width: auto;
  }
  .chk {
    width: 70%;
  }
}
</style>

<style scoped>
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
a:hover,
a:active,
a:focus {
  color: #3c5665;
  text-decoration: none;
}
.chk {
  background-position: left center;
}
.chk.checked {
  background-position: left center;
}
</style>