<template>
  <div>
    <h2>{{ $t('동거가족_문진') }}</h2>
    <p>
      {{
        $t(
          '동거가족이_증상_및_장소방문력이_있는_경우_해당_동거가족에_대한_문진을_추가하세요'
        )
      }}
    </p>
    <div class="question_list">
      <ul v-for="(contents, contentsIdx) in famList" :key="contentsIdx">
        <div
          @click="toggleActive(contentsIdx)"
          class="family_chk"
          :class="{ selected: famListActiveInfo[contentsIdx] }"
        >
          <div class="chk" />
          {{ $t('동거가족1의_문진', { '0': `${contentsIdx + 1}` }) }}
        </div>
        <div
          class="family_q"
          id="family_q_1"
          :style="{
            display: famListActiveInfo[contentsIdx] ? 'block' : 'none',
          }"
        >
          <div v-for="item in contents" :key="item.inquiryMappingId">
            <!-- 역학적 연관성 -->
            <Normal
              v-if="
                item.categoryType === InqType.HousemateRelation && item.show
              "
              :contents="item"
              :ref="`inquiry_${contentsIdx}`"
              @setShow="
                setShow($event, {
                  contentsIdx,
                  target: 'isCheckHousemateRelation',
                })
              "
            />
            <!-- 주관식 형 -->
            <ShortAnswer
              v-else-if="
                item.categoryType === InqType.HousemateDetail &&
                famNeedDiceaseInfo[contentsIdx].isCheckHousemateRelation
              "
              :contents="item"
              :ref="`inquiry_${contentsIdx}`"
            />
            <!-- 장소 방문력 -->
            <!-- <VisitHistory
              v-else-if="
                item.categoryType === InqType.VisitHistory &&
                item.show &&
                (getLanguage === 'ko' || getLanguage === 'en')
              "
              :ref="`inquiry_${contentsIdx}`"
              :contents="item"
              @setShow="
                setShow($event, {
                  contentsIdx,
                  target: 'isCheckvisitHistory',
                })
              "
            /> -->
            <!-- 증상 -->
            <MultiChoice
              v-else-if="
                item.categoryType === InqType.Housemate &&
                (famNeedDiceaseInfo[contentsIdx].isCheckHousemateRelation ||
                  famNeedDiceaseInfo[contentsIdx].isCheckvisitHistory)
              "
              :contents="item"
              :ref="`inquiry_${contentsIdx}`"
            />
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import Normal from '../inquiryType/Normal.vue';
import VisitHistory from '../inquiryType/VisitHistory.vue';
import MultiChoice from '../inquiryType/MultiChoice.vue';
import ShortAnswer from '../inquiryType/ShortAnswer.vue';
import lodash from 'lodash';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'homemateWrapper',
  components: {
    Normal,
    VisitHistory,
    MultiChoice,
    ShortAnswer,
  },
  props: {
    categoryList: Array,
    visitHistoryTemplete: Array,
  },
  data: () => {
    return {
      famList: [],
      famListActiveInfo: [],
      famNeedDiceaseInfo: [],
      maxFam: 2, // 최대 2명의 동거인 등록 가능
    };
  },
  computed: {
    ...mapState(['InqType']),
    ...mapGetters(['getLanguage']),
  },
  mounted() {
    this.famList.push(this.makeNewFam());
    this.famNeedDiceaseInfo = Array.from({ length: this.maxFam }, () => ({
      isCheckHousemateRelation: false,
      isCheckvisitHistory: false,
    }));
  },
  methods: {
    submit() {
      const returnValue = [];
      for (let i = 0; i < this.famList.length; i++) {
        if (this.famListActiveInfo[i]) returnValue.push(this.famList[i]);
      }
      return returnValue;
    },
    checkInput() {
      for (let i = 0; i < this.famListActiveInfo.length; i++) {
        if (!this.famListActiveInfo[i]) continue; // 체크된 컨텐츠만 확인
        for (let j = 0; j < this.$refs[`inquiry_${i}`].length; j++) {
          const inquiryComponent = this.$refs[`inquiry_${i}`][j];
          // 각 컴포넌트 별 데이터 업데이트 실행 후 true 리턴이 되는지 확인
          if (inquiryComponent.saveData && !inquiryComponent.saveData()) {
            inquiryComponent.$el.scrollIntoView({ behavior: 'smooth' }); // move scroll
            return false;
          }
        }
      }
      return true;
    },
    toggleActive(index) {
      this.$set(this.famListActiveInfo, index, !this.famListActiveInfo[index]);
      if (
        index === this.famListActiveInfo.length - 1 &&
        this.famListActiveInfo[index] &&
        this.famListActiveInfo.length < this.maxFam
      ) {
        this.famList.push(this.makeNewFam());
      }
    },
    makeNewFam() {
      const newFam = [];
      const visitHistory = lodash.cloneDeep(this.visitHistoryTemplete);
      const Housemate = lodash.cloneDeep(
        this.categoryList.filter(
          (item) => item.categoryType === this.InqType.Housemate
        )
      );
      const HousemateRelation = lodash.cloneDeep(
        this.categoryList.filter(
          (item) => item.categoryType === this.InqType.HousemateRelation
        )
      );
      const HousemateDetail = lodash.cloneDeep(
        this.categoryList.filter(
          (item) => item.categoryType === this.InqType.HousemateDetail
        )
      );
      // 1. 역학적 연관성
      if (HousemateRelation.length > 0) {
        HousemateRelation[0].targetCategoryId = Housemate[0].categoryId;
        newFam.push(HousemateRelation[0]);
      }
      // 2. 역학적 연관성 상세 주관식
      if (HousemateDetail.length > 0) {
        newFam.push(HousemateDetail[0]);
      }
      // 3. 방문력.
      if (visitHistory.length > 0) {
        visitHistory[0].targetCategoryId = Housemate[0].categoryId;
        newFam.push(visitHistory[0]);
      }
      // 4. 증상
      if (Housemate.length > 0) {
        newFam.push(Housemate[0]);
      }

      this.famListActiveInfo.push(false);

      return newFam;
    },
    setShow({ isShow }, { contentsIdx, target }) {
      this.famNeedDiceaseInfo[contentsIdx][target] = isShow;
    },
  },
};
</script>
