<template>
  <section class="container">
    <div id="header"></div>
    <div class="conts">
      <div class="wapper">
        <div class="guide_with">
          <h2
            v-html="
              $t('보호자와_함께_내원하시는_경우는_보호자_연락처를_입력해주세요')
            "
          />
          <div class="guide_num" @click="switchYn(true)">
            <h4 :class="{ selected: companionYn }">
              <div class="guide_chk"></div>
              {{ $t('보호자_동반') }}
            </h4>
            <div class="num_wrap" :class="{ disabled: !companionYn }">
              <div class="num_select">
                <select v-model="prePhoneNum">
                  <option>010</option>
                  <option>011</option>
                  <option>017</option>
                  <option>018</option>
                  <option>019</option>
                </select>
              </div>
              <div class="num_input">
                <input
                  type="number"
                  placeholder="12345678"
                  :disabled="!companionYn"
                  v-model="postPhoneNum"
                />
              </div>
            </div>
            <div class="noti">
              {{ $t('보호자에게_병원_출입증_발급_링크를_자동으로_발송합니다') }}
            </div>
            <div class="noti">
              {{
                $t(
                  '스마트폰이_아닌_휴대전화를_사용하시는_보호자는_현장에_설치된_키오스크에서_출입증을_발급해주세요'
                )
              }}
            </div>
          </div>
          <div class="guide_num">
            <h4 :class="{ selected: !companionYn }" @click="switchYn(false)">
              <div class="guide_chk"></div>
              {{ $t('보호자_동반하지_않음') }}
            </h4>
          </div>
        </div>
      </div>
      <div class="btn_area">
        <div class="btn" :class="{ disabled: !phoneNumChk }" @click="closePop">
          {{ $t('확인') }}
        </div>
      </div>
      
    <div v-if="nokPopUp == true" class="pop_bg" style="display: block">
      <div id="popup_yes" class="pop pop_md">
        <!-- 유효성 검사 팝업 -->
        <div class="pop_head type2">
          
          <br><br><br>
          <p>
            <blockquote v-html="$t('원내_보호자_출입은_1인으로_제한됩니다.')" />
          </p>
        </div>
        <div class="pop_body">
        </div>
        <!-- 버튼 -->
        <div class="btn_area scroll">
          <div
            class="btn"
            @click="closePopup"
          >
            {{ $t('확인') }}
          </div>

        </div>
      </div>
    </div>

    </div>
  </section>
</template>

<script>
export default {
  props: {
    value: String,
  },
  data: () => {
    return {
      companionYn: false, // false면 동반X
      prePhoneNum: '010',
      postPhoneNum: '',
      nokPopUp:true,
    };
  },
  computed: {
    phoneNumChk() {
      return (
        (this.companionYn &&
          this.postPhoneNum !== '' &&
          this.postPhoneNum.length > 6) ||
        !this.companionYn
      );
    },
    is010() {
      return this.prePhoneNum === '010';
    },
  },
  methods: {
    clearInput() {
      this.prePhoneNum = '010';
      this.postPhoneNum = '';
    },
    closePop() {
      this.phoneNumChk && this.$emit('closePopup');
    },
    switchYn(check) {
      this.companionYn = check;
    },
    closePopup() {
      this.nokPopUp = false;
    },
  },
  watch: {
    postPhoneNum: function (newVal) {
      // 숫자만 최대 8자리
      this.postPhoneNum = newVal.replace(/[^0-9]/gi, '').substring(0, 8);
      this.$emit(
        'input',
        `${newVal === '' ? '' : this.prePhoneNum}${this.postPhoneNum}`
      );
    },
    prePhoneNum: function (newV) {
      this.$emit(
        'input',
        `${this.postPhoneNum === '' ? '' : newV}${this.postPhoneNum}`
      );
    },
    companionYn: function (newVal) {
      !newVal && this.clearInput();
    },
  },
};
</script>

<style scoped>
.btn_area {
  position: absolute;
  padding: 0 6% 20px 6%;
  width: 100%;
}
.btn_area .btn {
  width: 100%;
  height: 40px;
  font-size: 1em;
  color: #fff;
  background: #006769;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'NEXONLv1GothicB';
}
.btn_area .btn:last-child {
  margin-top: 10px;
}
</style>