import { render, staticRenderFns } from "./AgreeTerms.vue?vue&type=template&id=413a33ac&scoped=true&"
import script from "./AgreeTerms.vue?vue&type=script&lang=js&"
export * from "./AgreeTerms.vue?vue&type=script&lang=js&"
import style0 from "./AgreeTerms.vue?vue&type=style&index=0&id=413a33ac&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "413a33ac",
  null
  
)

export default component.exports