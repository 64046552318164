<template>
  <section class="container">
    <div id="header">
      <div class="btn_home">
        <!-- <a href="">처음으로</a> -->
      </div>
    </div>
    <div class="conts">
      <div class="wapper">
        <div class="info_page">
          <h2 v-html="$t('작성을_완료하였습니다_감사합니다')" />
          <br />
          <div v-if="isSubTitle && visitDate !== null">
            사전 문진을 완료하였습니다.<br />
            내원일자 :
            <h2
              style="
                margin-top: 0;
                margin-bottom: 10px;
                margin-top: 10px;
                margin-bottom: 10px;
                display: inline-block;
                font-size: 20px;
                margin-left: 11px;
              "
            >
              {{ visitDate }}
            </h2>
            <h3>
              병원 출입구에서 직원에게 <br />이 화면을 보여주시기 바랍니다.
              <br /><span v-if="getUserType == 'NOK'" style="font-size: 21px"
                >(보호자)</span
              >
            </h3>
          </div>
        </div>
        <!-- <div v-if="passCheck !== null" class="btn_area">
          <div @click="openPopup" class="btn outlined">문진 재작성</div>
        </div> -->
      </div>
    </div>
    <CheckReInquiryPopup
      v-if="retryInquiryPopup"
      @closePopup="closePopup"
      @okBtn="okBtn"
    />
  </section>
</template>
<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import CheckReInquiryPopup from '../../components/v2/popup/CheckReInquiryPopup.vue';
import moment from 'moment';

export default {
  components: { CheckReInquiryPopup },
  name: 'Thankyou',
  data() {
    return {
      passCheck: null,
      retryInquiryPopup: false,
      visitDate: null,
    };
  },
  computed: {
    ...mapState(['USER']),
    ...mapGetters(['getUserType']),
    isSubTitle() {
      return (
        this.getUserType === this.USER.PT ||
        this.getUserType === this.USER.NOK ||
        this.getUserType === this.USER.GST ||
        this.getUserType === this.USER.TMP ||
        this.getUserType === this.USER.PEMP
      );
    },
  },
  mounted() {
    this.passCheck = this.$route.query.pass ?? null;
    this.passCheck && this.getPassCheckInfo();
  },
  methods: {
    ...mapMutations(['setUserType']),
    async getPassCheckInfo() {
      try {
        const response = await this.$axios.post('/api/v1/identity/pass-check', {
          passCheck: this.passCheck,
        });
        switch (response.data.resultCd) {
          case 201:
            (this.getUserType === null || this.isSubTitle) &&
              this.$router.replace({
                name: 'passForbiden',
                query: {
                  pass: this.passCheck,
                },
              });
            break;
          case 305:
            // 305 - 만료된 PassCheck
            // 만료된 PassCheck
            this.$router.replace({
              name: 'error',
              params: {
                titleText: this.$t('유효하지_않은_출입증입니다'),
              },
            });
            break;
          case 0:
            // 정상
            this.visitDate = response.data.result.visitDate;
            this.visitDate = moment(this.visitDate).format('YYYY/MM/DD');
            this.setUserType(response.data.result.answerType);
            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        console.log(error);
        this.$router.push({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_출입증입니다'),
          },
        });
      }
    },
    async retryInquiry() {
      this.btnLock = true;
      try {
        const response = await this.$axios.post('/api/v1/identity/re-inquiry', {
          passCheck: this.passCheck,
        });
        // if (!response.data.success) throw new Error('error');

        switch (response.data.resultCd) {
          case 309:
            // 병원 내 출입중 재문진 실행 불가
            this.$modal.error(
              this.$t(
                '원내_출입_중에는_재문진이_불가능합니다_원외로_이동_후_재문진을_시작해_주세요'
              )
            );
            this.closePopup();
            break;
          case 305:
            // 만료된 문진 데이터
            this.$router.replace({ name: 'error' });
            break;
          case 0:
            this.$router.replace({ name: 'retryInfo' });
            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        console.log(error);
        this.$router.replace({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_접근입니다'),
          },
        });
      } finally {
        this.btnLock = false;
      }
    },
    openPopup() {
      this.retryInquiryPopup = true;
    },
    closePopup() {
      this.retryInquiryPopup = false;
    },
    okBtn() {
      this.retryInquiry();
    },
  },
};
</script>
<style scoped>
.info_page {
  padding: 0 10px;
}
.info_page h2 {
  margin-top: 25%;
  margin-bottom: 10px;
  height: auto;
  line-height: 1.2;
  font-size: 30px;
}
.info_page blockquote {
  margin-bottom: 60px;
}
.btn_area {
  position: absolute;
  bottom: 0;
  left: 0%;
  padding: 0 6% 20px 6%;
  width: 100%;
}
.btn_area .btn {
  width: 100%;
  height: 40px;
  font-size: 1em;
  color: #fff;
  background: #006769;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'NEXONLv1GothicB';
}
.btn_area .outlined {
  border: 1px solid #006769;
  color: #006769;
  background: #ffffff;
}
</style>