<template>
  <div class="conts">
    <div class="wapper">
      <h2>{{ $t('온라인_출입증_발급') }}</h2>
      <div class="ask_conts">
        <dl>
          <dd>
            <blockquote>
              <div
                v-html="
                  $t(
                    '본인확인을_위해_알림_메시지를_받으신_분의_휴대전화_번호_마지막_4자리를_입력해주세요'
                  )
                "
              />
              <div v-html="$t('예_010_1234_5678_인_경우_5678_입력')" />
            </blockquote>
            <div class="num_box">
              <input
                maxlength="4"
                type="number"
                v-model="lastPlcTelno"
                placeholder="1234"
              />
            </div>
          </dd>
        </dl>
      </div>
      <div class="btn_area">
        <blockquote
            v-if="getValidInquiryCnt > 0 && getUserType === USER.RNOK"
            v-html="
            $t(
              '본_문진표를_허위로_작성할_경우_보건_관련_법률_및_형법에_따라_처벌_또는_과태료_1천만원_가_부과됩니다_입원_예약자_및_상주보호자에_한함'
            )
          "
        />
        <blockquote
            v-if="getValidInquiryCnt > 0 && getUserType !== USER.RNOK"
            v-html="
            $t(
              '본_문진표를_허위로_작성할_경우_보건_관련_법률_및_형법에_따라_처벌_또는_과태료_1천만원_가_부과됩니다_noBr'
            )
          "
        />
        <div :class="{ disabled: !checkInput }" class="btn" @click="submit">
          {{ $t('확_인') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
export default {
  name: 'PidCheck',
  props: {
    identityNo: String,
  },
  data: () => {
    return {
      lastPlcTelno: null, //전화번호 마지막 4자리
    };
  },
  computed: {
    ...mapState(['USER']),
    ...mapGetters(['getUserType','getValidInquiryCnt']),
    checkInput() {
      if (this.lastPlcTelno === null || this.lastPlcTelno.length < 4) {
        return false;
      }
      return true;
    },
  },
  watch: {
    lastPlcTelno: function (newVal) {
      // 숫자만 최대 4자리
      this.lastPlcTelno = newVal.substring(0, 4).replace(/[^0-9]/gi, '');
    },
  },
  methods: {
    ...mapMutations(['setUserType']),
    async submit() {
      // 입력값 확인
      if (!this.checkInput) return;

      try {
        const lastTelno = this.identityNo.substring(this.identityNo.length - 4);

        if (lastTelno === this.lastPlcTelno) {
          this.$emit('check');
        } else {
          this.$modal.error(
            this.$t(
              '휴대전화_번호가_일치하지_않습니다_휴대전화_번호를_한번_더_확인해주세요'
            )
          );
          this.lastPlcTelno = '';
        }
      } catch (error) {
        console.log(error);
        this.$router.push({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_접근입니다'),
          },
        });
      }
    },
  },
};
</script>

<style scoped>
input:focus {
  outline: none;
}
</style>