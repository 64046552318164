<template>
  <!-- 일반문항(기본) -->
  <div>
    <h3>
      {{ questionContents }}
    </h3>
    <ul>
      <div class="info_text" v-html="categoryDesc"></div>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'Editor',
  props: {
    contents: Object,
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['USER', 'InqType']),
    isIndvdCheck() {
      return true;
    },
    questionContents() {
      if (this.contents.langQuestion && this.contents.langQuestion !== '')
        return this.contents.langQuestion;
      else if (
        this.contents.questionContents &&
        this.contents.questionContents !== ''
      )
        return this.contents.questionContents;
      else return '';
    },
    categoryDesc() {
      if (
        this.contents.langCategoryDesc &&
        this.contents.langCategoryDesc !== ''
      )
        return this.contents.langCategoryDesc;
      else if (this.contents.categoryDesc && this.contents.categoryDesc !== '')
        return this.contents.categoryDesc;
      else return '';
    },
    existCheckedContents() {
      return false;
    },
  },
  methods: {
    saveData() {
      return true;
    },
  },
};
</script>

<style scoped>
.ask blockquote {
  width: 100%;
  line-height: 1.4;
  
  font-weight: 300;
  letter-spacing: 0px;
}
.ask .ask_conts {
  margin-top: 25px;
  min-height: 350px;
}
.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.ask dt {
  margin-bottom: 10px;
  font-size: 1em;
  color: #006769;
  font-weight: bold;
}
.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.btn_no {
  float: left;
  width: 48% !important;
  color: #006769 !important;
  border: 1px solid #006769 !important;
  background: #fff !important;
}
.btn_yes {
  float: left;
  margin-right: 4%;
  width: 48% !important;
  color: #fff !important;
  background-color: #006769 !important;
}
.ask dd li {
  padding: 0 0 15px 15px;
  line-height: 1.3;
  background: url('../../../assets/images/ico_dot.svg') no-repeat;
  background-size: 1%;
  background-position: 3px 9px;
}
.ask dd li:last-child {
  padding-bottom: 30px;
}
.ask dd li p {
  font-size: 0.8em;
  line-height: 1.3;
}

.btn_area {
  bottom: 0;
  left: 0%;
  padding: 0 6% 20px 6%;
  width: 100%;
}
.btn_area .btn {
  width: 100%;
  height: 40px;
  font-size: 1em;
  color: #fff;
  background: #006769;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'NEXONLv1GothicB';
}
.btn_area blockquote {
  padding-bottom: 20px;
}
.btn_area .outlined {
  border: 1px solid #006769;
  color: #006769;
  background: #ffffff;
}
.btn_area.scroll {
  position: relative;
  padding: 0 0 20px 0;
}
.btn_area.inside {
  position: relative;
  padding: 0;
}
.btn_area.type2 .btn:last-child {
  margin-left: 10px;
}
.btn_unchk {
  width: 100%;
  height: 40px;
  font-size: 1em;
  color: #666;
  border: 1px solid #666;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'NEXONLv1GothicB';
  background: url('../../../assets/images/btn_ico_chk_off.svg') no-repeat
    #ffffff;
  background-position: 35% 10px;
}
.btn_chk {
  width: 100%;
  height: 40px;
  font-size: 1em;
  color: #ffffff;
  border: 1px solid #006769;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'NEXONLv1GothicB';
  background: url('../../../assets/images/btn_ico_chk_on.svg') no-repeat #006769;
  background-position: 35% 10px;
}

/** 문진목록 **/
.question_list {
  margin-top: 10px;
  padding-bottom: 10px;
}
.question_list h3 {
  word-break: break-all;
  margin-bottom: 5px;
  font-size: 1em;
  font-weight: bold;
  color: #222222;
}
.question_list h3 p {
  word-break: break-all;
  margin-top: 5px;
  margin-bottom: 15px;
  line-height: 1.2;
  
  font-weight: normal;
}

.question_list h5 {
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  color: #222222;
  border-bottom: 1px solid #eeeeee;
}
.question_list h5 p {
  
  font-weight: normal;
}
.question_list ul {
  width: 100%;
  margin-bottom: 40px;
  padding: 0px;
  border-radius: 11px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  overflow: hidden;
}

.question_list ul .family_q {
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
  display: none;
  border-top: 1px solid #eeeeee;
}

.question_list ul .family_q ul {
  width: 100%;
  padding: 0;
  margin: 0 auto 0px auto;
  box-shadow: none;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-radius: 0;
}
.question_list ul .family_q ul:last-child {
  border-bottom: none;
}
/* .question_list ul:last-child{box-shadow:none;background-color: #f6f6f6; } */
.question_list li {
  position: relative;
  width: 100%;
  padding: 13px 45px 13px 20px;
  
  line-height: 1.4;
  border-bottom: 1px solid #efefef;
}
.question_list li:last-child {
  border-bottom: none;
  overflow: hidden;
}
.question_list input[type='checkbox'] {
  display: none;
}
.question_list input[type='checkbox'] + label {
  padding: 10px 20px;
  background: url(../../../assets/images/ico_uncheck.svg) no-repeat 94% 12px;
  cursor: pointer;
  display: block;
  vertical-align: middle;
}
.question_list input[type='checkbox']:checked + label {
  background: url(../../../assets/images/ico_checked.svg) no-repeat 94% 12px
    #eaffff;
  display: block;
}
.question_list input[type='checkbox'] + label span {
  padding: 0 20px 0 0;
  width: 100%;
  display: block;
}
.question_list li button {
  margin: 10px auto;
  width: 90%;
  height: 40px;
  font-size: 1em;
  color: #fff;
  background: #006769;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.question_list li button:disabled {
  background: #cccccc;
}
.question_list .btn_area {
  position: relative;
  padding: 10px 20px 20px 20px;
  display: flex;
}
/* .question_list .btn_area .btn:last-child{margin:15px 0 0 15px;} */
.question_list li span > span {
  font-size: 0.8em;
}
.question_list table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
}
.question_list td {
  position: relative;
  padding: 12px 100px 12px 20px;
  border-bottom: 1px solid #eeeeee;
}
.question_list th {
  font-weight: bold;
  padding: 5px 10px;
  background: #f6f6f6;
  text-align: left;
}
.question_list td .pic_date {
  position: absolute;
}
.question_list .label_new {
  margin-right: 5px;
  width: auto !important;
  padding: 0 10px !important;
  font-size: 11px !important;
  background: #00a2ff;
  color: #ffffff;
  border-radius: 5px;
  display: inline-block !important;
}
.question_list .label_important {
  margin-right: 5px;
  width: auto !important;
  padding: 0 10px !important;
  font-size: 11px !important;
  background: #f68b1f;
  color: #ffffff;
  border-radius: 5px;
  display: inline-block !important;
}
.question_list .pic_date {
  position: absolute;
  right: 60px;
  top: 50%;
  margin-top: -10px;
  padding-left: 20px;
  width: 30px;
  color: #00a2ff;
  font-size: 0.8em;
  background: url(../../../assets/images/ico_calander.svg) no-repeat top left;
  background-size: 60%;
}
.question_list .privacy_wraps {
  position: relative;
}
.question_list .sm_txt {
  font-size: 0.8em;
  line-height: 1.2;
}
.question_list .chk {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -10px;
  width: 21px;
  height: 21px;
  background: url(../../../assets/images/ico_uncheck.svg) no-repeat right;
  cursor: pointer;
  display: block;
  vertical-align: middle;
}
.question_list .radio {
  display: inline-block;
  width: 25px;
  height: 21px;
  background: url(../../../assets/images/ico_radio_unchk.svg) no-repeat left;
  cursor: pointer;
  vertical-align: middle;
}
.question_list .selected {
  background: #eaffff;
}
.question_list .selected .chk {
  width: 21px;
  height: 21px;
  background: url(../../../assets/images/ico_checked.svg) no-repeat right;
  display: block;
}
.question_list .selected .radio {
  display: inline-block;
  width: 25px;
  height: 21px;
  background: url(../../../assets/images/ico_radio_chk.svg) no-repeat left;
}
.info_text {
  padding: 20px;
}
.question_list .multi_c .txt {
  width: 90%;
}
.question_list .multi_c li {
  display: flex;
}

.family_chk {
  position: relative;
  padding: 13px 20px 13px 50px;
  line-height: 1.3;
  font-size: 1em;
  font-weight: bold;
  color: #006769;
}
.family_chk .chk {
  top: 50%;
  left: 20px;
  margin-top: -10px;
  background: url(../../../assets/images/ico_pri_unchecked.svg) no-repeat 0 0 !important;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}
.family_chk.selected .chk {
  top: 50%;
  left: 20px;
  margin-top: -10px;
  background: url(../../../assets/images/ico_pri_check.svg) no-repeat 0 0 !important;
  display: block;
}
.question_list .textarea {
  padding: 10px;
}

</style>