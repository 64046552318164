var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container ask"},[_c('NormalHeader',{attrs:{"languageBtn":true}}),_c('div',{staticClass:"conts"},[_c('div',{staticClass:"wapper"},[_c('h2',[_vm._v(_vm._s(_vm.$t('방문목적_선택')))]),(!_vm.getWithTalk || _vm.getCnnHome === 'C' )?_c('div',{staticClass:"purpose_list"},[_c('blockquote',[_vm._v(_vm._s(_vm.$t('방문목적을_선택해주세요')))]),_c('div',{staticClass:"purpose_list"},[_c('ul',[_c('h3',{staticClass:"chk",class:{ selected: _vm.selectedMeetingType === 'code' },on:{"click":function($event){return _vm.selectType('code')}}},[_vm._v(" "+_vm._s(_vm.$t('집합행사'))+" ")]),(
                _vm.selectedMeetingType === 'code' && _vm.meetingInfo.length === 0
              )?_c('li',[_vm._v(" "+_vm._s(_vm.$t('금일_예정된_집합_행사가_없습니다'))+" ")]):_vm._e(),(_vm.selectedMeetingType === 'code')?_c('div',{staticClass:"list-wrapper"},_vm._l((_vm.meetingInfo),function(item){return _c('li',{key:item.meetingId,staticClass:"chk",class:{
                  selected:
                    _vm.selectedMeeting &&
                    _vm.selectedMeeting.meetingId === item.meetingId,
                },on:{"click":function($event){return _vm.selectMeeting(item)}}},[_vm._v(" "+_vm._s(item.meetingName)+" ")])}),0):_vm._e()]),_c('ul',{on:{"click":function($event){return _vm.selectType('etc')}}},[_c('h3',{staticClass:"chk",class:{ selected: _vm.selectedMeetingType === 'etc' }},[_vm._v(" "+_vm._s(_vm.$t('기타방문'))+" ")])])])]):_c('div',{staticClass:"purpose_list"},[_c('div',{staticClass:"purpose_list"},[_c('ul',[_c('h3',{staticClass:"chk selected"},[_vm._v(_vm._s(_vm.$t('집합행사')))]),_c('div',{staticClass:"list-wrapper"},[(_vm.meetingInfo.length === 0)?_c('li',[_vm._v(" "+_vm._s(_vm.$t('금일_예정된_집합_행사가_없습니다'))+" ")]):_vm._e(),_vm._l((_vm.meetingInfo),function(item){return _c('li',{key:item.meetingId,staticClass:"chk",class:{
                  selected:
                    _vm.selectedMeeting &&
                    _vm.selectedMeeting.meetingId === item.meetingId,
                },on:{"click":function($event){return _vm.selectMeeting(item)}}},[_vm._v(" "+_vm._s(item.meetingName)+" ")])})],2)])])]),_c('div',{staticClass:"btn_area"},[_c('div',{staticClass:"btn",class:{ disabled: !_vm.canNext },on:{"click":_vm.goNext}},[_vm._v(" "+_vm._s(_vm.$t('다음'))+" ")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }