<template>
  <section class="container ask">
    <NormalHeader :languageBtn="true" />
    <div class="conts hp">
      <div class="wapper">
        <h2>{{ $t('출입증발급') }}</h2>
        <h3>{{ $t('출입증발급을위해선택하세요') }}</h3>
        <!-- 버튼 영역 -->
        <div class="btn_pati" @click="showPopup('patient')">
          <h4>{{ $t('환_자') }}</h4>
          <p>{{ $t('외래진료입원검사등') }}</p>
          <i class="img_ai">일러스트</i>
        </div>
        <div class="btn_guide" @click="showPopup('guardian')">
          <h4>{{ $t('보호자') }}</h4>
          <i class="img_ai">보호자 일러스트</i>
        </div>
        <div class="btn_guest" @click="showPopup('visiter')">
          <h4>{{ $t('방문객') }}</h4>
          <!-- <p>업체, 조문 등</p> -->
          <i class="img_ai">보호자 일러스트</i>
        </div>
        <!--면회객 버튼 임시 추가 -->
        <!-- <div class="btn" style="padding:10px;width:fit-content; border:1px solid grey;cursor:pointer" 
        @click="showPopup('icuGuest')">
          <h3>{{ $t("면회객") }}</h3>
          <p>중환자실</p>
        </div> -->
      </div>
    </div>
    <div v-if="selectDatePopup" class="pop_bg" style="display: block">
      <div id="popup_yes" class="pop pop_md">
        <!-- 유효성 검사 팝업 -->
        <div class="pop_head type2">
          {{ $t('내원일을_선택하세요') }}
          <p>
            {{
              $t('방문일_전날_오후_4시부터_출입증_발급이_가능합니다')
            }}
          </p>
        </div>
        <div class="pop_body">
          <div
            v-for="item in getDateList"
            :key="item"
            class="btn_date"
            :class="{ selected: item === selectedDate }"
            @click="selectDate(item)"
          >
            {{ dateListText(item) }}
          </div>
        </div>
        <!-- 버튼 -->
        <div class="btn_area scroll">
          <div
            class="btn"
            :class="{ disabled: selectedDate === null }"
            @click="goNext"
          >
            {{ $t('다음') }}
          </div>
          <div class="btn outlined" @click="closePopup">
            {{ $t('다시_입력') }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapMutations, mapState , mapActions} from 'vuex';
import NormalHeader from '../components/v2/NormalHeader.vue';

export default {
  name: 'Home',
  components: {
    NormalHeader,
  },
  data() {
    return {
      selectDatePopup: false,
      selectedDate: null,
    };
  },
  computed: {
    ...mapState(['USER']),
    ...mapGetters(['getUserType', 'getLanguage']),
    getDateList() {
      // 주의 : computed 에서 Date 사용 시 remounted 시점까지 다시 연산되지 않음
      const now = new Date();
      const returnValue = [];
      returnValue.push(moment().format('YYYYMMDD'));
      if (now.getHours() >= 16) {
        returnValue.push(moment().add(1, 'day').format('YYYYMMDD'));
      }
      return returnValue;
    },
  },
  mounted() {
    let date = "2022-11-11"
    console.log(date.replace(/-/g, ''));
    if(this.$route.name === 'homeChart'){     
      this.setCnnHome('C'); //내손안의차트 앱으로 접속시.
    } else {
      this.setWithTalk(false); //문진 작성완료후 출입증을 대신 휴대전화로 발송된다는 안내 맨트를 위해 추가.
      this.setCnnHome('H');//홈페이지 경로로 접속시.
    }
  },
  methods: {
    ...mapMutations(['setUserType', 'initUserType', 'setWithTalk', 'setCnnHome', 'initInquiryYn']),
    ...mapActions(['InquiryUseCheck']),
    dateListText(date) {
      if (this.getLanguage === 'ko')
        return moment(date).format('YYYY년 M월 D일 (ddd)');
      else return moment(date).format('YYYY-MM-DD (ddd)');
    },
    selectDate(date) {
      this.selectedDate = date;
    },
    showPopup(path) {
      switch (path) {
        case 'patient':
          this.setUserType(this.USER.PT);
          break;
        case 'guardian':
          this.setUserType(this.USER.NOK);
          break;
        case 'visiter':
          this.setUserType(this.USER.GST);
          break;
        case 'icuGuest':
          this.setUserType(this.USER.ICUGST);
          break;
        default:
          break;
      }
      this.selectDatePopup = true;
    },
    async goNext() {
      if (this.selectedDate === null) return;
      await this.InquiryUseCheck();
      switch (this.getUserType) {
        case this.USER.PT:
        case this.USER.NOK:
        case this.USER.ICUGST: 
          this.$router.push({
            path: '/pt',
            query: {
              vd: window.btoa(this.selectedDate),
              type: window.btoa(this.getUserType),
            },
          });
          break;
        case this.USER.GST:
          this.$router.push({
            path: '/gst/grpo',
            query: {
              vd: window.btoa(this.selectedDate),
              type: window.btoa(this.getUserType),
            },
          });
          break;
        default:
          break;
      }
    },
    closePopup() {
      this.initUserType();
      this.initInquiryYn();
      this.selectedDate = null;
      this.selectDatePopup = false;
    },
  },
};
</script>

<style scoped>
@media screen and (min-width: 768px) {
  .container {
    min-height: 960px;
  }
}
</style>