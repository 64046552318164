<template>
  <div class="modal">
    <!--개인정보 수집-->
    <div class="bg">
      <a class="js-close-modal" id="close-modal" @click="closeModal">{{
        $t('닫기')
      }}</a>
      <div class="header">{{ modalTitle }}</div>
      <div
        class="body"
        v-html="modalText"
        :style="`height: calc(100% - 80px - ${nativeCallpadding})`"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
  name: 'TermsPopup',
  props: {
    modalTitle: String,
    modalText: String,
  },
  computed: {
    ...mapState(['USER']),
    ...mapGetters(['getUserType']),
    nativeCallpadding() {
      return this.getUserType === this.USER.EMP ? '60px' : '0px';
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>

<style scoped>
</style>