<template>
  <section class="container">
    <NormalHeader />
    <div class="conts">
      <div class="wapper">
        <h2>{{ $t('직원_등록_관리') }}</h2>
        <ul class="guide_list">
          <table>
            <tr>
              <th>{{ $t('이름') }}</th>
              <th>{{ $t('휴대전화번호') }}</th>
              <th>{{ $t('관리') }}</th>
            </tr>
            <tr v-for="item in employeeList" :key="item.empId">
              <td>{{ item.empNm }}</td>
              <td>{{ phoneNum(item.empPhone) }}</td>
              <td>
                <a
                  v-if="item.managerYn !== 'Y'"
                  href="javascript:void(0);"
                  class="btn_del"
                  @click="openConfirmPopup(item.empId)"
                  >{{ $t('삭제') }}</a
                >
              </td>
            </tr>
          </table>
        </ul>
        <p>
          {{
            $t(
              '등록된_번호로_병원_출입증_발급_링크가_발송되오니_정확히_작성해주세요'
            )
          }}
        </p>
        <div class="btn_area">
          <div class="btn addEmp" @click="openEmployeeInsertPopup">
            {{ $t('직원_추가_등록') }}
          </div>
        </div>
      </div>
    </div>
    <EmployeeInsert
      v-if="showEmployeeInsertPopup"
      :partnerEmpId="partnerEmpId"
      :partnerGroupId="partnerGroupId"
      @update="getPartnerEmployeeList"
      @closePopup="closePopup"
    />
    <ConfirmPopup
      v-if="showConfirmPopup"
      :guideText="$t('삭제하시겠습니까')"
      @ok="deletePartnerEmployee"
      @cancle="closePopup"
    />
  </section>
</template>

<script>
import NormalHeader from '../../components/v2/NormalHeader.vue';
import EmployeeInsert from '../../components/v2/popup/EmployeeInsert.vue';
import ConfirmPopup from '../../components/v2/popup/ConfirmPopup.vue';

export default {
  name: 'EmployeeManage',
  components: {
    NormalHeader,
    EmployeeInsert,
    ConfirmPopup,
  },
  props: {
    partnerEmpId: String,
    partnerGroupId: String,
  },
  data() {
    return {
      employeeList: [],
      showEmployeeInsertPopup: false,
      showConfirmPopup: false,
      delEmployeeTarget: null,
    };
  },
  methods: {
    phoneNum(str) {
      let returnValue = '';
      returnValue += str.slice(0, 3);
      if (str.length > 3) {
        returnValue += '-';
        returnValue += str.slice(3, -4);
      }
      if (str.length > 7) {
        returnValue += '-';
        returnValue += str.slice(-4);
      }
      return returnValue;
    },
    openConfirmPopup(targetPartnerEmpId) {
      this.delEmployeeTarget = targetPartnerEmpId;
      this.showConfirmPopup = true;
    },
    async deletePartnerEmployee() {
      this.closePopup();
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post('/api/v1/identity/pemp-del', {
          partnerEmpId: this.partnerEmpId,
          partnerGroupId: this.partnerGroupId,
          targetPartnerEmpId: this.delEmployeeTarget,
        });
        // if (!response.data.success) throw new Error('error');
        switch (response.data.resultCd) {
          case 401:
            // 삭제 권한 없음
            this.$modal.error('직원정보 삭제 권한이<br/>없습니다.');
            break;
          case 300:
            // 삭제 실패
            this.$modal.error('삭제 대상을 다시 확인해주세요.');
            break;
          case 0:
            // 정상 -> Reload
            this.getPartnerEmployeeList();
            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$modal.loading(false);
      }
    },
    async getPartnerEmployeeList() {
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post('/api/v1/identity/pemp-list', {
          partnerEmpId: this.partnerEmpId,
          partnerGroupId: this.partnerGroupId,
        });
        // if (!response.data.success) throw new Error('error');
        switch (response.data.resultCd) {
          case 401:
            // 조회 권한 없음
            this.$modal.error('직원정보 조회 권한이<br/>없습니다.');
            break;
          case 0:
            // 정상
            this.employeeList = response.data.result;
            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$modal.loading(false);
      }
    },
    closePopup() {
      this.showEmployeeInsertPopup = false;
      this.showConfirmPopup = false;
    },
    openEmployeeInsertPopup() {
      this.showEmployeeInsertPopup = true;
    },
  },
  mounted() {
    this.getPartnerEmployeeList();
  },
};
</script>

<style scoped>
/** 공통 */

.guide_list {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
  border-radius: 11px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  overflow: hidden;
}
.guide_list li {
  padding: 10px 20px;
  border-bottom: 1px solid #eeeeee;
}
.guide_list li:last-child {
  border-bottom: none;
}
.guide_list input[type='radio'] {
  display: none;
}
.guide_list input[type='radio'] + label {
  background: url(../../assets/images/ico_uncheck.svg) no-repeat right;
  cursor: pointer;
  display: block;
  vertical-align: middle;
}
.guide_list input[type='radio']:checked + label {
  background: url(../../assets/images/ico_checked.svg) no-repeat right;
  display: block;
}
.guide_list input[type='radio'] + label span {
  padding: 0 20px 0 0;
  width: 100%;
  display: block;
}
.guide_list li button {
  width: 100%;
  height: 40px;
  font-size: 1em;
  color: #fff;
  background: #006769;
  border-radius: 50px;
}
.guide_list .btn_area {
  position: relative;
  padding: 0px 0 30px 0;
}

.guide_list table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
}
.guide_list th {
  padding: 5px;
  background: #fafafa;

  font-weight: bold;
}
.guide_list td {
  padding: 10px 20px;
  border-bottom: 1px solid #eeeeee;
  text-align: center;
}
.btn_area .addEmp{
  position: fixed;
  bottom:18px;
  right:0; 
}
</style>