<template>
  <div class="pop_bg" style="display: block">
    <div id="pop_GuideAdd" class="pop pop_md" style="display: block">
      <!-- 보호자 변경 -->
      <div class="pop_body">
        <div class="pop_url">
          <h2>
            {{ $t('보호자_설정') }}
            <a
              href="javascript:void(0);"
              id=""
              class="btn_pop_close"
              @click="closePop"
              >{{ $t('닫기') }}</a
            >
          </h2>
          <p>
            {{
              $t(
                '입력한_보호자에게_병원_출입증_발급_링크를_자동으로_발송합니다'
              )
            }}
          </p>
           <p style="color:#006769;">{{
              $t(
                '6세이하_<84개월_미만>외래환자_보호자는_2인까지_등록_및_내원가능'
              )}}
          </p>
          <!-- <div class="num_wrap readonly"> -->
          <div class="num_wrap" v-for="(list,idx) in accList" :key="idx" :style="list.stat? 'background-color:#efefef':''">
            <div class="num_select" >
              <select v-model="list.pre" :disabled="list.stat" >
                <option>010</option>
                <option>011</option>
                <option>017</option>
                <option>018</option>
                <option>019</option>
              </select>
            </div>
            <div class="num_input" >
              <input :disabled="list.stat"
                maxlength="8"
                type="text"
                pattern="/[^0-9]/gi"
                v-model="list.post"
                placeholder="12345678"
              />
              <!-- <input type="number" value="12345678" readonly /> -->
            </div>
          </div>
          <!-- <p>이미 출입한 보호자가 병원을 나온 경우에만 변경이 가능합니다.</p> -->
          <div class="btn_area type2 inside">
            <div class="btn outlined" id="btn_pop_close" @click="closePop">
              {{ $t('취소') }}
            </div>
            <div
              class="btn"
              id="btn_pop_close"
              :class="{ disabled: !phoneNumChk }"
              @click="submit"
            >
              {{ $t('보호자_등록_및_알림_발송') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    passCheck: String,
    inStatus : Array,
    vistCnt: Array
  },
  data: () => {
    return {
      phoneNumber: '',
      prePhoneNum: '010',
      postPhoneNum: '',
      btnLock: false,
      accList: [], // 저장할 임시 리스트 
    };
  },
  computed: {
    phoneNumChk() {
      var deny = true;
      for (var i = 0; i < this.accList.length; i++) {
        var target = this.accList[i].post
        if (!(target == '' || target.length > 6)) {
          console.log("deny2" + deny);
          deny = false;
        }
        console.log("deny1" + deny);
      }
      return deny;
    },
    is010() {
      return this.prePhoneNum === '010';
    },
  },
  mounted() {
    this.getList();
    
  },
  methods: {
    getList(){
      //axios 
      // 환자의 보호자 수: count, 
      // 환자가 등록한 보호자리스트 : currentList
      const count = parseInt(this.vistCnt[0]);
      const currentList = []
      const currentStat = []
      
      if (this.value != null && this.inStatus != null) {
        for (var j = 0; j<this.value.length ; j++) {
          const temp = this.value[j].split('-');
          
          var accNo = ""
          for(var i=0; i<temp.length;i++){  
            console.log("currnetList: " + temp[i]);
            accNo += temp[i]
          }
          
          currentList.push(accNo);
          this.inStatus[j]=="IN" ? currentStat.push(true):currentStat.push(false)
        }
      }

      //출입정보 세팅 : 받아온값이 "IN"이면 출입중임으로 수정 불가, "null"이면 수정 가능
      for(var i =0 ; i < count ; i++) {
        if(currentList[i]){
          this.accList.push({
              pre: currentList[i].substring(0, 3),// currentList[i] 의 앞 3글자 
              post: currentList[i].substring(3),// currentList[i] 의 앞 3글자 제외 나머지
              stat: currentStat[i]
            })
          }else {
            this.accList.push({
              pre:'010',
              post:'',
              stat: false
            })
          }
      }

    },
    errorPopupClose() {
      this.popup.errorPopup = false;
    },
    closePop() {
      this.$emit('closePopup');
    },
    // async submit() {
    //   if (this.btnLock) return;
    //   this.btnLock = true;

    //   const phoneNum = `${this.postPhoneNum === '' ? '' : this.prePhoneNum}${
    //     this.postPhoneNum
    //   }`;
    //   try {
    //     const response = await this.$axios.post('/api/v1/identity/change-nok', {
    //       passCheck: this.passCheck,
    //       phoneNum: phoneNum,
    //     });
    //     // if (!response.data.success) throw new Error('error');

    //     switch (response.data.resultCd) {
    //       case 307:
    //         // 기존 보호자 이미 들어감
    //         this.$modal.info(
    //           this.$t('보호자_설정_불가'),
    //           this.$t(
    //             '이미_출입한_보호자가_병원을_나온_경우에만_변경이_가능합니다'
    //           )
    //         );
    //         this.postPhoneNum = '';
    //         break;
    //       case 0:
    //         this.$emit('input', phoneNum);
    //         this.$emit('closePopup');
    //         break;
    //       default:
    //         throw new Error('정의되지 않은 resultCd 오류');
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     this.$router.replace({
    //       name: 'error',
    //       params: {
    //         titleText: this.$t('유효하지_않은_접근입니다'),
    //       },
    //     });
    //   } finally {
    //     this.btnLock = false;
    //   }
    // },
    async submit() {
      if (this.btnLock) return;
      this.btnLock = true;

      const accompanyList = []

      // const phoneNum = `${this.postPhoneNum === '' ? '' : this.prePhoneNum}${
      //   this.postPhoneNum
      // }`;

      for(var i =0 ; i < this.accList.length ; i++) {
        if(this.accList[i]){
          if (this.accList[i].post !== ""){
            accompanyList.push(this.accList[i].pre+this.accList[i].post);
          }
        }
      }

      try {
        const response = await this.$axios.post('/api/v1/pt-accompanies', {
          passCheck: this.passCheck,
          accompanyList: accompanyList,
          accompanyLimit: this.vistCnt[0]
        });
        // if (!response.data.success) throw new Error('error');

        switch (response.data.resultCd) {
          case 307:
            // 기존 보호자 이미 들어감
            this.$modal.info(
              this.$t('보호자_설정_불가'),
              this.$t(
                '이미_출입한_보호자가_병원을_나온_경우에만_변경이_가능합니다'
              )
            );
            this.postPhoneNum = '';
            break;
          case 0:
            this.$emit('input', accompanyList);
            this.$emit('closePopup');
            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        console.log(error);
        this.$router.replace({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_접근입니다'),
          },
        });
      } finally {
        this.btnLock = false;
      }
    },
  },
  
};
</script>
<style scoped>
:lang(ja) p {
  word-break: break-all;
}
</style>

<style scoped>


.btn {
  width: 100%;
  height: 40px;
  font-size: 1em;

}

.btn_area {
  bottom: 0;
  left: 0%;
  padding: 0 6% 20px 6%;
  width: 100%;
}
.btn_area .btn {
  width: 100%;
  height: 40px;
  font-size: 1em;
  color: #fff;
  background: #006769;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'NEXONLv1GothicB';
}
.btn_area blockquote {
  padding-bottom: 20px;
}
.btn_area .outlined {
  margin-top: 15px;
  border: 1px solid #006769;
  color: #006769;
  background: #ffffff;
}
.btn_area .btn:last-child {
  margin-top: 20px;
}

/** 보호자변경 **/

.pop_url .num_wrap {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 32px;
  border: 1px solid #eee;
  border-radius: 5px;
  background: #ffffff;
}
.pop_url .num_select {
  float: left;
  width: 25%;
  border: none;
}
.pop_url .num_input {
  float: left;
  width: 75%;
}
.pop_url .num_input input[type='text'] {
  width: 90%;
  padding: 3px 0px 0px 10px;
  line-height: 1.5;
  border: none;
  font-size: 1em;
  color: #333;
}
.pop_url .num_wrap .noti {
  padding-top: 5px;
  clear: both;
  display: block;
  font-size: 0.8em;
  color: #f68b1f;
  letter-spacing: -1px;
}
.pop_url .btn_area {
  display: flex;
  padding: 0;
}
.pop_url .btn_area.type2 div:first-child {
  width: 30%;
  margin-top: 20px;
  margin-right: 10px;
}

.pop_url .num_wrap.readonly {
  background: #eee;
}
.pop_url .num_wrap.readonly .num_select {
  background: #eee;
}

.pop_url .num_wrap.readonly .num_input input[type='text'] {
  background: #eee;
  color: #aaa;
}

.disabled .btn {
  opacity: 0.3;
}
</style>