<template>
  <section class="container">
    <div id="header">
      <div class="btn_home"></div>
    </div>
    <div class="conts">
      <div class="wapper">
        <div class="info_page">
          <h2 v-html="$t('문진_기록을_삭제하였습니다')" />
          <blockquote
            v-html="
              getWithTalk
                ? $t(
                    '기존에_받으신_알림톡_링크를_다시_눌러서_문진표를_재작성해주시기_바랍니다'
                  )
                : $t(
                    '문진표를_재작성해주시기_바랍니다'
                  )
            "
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getWithTalk']),
  },
};
</script>

<style scoped>
.info_page {
  padding: 0 10px;
}
.info_page h2 {
  margin-top: 25%;
  margin-bottom: 10px;
  height: auto;
  line-height: 1.2;
  font-size: 30px;
}
.info_page blockquote {
  margin-bottom: 60px;
}
.btn_area {
  position: absolute;
  bottom: 0;
  left: 0%;
  padding: 0 6% 20px 6%;
  width: 100%;
}
.btn_area .btn {
  width: 100%;
  height: 40px;
  font-size: 1em;
  color: #fff;
  background: #006769;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'NEXONLv1GothicB';
}
.btn_area .outlined {
  border: 1px solid #006769;
  color: #006769;
  background: #ffffff;
}
</style>