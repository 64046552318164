<template>
  <section class="container ask">
    <NormalHeader :languageBtn="true" />
    <div class="conts">
      <div class="wapper">
        <h2>{{ $t('초대코드_입력') }}</h2>
        <blockquote v-html="$t('초대코드_4자리를_입력해주세요')" />
        <div class="ask_conts">
          <dl>
            <dd>
              <div class="num_box">
                <input
                  type="number"
                  maxlength="4"
                  placeholder="1234"
                  v-model="inputCode"
                   @keyup.enter="submit"
                />
              </div>
            </dd>
          </dl>
        </div>
        <!-- 수집 동의 -->
      </div>
      <div class="btn_area">
        <div class="btn" :class="{ disabled: !canNext }" @click="submit">
          {{ $t('다음') }}
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import NormalHeader from '../../components/v2/NormalHeader.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'GuestList_CODE',
  props: {
    identityNo: String,
    privacyCode: String,
    sensitiveCode: String,
    meetingItem: Object,
    visitDate: String,
  },
  components: {
    NormalHeader,
  },
  data: () => {
    return {
      inputCode: '',
      btnLock: false,
      isInvited: false,
    };
  },
  computed: {
    ...mapState(['USER']),
    ...mapGetters(['getUserType', 'getWithTalk', 'getLanguage', 'getKioskType', 'getCnnHome']),
    canNext: function () {
      return (
        // 전화번호 정상 여부 확인
        this.inputCode !== null &&
        this.inputCode !== '' &&
        this.inputCode.length === 4
      );
    },
  },
  mounted() {},
  watch: {
    inputCode: function (newVal) {
      this.inputCode = newVal.replace(/[^0-9]/g, '').substring(0, 4);
    },
  },
  methods: {
    ...mapMutations(['setUserType', 'setWithTalk']),
    async submit() {
      if (!this.canNext || this.btnLock) return;
      await this.checkInviteCode();
      if(this.isInvited) {
        try {
          this.btnLock = true;
          this.$modal.loading(true);
          const response = await this.$axios.post(
              '/api/v1/identity/group-open',
              {
                answerType: this.getUserType,
                identityNo: this.identityNo,
                meetingId: this.meetingItem.meetingId,
                visitDate: this.visitDate,
                pid: this.$route.query.pid || null,
              }
          );
          // if (!response.data.success) throw new Error("error");

          switch (response.data.resultCd) {
            case 201:
              // 증상있음 출입 금지.
              this.$router.replace({
                name: 'passForbiden',
                query: {
                  pass: response.data.result.passCheck,
                },
              });
              break;
            case 200:
              // 방문일에 해당하는 문진을 이미 완료했을 경우 출입증 페이지로 이동한다.
              this.$router.replace({
                name: 'pass',
                query: {
                  pass: response.data.result.passCheck,
                  isDirect: 'N',
                },
              });
              break;
            case 0:
              //문진을 사용하던 안하던 일단 agreeTerm페이지 이동 2023-06-29
              this.$router.replace({
                name: 'infoAgree',
                query: {
                  type: window.btoa(response.data.result.answerType),
                  itype: window.btoa(response.data.result.inquiryType),
                  idn: window.btoa(response.data.result.identityNo),
                  vd: window.btoa(response.data.result.visitDate),
                },
              });
              break;
            default:
              throw new Error('정의되지 않은 resultCd 오류');
          }
        } catch (error) {
          this.$modal.error();
          console.log(error);
        } finally {
          this.btnLock = false;
          this.$modal.loading(false);
        }
      } else {
        this.$modal.error(this.$t('초대코드가_일치하지_않습니다'));
      }

    },
    async checkInviteCode(){
      const response = await this.$axios.post(
          '/api/v1/identity/check-code',
          {
            meetingId: this.meetingItem.meetingId,
            inviteCode: this.inputCode
          }
      );
      if(response.data.result) {
        this.isInvited = true;
      }
    }
  },
};
</script>
<style scoped>
.num_box input[type='number'] {
  width: 100%;
  margin-top: 20px;
  font-size: 1.4em;
  border: none;
  border-bottom: 2px solid #cccccc !important;
  text-align: center;
  font-family: 'NEXONLv1GothicB';
  background: #f6f6f6;
   color:#333333;
}
.num_box input[type='number']::placeholder {
  color: #ccc;
}
.num_box input[type='number']:focus {
  color: #333333;
  border-bottom: 2px solid #509d9c !important;
}
.ask blockquote {
  width: 100%;
  line-height: 1.4;
  
  font-weight: 300;
  letter-spacing: 0px;
}

.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.ask dt {
  margin-bottom: 10px;
  font-size: 1em;
  color: #006769;
  font-weight: bold;
}
.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.btn_no {
  float: left;
  width: 48% !important;
  color: #006769 !important;
  border: 1px solid #006769 !important;
  background: #fff !important;
}
.btn_yes {
  float: left;
  margin-right: 4%;
  width: 48% !important;
  color: #fff !important;
  background-color: #006769 !important;
}
.ask dd li {
  padding: 0 0 15px 15px;
  line-height: 1.3;
  background: url('../../assets/images/ico_dot.svg') no-repeat;
  background-size: 1%;
  background-position: 3px 9px;
}
.ask dd li:last-child {
  padding-bottom: 30px;
}
.ask dd li p {
  font-size: 0.8em;
  line-height: 1.3;
}

.btn_area .outlined {
  margin-top: 15px;
  border: 1px solid #006769;
  color: #006769;
  background: #ffffff;
}
.btn_area .btn:last-child {
  margin-top: 10px;
}
</style>