<template>
  <section class="container">
    <div id="header">
      <div class="btn_home">
        <!-- <a href="">처음으로</a> -->
      </div>
    </div>
    <div class="conts">
      <div class="wapper">
        <div class="info_page">
          <h2 v-html="$t('귀하의_출입증이_발급되지_않았습니다')" />
          <blockquote
            v-html="
              getUserType === USER.PEMP
                ? $t('부서장에게_보고해주세요')
                : $t('대표번호_1688_7575_으로_문의하여_주시기_바랍니다')
            "
          />
        </div>
        <div v-if="passCheck !== null && getInquiryYn" class="btn_area">
          <div @click="openPopup" class="btn outlined">
            {{ $t('문진_재작성') }}
          </div>
        </div>
      </div>
    </div>
    <CheckReInquiryPopup
      v-if="retryInquiryPopup"
      @closePopup="closePopup"
      @okBtn="okBtn"
    />
  </section>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import CheckReInquiryPopup from '../../components/v2/popup/CheckReInquiryPopup.vue';
export default {
  components: { CheckReInquiryPopup },
  name: 'passForbiden',
  data() {
    return {
      passCheck: null,
      retryInquiryPopup: false,
    };
  },
  computed: {
    ...mapState(['USER']),
    ...mapGetters(['getUserType', 'getInquiryYn']),
  },
  mounted() {
    this.passCheck = this.$route.query.pass || null;
  },
  methods: {
    async retryInquiry() {
      this.btnLock = true;
      try {
        const response = await this.$axios.post('/api/v1/identity/re-inquiry', {
          passCheck: this.passCheck,
        });
        // if (!response.data.success) throw new Error('error');

        switch (response.data.resultCd) {
          case 309:
            // 병원 내 출입중 재문진 실행 불가
            this.$modal.error(
              this.$t(
                '원내_출입_중에는_재문진이_불가능합니다_원외로_이동_후_재문진을_시작해_주세요'
              )
            );
            this.closePopup();
            break;
          case 305:
            // 만료된 문진 데이터
            this.$router.replace({ name: 'error' });
            break;
          case 0:
            this.$router.replace({ name: 'retryInfo' });
            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        console.log(error);
        this.$router.replace({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_접근입니다'),
          },
        });
      } finally {
        this.btnLock = false;
      }
    },
    openPopup() {
      this.retryInquiryPopup = true;
    },
    closePopup() {
      this.retryInquiryPopup = false;
    },
    okBtn() {
      this.retryInquiry();
    },
  },
};
</script>
<style scoped>
.info_page {
  padding: 0 10px;
}
.info_page h2 {
  margin-top: 25%;
  margin-bottom: 10px;
  height: auto;
  line-height: 1.2;
  font-size: 30px;
}
.info_page blockquote {
  margin-bottom: 60px;
}
.btn_area {
  position: absolute;
  bottom: 0;
  left: 0%;
  padding: 0 6% 20px 6%;
  width: 100%;
}
.btn_area .btn {
  width: 100%;
  height: 40px;
  font-size: 1em;
  color: #fff;
  background: #006769;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'NEXONLv1GothicB';
}
.btn_area .outlined {
  border: 1px solid #006769;
  color: #006769;
  background: #ffffff;
}
</style>