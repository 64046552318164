var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container ask"},[_c('NormalHeader',{attrs:{"languageBtn":true}}),_c('div',{staticClass:"conts"},[_c('div',{staticClass:"wapper"},[_c('h2',[_vm._v(_vm._s(_vm.$t('긴급_방문력_조사')))]),_c('blockquote',[(_vm.isEMP)?_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.$t(
              '본인확인을_위해_문진_알림_메시지를_받으신_분의_사번을_입력해주세요'
            )
          )}}):_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.$t(
              '본인확인을_위해_알림_메시지를_받으신_분의_휴대전화_번호_마지막_4자리를_입력해주세요'
            )
          )}}),(!_vm.isEMP)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('예_010_1234_5678_인_경우_5678_입력'))}}):_vm._e()]),_c('div',{staticClass:"ask_conts"},[_c('dl',[_c('dd',[_c('div',{staticClass:"num_box"},[(_vm.isEMP)?_c('input',{attrs:{"type":"text","readonly":""},domProps:{"value":_vm.identityNo}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastPlcTelno),expression:"lastPlcTelno"}],attrs:{"type":"number","placeholder":"1234"},domProps:{"value":(_vm.lastPlcTelno)},on:{"input":function($event){if($event.target.composing)return;_vm.lastPlcTelno=$event.target.value}}})])])])]),_c('Terms',{ref:"terms",attrs:{"ruleText":"","pvc":_vm.privacyCode,"ssc":_vm.sensitiveCode},model:{value:(_vm.isTermChecked),callback:function ($$v) {_vm.isTermChecked=$$v},expression:"isTermChecked"}}),_c('div',{staticClass:"btn_area",class:{ disabled: !_vm.checkInput },on:{"click":_vm.submit}},[_c('div',{staticClass:"btn"},[_vm._v(_vm._s(_vm.$t('확_인')))])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }