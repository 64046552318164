<template>
  <div v-if="isShow" class="pop_bg" style="display: block">
    <div id="popup_yes" class="pop pop_md">
      <!-- 유효성 검사 팝업 -->
      <div class="pop_head type2">{{ infoTitle }} <br /></div>
      <div class="pop_body">
        <p style="text-align: center" v-html="infoMessage"></p>
      </div>
      <!-- 버튼 -->
      <div class="btn_area scroll">
        <div class="btn" @click="close">{{ $t('확인') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      infoTitle: '',
      infoMessage: '',
    };
  },
  methods: {
    close() {
      this.isShow = false;
    },
    show(infoTitle, infoMessage) {
      this.infoTitle = infoTitle;
      this.infoMessage = infoMessage;
      this.isShow = true;
    },
  },
  beforeMount() {
    this.$modal.on('info', ({ title, message }) => this.show(title, message));
    this.$modal.on('hide', this.close);
  },
  beforeDestroy() {
    this.$modal.off('info');
    this.$modal.off('hide');
  },
};
</script>
