<template>
  <div v-if="isShow" class="pop_bg" style="display: block">
    <div id="popup_yes" class="pop pop_error">
      <!-- 미응답 팝업 -->
      <div class="pop_head"><h2></h2></div>
      <div class="pop_body">
        <p class="error_msg" v-html="errorMessage"></p>
      </div>
      <!-- 버튼 -->
      <div class="btn_area scroll">
        <div class="btn" @click="close">{{ $t('확인') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      errorMessage: '',
    };
  },
  methods: {
    close() {
      this.isShow = false;
    },
    show(message) {
      this.errorMessage = message || this.$t('유효하지_않은_접근입니다');
      this.isShow = true;
    },
  },
  beforeMount() {
    this.$modal.on('error', ({ message }) => this.show(message));
    this.$modal.on('hide', this.close);
  },
  beforeDestroy() {
    this.$modal.off('error');
    this.$modal.off('hide');
  },
};
</script>

<style scoped>
.pop_error {
  padding: 20px 0 0 0;
}
</style>