<template>
  <div v-if="isShow" class="pop_bg">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    close() {
      this.isShow = false;
    },
    show() {
      this.isShow = true;
    },
  },
  beforeMount() {
    this.$modal.on('loadingOn', () => this.show());
    this.$modal.on('loadingOff', () => this.close());
  },
  beforeDestroy() {
    this.$modal.off('loadingOn');
    this.$modal.off('loadingOff');
  },
};
</script>

<style scoped>
.pop_bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
}

.pop_bg div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  /*! autoprefixer: off */
  -webkit-animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent;
}

.pop_bg div:first-child {
  /*! autoprefixer: off */
  -webkit-animation-delay: -0.45s;
  animation-delay: -0.45s;
}

.pop_bg div:nth-child(2) {
  /*! autoprefixer: off */
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.pop_bg div:nth-child(3) {
  /*! autoprefixer: off */
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
}
/*! autoprefixer: off */
@-webkit-keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
@keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
</style>
