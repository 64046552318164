var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conts"},[_c('div',{staticClass:"wapper"},[_c('h2',[_vm._v(_vm._s(_vm.$t('온라인_출입증_발급')))]),_c('div',{staticClass:"ask_conts"},[_c('dl',[_c('dd',[_c('blockquote',[_c('div',{domProps:{"innerHTML":_vm._s(
                _vm.$t(
                  '본인확인을_위해_알림_메시지를_받으신_분의_휴대전화_번호_마지막_4자리를_입력해주세요'
                )
              )}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('예_010_1234_5678_인_경우_5678_입력'))}})]),_c('div',{staticClass:"num_box"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastPlcTelno),expression:"lastPlcTelno"}],attrs:{"maxlength":"4","type":"number","placeholder":"1234"},domProps:{"value":(_vm.lastPlcTelno)},on:{"input":function($event){if($event.target.composing)return;_vm.lastPlcTelno=$event.target.value}}})])])])]),_c('div',{staticClass:"btn_area"},[(_vm.getValidInquiryCnt > 0 && _vm.getUserType === _vm.USER.RNOK)?_c('blockquote',{domProps:{"innerHTML":_vm._s(
          _vm.$t(
            '본_문진표를_허위로_작성할_경우_보건_관련_법률_및_형법에_따라_처벌_또는_과태료_1천만원_가_부과됩니다_입원_예약자_및_상주보호자에_한함'
          )
        )}}):_vm._e(),(_vm.getValidInquiryCnt > 0 && _vm.getUserType !== _vm.USER.RNOK)?_c('blockquote',{domProps:{"innerHTML":_vm._s(
          _vm.$t(
            '본_문진표를_허위로_작성할_경우_보건_관련_법률_및_형법에_따라_처벌_또는_과태료_1천만원_가_부과됩니다_noBr'
          )
        )}}):_vm._e(),_c('div',{staticClass:"btn",class:{ disabled: !_vm.checkInput },on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('확_인'))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }