<template>
  <section class="container ask">
    <template v-if="!isNotDirect || getWithTalk">
      <NormalHeader />
      <div v-if="barcodeValue" class="conts">
        <div class="wapper">
          <h2>{{ $t('출입증_발급_완료') }}</h2>
          <blockquote>
            <div>{{ $t('출입등록이_완료되었습니다') }}</div>
            <div>{{ $t('출입구에서_바코드를_스캔해주세요') }}</div>
          </blockquote>
          <div class="print_info">
            <h3 id="passTitle">
              {{ $t('출입증') }}
              <br />
              <em>({{ pubPassTimeText }} {{ passTypeText }})</em>
              <QrcodeVue
                v-if="
                  barcodeValue &&
                  !popup.updateCompanionPopup &&
                  !popup.retryInquiryPopup
                "
                :value="barcodeValue"
                :size="90"
                :class="'qrcode'"
              />
            </h3>
            <div id="userType" class="title">{{ getUserTypeText }}</div>
            <div id="userId" class="id" style="min-height: 40px">{{ identityNo }}</div>
            <div v-if="!completePlace" class="info">
              {{ $t('방문력_확인필요') }}
            </div>
            <div class="barcode">
              <!-- <img src="../images/img_barcode.png"> -->
              <VueBarcode
                v-if="barcodeValue"
                :value="barcodeValue"
                height="100"
                displayValue="false"
              />
            </div>
            <div class="date">
              <b>{{ timeStamp }}</b>
            </div>
            <!-- <div class="noti">(당일만 사용 가능)</div> -->
          </div>
          <div
            v-if="isPT && this.accompanyId !== null && this.accompanyId !== ''"
            class="with_guide"
          >
            <p>{{ $t('나의_보호자') }}</p>
            <p>{{ companionPhoneNumber }}</p>
          </div>
          <div class="btn_area scroll">
            <div v-if="isPT" class="btn" id="btnGuide" @click="openPopup">
              {{ $t('보호자_등록_변경') }}
            </div>
            <div
              v-if="isPEMP_Manager"
              class="btn"
              id="btnGuide"
              @click="goManage"
            >
              {{ $t('직원_등록_관리') }}
            </div>
            <div
              v-if="passType !== 'T' && agreePassYn !== 'Y' && getInquiryYn"
              :class="{ disabled: !retryReady }"
              @click="retryInquiry"
              class="btn outlined"
            >
              {{ $t('문진_재작성') }}
            </div>
          </div>
        </div>
      </div>
      <UpdateCompanion
        v-if="popup.updateCompanionPopup"
        v-model="accompanyId"
        :passCheck="passCheck"
        :inStatus="accompanyStatus"
        :vistCnt="vistCnt"
        @closePopup="closePopup"
      />
    </template>
    <template v-else>
      <div id="header"></div>
      <div class="conts">
        <div class="wapper">
          <div class="info_page">
            <h2 v-html="$t('출입증_발급을_완료하였습니다.')" />
            <blockquote
              v-html="$t('모바일_출입증은_아래_연락처로_발송됩니다')"
            />
            <div class="phone_num">{{ phoneFormatter(phoneNum, 0) }}</div>
          </div>
          <div v-if="getInquiryYn" class="btn_area" >
            <div
              :class="{ disabled: !retryReady }"
              @click="retryInquiry"
              class="btn outlined"
            >
              {{ $t('문진_재작성') }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <CheckReInquiryPopup
      v-if="popup.retryInquiryPopup"
      @closePopup="closePopup"
      @okBtn="okRetry"
    />
  </section>
</template>
<script>
import NormalHeader from '../../components/v2/NormalHeader.vue';
import CheckReInquiryPopup from '../../components/v2/popup/CheckReInquiryPopup.vue';
import VueBarcode from 'vue-barcode';
import UpdateCompanion from '../../components/v2/popup/UpdateCompanion.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import moment from 'moment';
import QrcodeVue from 'qrcode.vue';

export default {
  components: {
    NormalHeader,
    VueBarcode,
    UpdateCompanion,
    CheckReInquiryPopup,
    QrcodeVue,
  },
  data: () => {
    return {
      phoneNum: '',
      passCheck: '',
      visitDate: null,
      identityNo: null,
      answerTypeNm: null,
      answerType: null,
      accompanyId: [],
      completePlace: true,
      pid: null, // 환자/상주보호자 가 pid로 시작하여 문진 제출했을때 이 값이 셋팅되어있을 예정 -> 문진재작성 시 전달해줘야 함
      partnerEmpId: null,
      btnLock: false,
      popup: { updateCompanionPopup: false, retryInquiryPopup: false },
      partnerGroupId: null,
      managerYn: null,
      passType: null,
      agreePassYn: null,
      accompanyStatus: null,
      vistCnt: null,
      empAuth: null,
    };
  },
  computed: {
    ...mapState(['USER']),
    ...mapGetters([
      'getUserType',
      'getUserTypeText',
      'getWithTalk',
      'getLanguage',
      'getInquiryYn'
    ]),
    passTypeText() {
      if (this.passType === 'T') return `(${this.$t('1회_출입_가능')})`;
      else if (this.passType === 'D')
        return `${this.$t('24시간_동안만_출입_가능')}`;
      else if (this.passType === 'M') return `${this.$t('당일만_사용_가능')}`;
      else return '';
    },
    isPT() {
      return this.getUserType === this.USER.PT;
    },
    isPEMP_Manager() {
      return this.getUserType === this.USER.PEMP && this.managerYn === 'Y' && this.empAuth == 'Y';
    },
    barcodeValue() {
      return `${this.identityNo}${moment()
        .subtract(1, 'minutes')
        .format('YYMMDDHHmm')}`;
    },
    pubPassTimeText() {
      return moment(this.visitDate).format('MM-DD');
    },
    retryReady() {
      return !this.btnLock && this.passCheck;
    },
    companionPhoneNumber() {
      var accList = this.convertPhone(this.accompanyId);
      var accStrList = "";
      for (var i = 0; i<accList.length; i++) {
        accStrList +=accList[i];
        if(i !== accList.length - 1) {
          accStrList +=", ";
        }
      }
      return accStrList
    },
    isNotDirect() {
      return this.$route.query.isDirect === 'N';
    },
    timeStamp() {
      if (this.getLanguage === 'ko') {
        return `${this.$t('바코드_생성')} ${moment().format(
          'YYYY-MM-DD HH:mm'
        )}`;
      }
      return moment().format('YYYY-MM-DD HH:mm');
    },
  },
  mounted() {
    this.passCheck = this.$route.query.pass || null;

    if (!this.passCheck)
      this.$router.replace({
        name: 'error',
        params: {
          titleText: this.$t('비정상적인_접근_입니다'),
        },
      });

    this.getPassCheckInfo();

  },

  methods: {
    ...mapMutations(['setUserType', 'setUserTypeText']),
    ...mapActions(['InquiryUseCheck']),
    async okRetry() {
      if (!this.retryReady) return;
      this.btnLock = true;
      try {
        const response = await this.$axios.post('/api/v1/identity/re-inquiry', {
          passCheck: this.passCheck,
        });
        // if (!response.data.success) throw new Error('error');

        switch (response.data.resultCd) {
          case 309:
            // 병원 내 출입중 재문진 실행 불가
            this.$modal.error(
              this.$t(
                '원내_출입_중에는_재문진이_불가능합니다_원외로_이동_후_재문진을_시작해_주세요'
              )
            );
            this.closePopup();
            break;
          case 305:
            // 만료된 문진 데이터
            throw new Error('만료된 문진 데이터 오류');
          case 307:
            // 다른 보호자가 입장 중입니다.
            this.$modal.error(this.$t('다른_보호자가_입장_중입니다'));
            break;
          case 0:
            // 문진작성으로 이동
            this.$router.push({
              name: 'inquiry',
              query: {
                type: window.btoa(response.data.result.answerType),
                itype: window.btoa(response.data.result.inquiryType),
                idn: window.btoa(response.data.result.identityNo),
                vd: window.btoa(response.data.result.visitDate),
                pid: this.pid,
                peid: this.partnerEmpId,
                pvc: window.btoa(response.data.result.privacyCode),
                ssc: window.btoa(response.data.result.sensitiveCode),
                pidn: window.btoa(response.data.result.refId),
              },
            });
            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        console.log(error);
        this.$router.replace({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_접근입니다'),
          },
        });
      } finally {
        this.btnLock = false;
      }
    },
    async retryInquiry() {
      if (!this.retryReady) return;
      this.popup.retryInquiryPopup = true;
    },
    async getPassCheckInfo() {
      try {
        const response = await this.$axios.post('/api/v1/identity/pass-check', {
          passCheck: this.passCheck,
        });
        // if (!response.data.success) throw new Error('error');
        switch (response.data.resultCd) {
          case 201:
            // 입장 불가 passCheck
            this.$router.replace({
              name: 'passForbiden',
              query: {
                pass: response.data.result.passCheck,
              },
            });
            break;
          case 305:
            // 만료된 PassCheck
            this.$router.replace({
              name: 'error',
              params: {
                titleText: this.$t('유효하지_않은_출입증입니다'),
              },
            });
            break;
          case 0:
            // 정상
            this.answerType = response.data.result.answerType;
            this.setUserType(this.answerType);
            // this.getUserType == 'PT' ? 
            //   await this.InquiryUseCheck({inqType:this.$route.query.inqType, ptno: response.data.result.identityNo, vd: response.data.result.visitDate}) 
            //   : await this.InquiryUseCheck();
            await this.inqUseCheckPT(response);
            this.identityNo = response.data.result.identityNo;
            this.visitDate = response.data.result.visitDate;
            // this.accompanyId = response.data.result.accompanyId;
            this.completePlace = response.data.result.completePlace;
            this.passType = response.data.result.passType;
            this.agreePassYn = response.data.result.agreePassYn;
            this.pid = response.data.result.pid || null;
            this.partnerEmpId =
              this.getUserType === this.USER.PEMP &&
              response.data.result.refMasterId
                ? response.data.result.refMasterId
                : null;

            if (response.data.result.answerType === this.USER.PT) {
              this.phoneNum = await this.apiCheckPatient(
                response.data.result.identityNo
              );
            } else {
              this.phoneNum = response.data.result.identityNo;
            }
            if (this.answerType === this.USER.PEMP && this.partnerEmpId)
              this.getPartnerEmpInfo();

            if(this.getUserType == 'PEMP'){
              const temp = document.getElementById("userType");
              const temp2 = document.getElementById("userId");
              const temp3 = document.getElementById("passTitle");
              temp.style.color = "#ff7802"
              temp2.style.color = "#ff7802"
              temp3.style.color = "#ff7802"
            } else if (this.getUserType === 'ICUGST' || this.getUserType === 'TMP' || this.getUserType === 'WGST') {
              if(response.data.result.vistReason) {
                  this.setUserTypeText(response.data.result.vistReason);
              }
            }
            if(response.data.result.answerType === 'PT'){
              await this.getAccompaniesInfo();
            }
            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        console.log(error);
        this.$router.push({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_출입증입니다'),
          },
        });
      }
    },
    async apiCheckPatient(identityNo) {
      const response = await this.$axios.post(
        '/api/v1/identity/check-patient',
        { identityNo }
      );
      // if (!response.data.success) throw new Error('error');
      switch (response.data.resultCd) {
        case 300:
          // 존재하지 않는 identityNo
          throw new Error('error');
        case 0:
          // 정상 조회 완료
          return response.data.result.mbphno;
        default:
          throw new Error('정의되지 않은 resultCd 오류');
      }
    },
    async getPartnerEmpInfo() {
      try {
        this.$modal.loading(true);
        const response = await this.$axios.post('/api/v1/identity/pemp', {
          partnerEmpId: this.partnerEmpId,
        });
        // if (!response.data.success) throw new Error('error');
        switch (response.data.resultCd) {
          case 301:
            // api호출일 기준으로 해당 방문예약이 잡혀있지 않음.
            throw new Error('방문예약이 잡혀있지 않음 오류');
          case 201:
            // 증상있음 출입 금지.
            this.$router.replace({
              name: 'passForbiden',
              query: {
                pass: response.data.result.passCheck,
              },
            });
            break;
          case 200:
          case 0:
            this.partnerGroupId = response.data.result.partnerGroupId;
            this.managerYn = response.data.result.managerYn;
            this.empAuth = response.data.result.empAuth;
            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        console.log(error);
        this.$router.push({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_접근입니다'),
          },
        });
      } finally {
        this.$modal.loading(false);
      }
    },
    async getAccompaniesInfo() {
      const params = this.passCheck;

      try {
        this.$modal.loading(true);
        const response = await this.$axios.get('/api/v1/pt-accompanies/' + params);
        // if (!response.data.success) throw new Error('error');
        switch (response.data.resultCd) {
          case 0:
            this.accompanyId = response.data.result[0];
            this.accompanyStatus = response.data.result[1];
            this.vistCnt = response.data.result[2];
            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        console.log(error);
        this.$router.push({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_접근입니다'),
          },
        });
      } finally {
        this.$modal.loading(false);
      }
    },
    
    goManage() {
      this.$router.push({
        name: 'employeeManage',
        params: {
          partnerEmpId: this.partnerEmpId,
          partnerGroupId: this.partnerGroupId,
        },
      });
    },
    convertPhone(phone) {
      console.log("결과");
      console.log(phone);
      if (phone == null) {
        return null;
      } else {
        for (var i = 0;i<phone.length;i++){
          phone[i] = phone[i].replace(
            /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/,
            '$1-$2-$3'
          );
        }
        return phone;
      }
    },
    openPopup() {
      this.popup.updateCompanionPopup = true;
    },
    closePopup() {
      this.popup.updateCompanionPopup = false;
      this.popup.retryInquiryPopup = false;
    },
    phoneFormatter(num, type) {
      let formatNum = '';
      try {
        if (num.length === 11) {
          if (type === 0) {
            formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
          } else {
            formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
          }
        } else if (num.length === 8) {
          formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2');
        } else {
          if (num.indexOf('02') === 0) {
            if (type === 0) {
              formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
            } else {
              formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
            }
          } else {
            if (type === 0) {
              formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
            } else {
              formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
            }
          }
        }
      } catch (e) {
        formatNum = num;
      }
      return formatNum;
    },
    async inqUseCheckPT(response){  // 환자 문진 완료 후 출입증 링크로 접속하여 inqType이 쿼리로 안 넘어왔을 경우
      if(this.getUserType == 'PT'){
        if(this.$route.query.inqType){
          this.InquiryUseCheck({inqType:this.$route.query.inqType, ptno: response.data.result.identityNo, vd: response.data.result.visitDate})
        } else {
          const res = await this.$axios.post('/api/v3/identity/patient', {
            identityNo: response.data.result.identityNo,
            answerType: this.getUserType,
            visitDate: response.data.result.visitDate,
          });
          this.InquiryUseCheck({inqType: res.data.result.inquiryType, ptno: response.data.result.identityNo, vd: response.data.result.visitDate});
        }
      } else {
        await this.InquiryUseCheck();
      }
    }
  },
};
</script>
<style>
.vue-barcode-element {
  width: 80%;
}
</style>
<style scoped>
.barcode-img {
  width: 50px;
  height: 100px;
}
input:focus {
  outline: none;
}

/** 출입증 출력 **/
</style>
<style lang="scss" scoped>
.btn_area {
  bottom: auto;
  margin-top: 20px;
}

.print_info {
  h3 {
    line-height: 30px;
    em {
      font-size: 16px;
      line-height: 16px;
      color: #333;
    }
  }
}

.print_info .qrcode {
  top: 65px;
  background-image: url('../../assets/images/1x1_ffffffff.png');
  background-repeat: repeat;
  padding: 10px;
  z-index: 100;
}

:lang(ru) {
  .print_info .qrcode {
    top: 100px;
  }
}
:lang(mn) {
  .print_info .qrcode {
    top: 100px;
  }
}
#userType {
  width: 240px;
}
</style>