<template>
  <section class="container ask">
    <NormalHeader />
    <div class="conts">
      <div class="wapper">
        <h2>{{ $t('긴급_방문력_조사') }}</h2>
        <div class="question_list">
          <div v-for="(item, index) in categoryListFilter" :key="index">
            <!-- 일반문항(기본) 형 -->
            <Normal
              v-if="item.categoryType === 'N'"
              :contents="item"
              :ref="`inquiry_${index}`"
            />
            <!-- 객관식 형 -->
            <MultiChoice
              v-else-if="item.categoryType === 'M'"
              :contents="item"
              :ref="`inquiry_${index}`"
            />
            <!-- 주관식 형 -->
            <ShortAnswer
              v-else-if="item.categoryType === 'T'"
              :contents="item"
              :ref="`inquiry_${index}`"
            />
            <!-- 안내문구 형 -->
            <Editor
              v-else-if="item.categoryType === 'E'"
              :contents="item"
              :ref="`inquiry_${index}`"
            />
          </div>
        </div>
        <div class="btn_area scroll">
          <div v-if="identityNo !== null" class="btn" @click="submit">
            {{ $t('완료') }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import NormalHeader from '../../components/v2/NormalHeader.vue';
import Normal from '../../components/v2/inquiryType/Normal.vue';
import MultiChoice from '../../components/v2/inquiryType/MultiChoice.vue';
import ShortAnswer from '../../components/v2/inquiryType/ShortAnswer.vue';
import Editor from '../../components/v2/inquiryType/Editor.vue';
import moment from 'moment';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'EmerInvestigation',
  components: {
    NormalHeader,
    Normal,
    MultiChoice,
    ShortAnswer,
    Editor,
  },
  props: {
    checkPid: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      privacyCode: null,
      sensitiveCode: null,
      identityNo: null,
      answerType: null,
      visitDate: null,
      detailId: null,
      categoryList: [],
      btnLock: false, //버튼 잠금(제출중 일때 중복 제출 방지용)
      passCheck: null,
      pid: null,
      state: 'F',
    };
  },
  computed: {
    ...mapState(['USER', 'InqType']),
    ...mapGetters(['getUserType', 'getLanguage', 'getKioskType']),
    categoryListFilter() {
      return this.categoryList.filter(
        (item) =>
          // 동거인 관련 카테고리
          item.categoryType !== this.InqType.Housemate &&
          item.categoryType !== this.InqType.HousemateRelation &&
          // 긴급방문에서 제공 안하는 카테고리
          item.categoryType !== this.InqType.NormalImg &&
          item.categoryType !== this.InqType.VisitHistory &&
          item.categoryType !== this.InqType.Etc &&
          item.show !== false
      );
    },
    refreshIdx() {
      return this.$route.params.refreshIdx;
    },
  },
  async beforeMount() {
    // Direct URL 테스트 URL
    // 환자-6880 : http://localhost:8080/#/emer-invst?idn=NjIzNTAzMzBB&emh=7545ffd4-eecd-4825-8a12-d710d660b24f&vd=MjAyMTEwMDc%3D&aid=Uk5PSw%3D%3D&pid=595eaa75-1ee4-434f-ac7e-b54b59e9f5ee
    // 직원 : http://localhost:8080/#/emer-invst?idn=RVQwMDc5OQ%3D%3D&emh=7545ffd4-eecd-4825-8a12-d710d660b24f&vd=MjAyMTEwMDc%3D&aid=RU1Q&pid=0029e7dd-9036-4096-8efc-09a0b979e1c7
    this.identityNo = this.$route.query.idn
      ? window.atob(this.$route.query.idn)
      : null;
    this.emergencyHistory = this.$route.query.emh ?? null;
    this.answerType = this.$route.query.aid
      ? window.atob(this.$route.query.aid)
      : this.getUserType;
    this.privacyCode = this.$route.query.pvc
      ? window.atob(this.$route.query.pvc)
      : null;
    this.sensitiveCode = this.$route.query.ssc
      ? window.atob(this.$route.query.ssc)
      : null;
    this.visitDate = this.$route.query.vd
      ? window.atob(this.$route.query.vd)
      : moment().format('YYYYMMDD');
    this.passCheck = this.$route.query.pass ?? null;
    this.pid = this.$route.query.pid ?? null;

    if (this.emergencyHistory) {
      await this.getInquiryInfo();
    } else if (this.refreshIdx) {
      // 미리보기 팝업으로 실행된 경우
      this.getCategoryInfoFromAdmin();
    } else
      this.$router.replace({
        name: 'error',
        params: {
          titleText: this.$t('유효하지_않은_접근입니다'),
        },
      });

    // 긴급방문력 Push URL 최초 클릭 시 PID 조회 확인부터 하도록 페이지 이동
    if (
      this.categoryList.length > 0 &&
      this.answerType &&
      this.pid &&
      !this.checkPid
    ) {
      // Direct URL 접속 시
      this.setUserType(this.answerType);
      this.$router.replace({
        name: 'emerAuth',
        query: {
          ...this.$route.query,
          pvc: window.btoa(this.privacyCode),
          ssc: window.btoa(this.sensitiveCode),
        },
      });
    }
  },
  methods: {
    ...mapMutations(['setUserType', 'setLanguage']),
    getCategoryInfoFromAdmin() {
      // 미리보기 팝업 시 호출주체로부터 데이터 입력받음
      const eventListenerFtn = async (e) => {
        if (e.data.method === 'needCategoryData') {
          this.$modal.loading(false);
          this.$i18n.locale = e.data.language;
          this.setLanguage(this.$i18n);
          this.categoryList = e.data.categoryList;
          window.removeEventListener('message', eventListenerFtn);
        }
      };
      this.$modal.loading(true);
      window.addEventListener('message', eventListenerFtn);
      window.opener.postMessage({ method: 'needCategoryData' }, '*');
    },
    async getInquiryInfo() {
      try {
        this.$modal.loading(true);
        const response = await this.$axios.get('/api/v1/inquiry-em', {
          params: {
            emergencyId: this.emergencyHistory,
            language: this.getLanguage,
          },
        });
        console.log(response.data.result.state);
        if(response.data.result.state === 'T'){
          this.state = true;
        } else {
          this.state = false;
        }
        // if (!response.data.success) throw new Error('error');
        switch (response.data.resultCd) {
          case 305:
            this.$router.replace({
              name: 'error',
              params: {
                titleText: this.$t('출입증_발급기한이_만료되었습니다'),
                showDetailText: false,
              },
            });
            break;
          case 0:
            this.detailId = response.data.result.detailId;
            this.categoryList = response.data.result.categoryList;
            this.privacyCode = response.data.result.privacyCode;
            this.sensitiveCode = response.data.result.sensitiveCode;
            break;
          default:
            throw new Error('정의되지 않은 resultCd');
        }
      } catch (error) {
        console.log(error);
        this.$router.replace({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_접근입니다'),
          },
        });
      } finally {
        this.$modal.loading(false);
      }
    },
    async submitPreAnswer() {
      if (this.btnLock) return;
      this.btnLock = true;
      try {
        this.$modal.loading(true);
        await this.$axios.post('/api/pre/v1/answer-em', {
          answerType: this.answerType,
          inqType: 'EM',
          visitDate: this.visitDate,
          deviceType: 'M',
          detailId: this.detailId,
          privacyCode: this.privacyCode,
          sensitiveCode: this.sensitiveCode,
          categoryList: this.categoryList,
          kioskType: this.getKioskType,
          identityNo: this.identityNo,
        });
        // 긴급방문력은 결과에 상관없이 thankyou페이지로 이동
        this.$router.push({
          name: 'thankyou',
          query: {
            pass: this.passCheck,
          },
        });
      } catch (error) {
        console.log(error);
        this.$router.replace({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_접근입니다'),
          },
        });
      } finally {
        this.btnLock = false;
        this.$modal.loading(false);
      }
    },
    checkInput() {
       if(this.state) {
        this.$modal.error(
            "테스트 발송의 경우 응답을 전송할 수 없습니다."
          );
        return;
      }
      for (const key in this.$refs) {
        if (Object.hasOwnProperty.call(this.$refs, key)) {
          if (!key.includes('inquiry')) continue;
          const inquiryComponent = this.$refs[key][0];
          // 각 컴포넌트 별 데이터 업데이트 실행 후 true 리턴이 되는지 확인
          if (inquiryComponent?.saveData && !inquiryComponent?.saveData()) {
            inquiryComponent.$el.scrollIntoView({ behavior: 'smooth' }); // move scroll
            setTimeout(() => {
              this.$modal.error(
                this.$t('아직_응답하지_않은_질문이_남아있습니다')
              );
            }, 200);
            return false;
          }
        }
      }
      return true;
    },
    submit() {
      if (!this.checkInput()) return; // 데이터 정상 입력 여부 체크
      this.submitPreAnswer();
    },
    setShow({ targetCategoryId, isShow, turnOffTargetCategoryId }) {
      for (const item of this.categoryList) {
        if (item.categoryId === targetCategoryId) {
          item.show = isShow;
        }
        if (item.categoryId === turnOffTargetCategoryId) {
          item.show = false;
        }
      }
    },
  },
  watch: {
    refreshIdx: function () {
      this.$router.go();
    },
  },
};
</script>
<style scoped="scoped">
.ask blockquote {
  width: 100%;
  line-height: 1.4;

  font-weight: 300;
  letter-spacing: 0px;
}
.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.ask dt {
  margin-bottom: 10px;
  font-size: 1em;
  color: #006769;
  font-weight: bold;
}
.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.btn_no {
  float: left;
  width: 48% !important;
  color: #006769 !important;
  border: 1px solid #006769 !important;
  background: #fff !important;
}
.btn_yes {
  float: left;
  margin-right: 4%;
  width: 48% !important;
  color: #fff !important;
  background-color: #006769 !important;
}
.ask dd li {
  padding: 0 0 15px 15px;
  line-height: 1.3;
  background: url('../../assets/images/ico_dot.svg') no-repeat;
  background-size: 1%;
  background-position: 3px 9px;
}
.ask dd li:last-child {
  padding-bottom: 30px;
}
.ask dd li p {
  font-size: 0.8em;
  line-height: 1.3;
}

.btn_area {
  position: fixed;
  bottom: 0;
  left: 0%;
  padding: 0 6% 20px 6%;
  width: 100%;
}
.btn_area .btn {
  width: 100%;
  height: 40px;
  font-size: 1em;
  color: #fff;
  background: #006769;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'NEXONLv1GothicB';
}
.btn_area blockquote {
  padding-bottom: 20px;
}
.btn_area .outlined {
  margin-top: 15px;
  border: 1px solid #006769;
  color: #006769;
  background: #ffffff;
}
.btn_area .btn:last-child {
  margin-top: 10px;
}
.btn_area.scroll {
  position: relative;
  padding: 0 0 20px 0;
}
.btn_area.inside {
  position: relative;
  padding: 0;
}

/** 문진목록 **/
.question_list {
  margin-top: 10px;
  padding-bottom: 10px;
}
.question_list h3 {
  word-break: break-all;
  margin-bottom: 5px;
  font-size: 1em;
  font-weight: bold;
  color: #222222;
}
.question_list h3 p {
  word-break: break-all;
  margin-top: 5px;
  margin-bottom: 15px;
  line-height: 1.2;

  font-weight: normal;
}

.question_list h5 {
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  color: #222222;
  border-bottom: 1px solid #eeeeee;
}
.question_list h5 p {
  font-weight: normal;
}
.question_list ul {
  width: 100%;
  margin-bottom: 20px;
  padding: 0px;
  border-radius: 11px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  overflow: hidden;
}
.question_list ul .family_q {
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
  display: none;
  border-top: 1px solid #eeeeee;
}

.question_list ul .family_q ul {
  width: 100%;
  padding: 0;
  margin: 0 auto 30px auto;
  box-shadow: none;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-radius: 0;
}
.question_list ul .family_q ul:last-child {
  border-bottom: none;
}
/* .question_list ul:last-child{box-shadow:none;background-color: #f6f6f6; } */
.question_list li {
  position: relative;
  width: 100%;
  padding: 13px 45px 13px 20px;

  line-height: 1.4;
  border-bottom: 1px solid #efefef;
}
.question_list li:last-child {
  border-bottom: none;
  overflow: hidden;
}
.question_list input[type='checkbox'] {
  display: none;
}
.question_list input[type='checkbox'] + label {
  padding: 10px 20px;
  background: url(../../assets/images/ico_uncheck.svg) no-repeat 94% 12px;
  cursor: pointer;
  display: block;
  vertical-align: middle;
}
.question_list input[type='checkbox']:checked + label {
  background: url(../../assets/images/ico_checked.svg) no-repeat 94% 12px
    #eaffff;
  display: block;
}
.question_list input[type='checkbox'] + label span {
  padding: 0 20px 0 0;
  width: 100%;
  display: block;
}
.question_list li button {
  margin: 10px auto;
  width: 90%;
  height: 40px;
  font-size: 1em;
  color: #fff;
  background: #006769;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.question_list li button:disabled {
  background: #cccccc;
}
.question_list .btn_area {
  position: relative;
  padding: 10px 20px 20px 20px;
  display: flex;
}
.question_list .btn_area .btn:last-child {
  margin: 15px 0 0 15px;
}
.question_list li span > span {
  font-size: 0.8em;
}
.question_list .sm_txt {
  font-size: 0.8em;
  line-height: 1.2;
}
.question_list .chk {
  position: absolute;
  top: 12px;
  right: 20px;
  width: 21px;
  height: 21px;
  background: url(../../assets/images/ico_uncheck.svg) no-repeat right;
  cursor: pointer;
  display: block;
  vertical-align: middle;
}
.question_list .selected {
  background: #eaffff;
}
.question_list .selected .chk {
  width: 21px;
  height: 21px;
  background: url(../../assets/images/ico_checked.svg) no-repeat right;
  display: block;
}
.family_chk {
  position: relative;
  padding: 13px 20px 13px 50px;
  line-height: 1.3;
  font-size: 1em;
  font-weight: bold;
  color: #006769;
}
.family_chk .chk {
  top: 12px;
  left: 20px;
  background: url(../../assets/images/ico_pri_unchecked.svg) no-repeat 0 0 !important;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}
.family_chk.selected .chk {
  top: 12px;
  left: 20px;
  background: url(../../assets/images/ico_pri_check.svg) no-repeat 0 0 !important;
  display: block;
}
</style>