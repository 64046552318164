<template>
  <div class="pop_bg" style="display: block">
    <div id="popup_yes" class="pop pop_md">
      <!-- 유효성 검사 팝업 -->
      <div class="pop_head type3">{{ guideText }}</div>
      <!-- 버튼 -->
      <div class="btn_area scroll type3">
        <div class="btn outlined" @click="cancleBtn">{{ $t('취소') }}</div>
        <div class="btn" @click="okBtn">{{ $t('확인') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmPopup',
  props: {
    guideText: String,
  },
  methods: {
    okBtn() {
      this.$emit('ok');
    },
    cancleBtn() {
      this.$emit('cancle');
    },
  },
};
</script>