import Vue from 'vue';
import App from './App.vue';
import Vuex from 'vuex';
import axios from 'axios';
import request from './lib/request';
import modal from './lib/modal';
import { router } from './router/index.js';
import i18n from './i18n';
import store from './store';
import moment from 'moment';

moment.locale('ko')

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.use(modal);
Vue.use(Vuex);
Vue.use(request);

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app');
