<template>
  <div class="pop_bg" style="display: block">
    <div id="popup_yes" class="pop pop_md">
      <!-- 유효성 검사 팝업 -->
      <div class="pop_head type2">
        {{ $t('마지막_방문일을_선택하세요') }}
        <p>{{ datePickerPopupText }}</p>
      </div>
      <div class="pop_body">
        <div class="datepickerWrap">
          <DatePicker
            style="zoom: 1"
            ref="datePicker"
            v-model="selectDate"
            valueType="format"
            :inline="true"
            :disabled-date="disabledRange"
            :defaultValue="defaultDate"
            :lang="getLanguage"
          />
        </div>
      </div>
      <!-- 버튼 -->
      <div class="btn_area type3">
        <div class="btn outlined" @click="onClosePopup">{{ $t('취소') }}</div>
        <div
          class="btn"
          @click="okBtn"
          :class="{ disabled: selectDate === null }"
        >
          {{ $t('다음') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import ko from 'date-format-parse/lib/locale/ko';
import 'vue2-datepicker/locale/ko';
import 'vue2-datepicker/locale/en';
import '../../../assets/css/datepicker.scss';
import { mapGetters } from 'vuex';

DatePicker.locale('ko', {
  formatLocale: ko,
  yearFormat: 'YYYY년',
  monthFormat: 'MM월',
  monthBeforeYear: false,
});

export default {
  name: 'DatePickerPopup',
  components: {
    DatePicker,
  },
  props: {
    selectDateTarget: Object,
  },
  data() {
    return {
      selectDate: null,
      defaultDate: null,
    };
  },
  computed: {
    ...mapGetters(['getLanguage']),
    datePickerPopupText() {
      try {
        return `${
          this.getLanguage === 'ko' || !this.selectDateTarget.localEn1
            ? this.selectDateTarget.local1
            : this.selectDateTarget.localEn1
        } ${this.tdTextValue(this.selectDateTarget)}`;
      } catch {
        return '';
      }
    },
  },
  mounted() {
    this.selectDate = this.selectDateTarget.placeDate;
    const boundaryDates = this.selectDateTarget.boundaryDateSplit.split('|');
    this.defaultDate = moment(boundaryDates[boundaryDates.length - 1]).toDate();
  },
  methods: {
    onClosePopup() {
      this.$emit('closeDatePickerPopup');
    },
    okBtn() {
      if (!this.selectDate) return;
      this.selectDateTarget.placeDate = this.selectDate;
      this.$emit('closeDatePickerPopup');
    },
    tdTextValue(item) {
      if (this.getLanguage === 'ko') {
        return `${item.local2 === '-' ? '' : item.local2} ${
          item.local3 === '-' ? '' : item.local3
        } ${item.storeName} (${item.boundaryDate})`;
      } else {
        const loc2 =
          item.localEn2 && item.localEn2 !== '' && item.localEn2 !== '-'
            ? item.localEn2
            : `${item.local2 === '-' ? '' : item.local2}`;
        const loc3 =
          item.localEn3 && item.localEn3 !== '' && item.localEn3 !== '-'
            ? item.localEn3
            : `${item.local3 === '-' ? '' : item.local3}`;
        const storeNm =
          item.storeNameEn && item.storeNameEn !== ''
            ? item.storeNameEn
            : item.storeName;

        return `${loc2} ${loc3} ${storeNm} (${item.boundaryDate})`;
      }
    },
    disabledRange(date) {
      const target = moment(date).format('YYYY-MM-DD');
      return !this.selectDateTarget.boundaryDateSplit
        .split('|')
        .includes(target);
    },
  },
};
</script>
<style scoped="scoped">
.pop_body {
  padding: 20px 0 0 0 !important;
  text-align: center !important;
}
.datepickerWrap {
  display: inline-block;
  overflow: hidden;
}
.mx-calendar-content .cell.active {
  background: #006769;
}
.mx-datepicker {
  width: auto;
}
.mx-datepicker-main {
  border: none !important;
}

.ask blockquote {
  width: 100%;
  line-height: 1.4;
  
  font-weight: 300;
  letter-spacing: 0px;
}
.ask .ask_conts {
  margin-top: 25px;
  min-height: 350px;
}
.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.ask dt {
  margin-bottom: 10px;
  font-size: 1em;
  color: #006769;
  font-weight: bold;
}
.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.btn_no {
  float: left;
  width: 48% !important;
  color: #006769 !important;
  border: 1px solid #006769 !important;
  background: #fff !important;
}
.btn_yes {
  float: left;
  margin-right: 4%;
  width: 48% !important;
  color: #fff !important;
  background-color: #006769 !important;
}
.ask dd li {
  padding: 0 0 15px 15px;
  line-height: 1.3;
  background: url('../../../assets/images/ico_dot.svg') no-repeat;
  background-size: 1%;
  background-position: 3px 9px;
}
.ask dd li:last-child {
  padding-bottom: 30px;
}
.ask dd li p {
  font-size: 0.8em;
  line-height: 1.3;
}

.error_page h2 {
  height: 110px;
  line-height: 70px;
  padding-top: 30px;
  background: url('../../../assets/images/ico_error.svg') no-repeat;
}

.privacy_wrap {
  position: absolute;
  bottom: 60px;
  width: 90%;
  padding: 10px 0 20px 0;
  color: #222222;
}
.privacy_wrap.relative {
  position: relative;
  width: 100%;
  padding: 0 20px 20px 20px;
}
.privacy_wrap li {
  position: relative;
  padding: 5px;
}
.privacy_wrap .chk a {
  position: absolute;
  top: 0px;
  right: 0;
  text-decoration: underline;
  font-size: 0.8em;
}
.privacy_wrap .chk {
  position: relative;
  width: 100%;
  padding-left: 30px;
  background: url(../../../assets/images/ico_pri_unchecked.svg) no-repeat 0 0;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}
.privacy_wrap .chk.checked {
  background: url(../../../assets/images/ico_pri_check.svg) no-repeat 0 0;
  display: inline-block;
}

.pop_bg {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  background: rgb(0, 0, 0, 0.8);
}
.pop {
  position: absolute;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.2);
  z-index: 100;
}
.pop .btn_pop_close {
  position: absolute;
  top: 15px;
  right: 20px;
  width: 20px;
  height: 20px;
  background: url('../../../assets/images/btn_p_close.svg') no-repeat;
  overflow: hidden;
  text-indent: -1000px;
  display: inline-block;
}
.pop h2 {
  margin-top: 0px;
  margin-bottom: 0;
  font-size: 1em;
  text-align: left;
  color: #393939;
  height: 33px;
  font-weight: 500;
}
.pop .pop_head {
  position: relative;
  margin-top: 0px;
  margin-bottom: 0;
  padding: 50px 30px 0 30px;
  font-size: 1em;
  line-height: 60px;
  overflow: hidden;
  font-family: 'NEXONLv1GothicB', sans-serif;
  text-align: center;
  color: #006769;
}


.pop .pop_body {
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: left;
  overflow: hidden;
}

.pop .list_name dl {
  min-height: auto;
  margin-bottom: 20px;
}
.pop .list_name dl:last-child {
  margin-bottom: 0;
}
.pop .list_name .radio dt {
  padding-left: 25px;
  margin-bottom: 0px;
  font-size: 1em;
  background: url(../../../assets/images/ico_radio_unchk.svg) no-repeat left;
  cursor: pointer;
  display: block;
  vertical-align: middle;
  color: #666666;
}
.pop .list_name .radio dd {
  padding-left: 25px;
}
.pop .list_name .radio.select dt {
  margin-bottom: 0px;
  font-size: 1em;
  background: url(../../../assets/images/ico_radio_chk.svg) no-repeat left;
  display: block;
  color: #006769;
}
.pop .list_name dd {
  padding-left: 0px;
}
.pop .list_name input[type='radio'] {
  display: none;
}
.pop .list_name input[type='radio'] + label {
  background: url(../../../assets/images/ico_radio_unchk.svg) no-repeat left;
  cursor: pointer;
  display: block;
  vertical-align: middle;
}
.pop .list_name input[type='radio']:checked + label {
  background: url(../../../assets/images/ico_radio_chk.svg) no-repeat left;
  display: block;
}
.pop .list_name input[type='radio'] + label span {
  padding: 0 0 0 25px;
  width: 100%;
  display: block;
}
.pop .btn_area {
  text-align: center;
}
.pop .btn_area.scroll {
  position: relative;
}
</style>