<template>
  <section class="container ask">
    <NormalHeader :languageBtn="true" />
    <div class="conts">
      <div class="wapper">
        <h2>{{ $t('온라인_출입증_발급') }}</h2>
        <div class="ask_conts">
          <dl>
            <dd>
              <blockquote>
                <div
                  v-html="
                    $t(
                      '본인확인을_위해_알림_메시지를_받으신_분의_휴대전화_번호_마지막_4자리를_입력해주세요'
                    )
                  "
                />
                <div v-html="$t('예_010_1234_5678_인_경우_5678_입력')" />
              </blockquote>
              <div class="num_box">
                <input
                  maxlength="4"
                  type="number"
                  v-model="lastPlcTelno"
                  placeholder="1234"
                />
              </div>
            </dd>
          </dl>
        </div>
        <div class="btn_area">
          <blockquote
              v-if="getValidInquiryCnt > 0"
              v-html="
              $t(
                '본_문진표를_허위로_작성할_경우_보건_관련_법률_및_형법에_따라_처벌_또는_과태료_1천만원_가_부과됩니다_입원_예약자_및_상주보호자에_한함'
              )
            "
          />
          <div :class="{ disabled: !checkInput }" class="btn" @click="submit">
            {{ $t('확_인') }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import NormalHeader from '../components/v2/NormalHeader.vue';
import { mapState, mapMutations, mapGetters} from 'vuex';
import debounce from 'lodash/debounce';

export default {
  name: 'PhonePatient',
  data: () => {
    return {
      lastPlcTelno: null, //전화번호 마지막 4자리
      pid: null,
    };
  },
  components: {
    NormalHeader,
  },
  computed: {
    ...mapState(['USER']),
    ...mapGetters(['getUserType', 'getValidInquiryCnt']),
    checkInput() {
      if (this.lastPlcTelno === null || this.lastPlcTelno.length < 4) {
        return false;
      }
      if (this.pid === null) {
        return false;
      }
      return true;
    },
    getSubmitURL() {
      if (this.getUserType === this.USER.RNOK)
        return '/api/v1/identity/check/rnok-pid';
      if (this.$route.query?.type === 'S')
        return '/api/v1/identity/check/pat-pid-s';
      return '/api/v1/identity/check/pat-pid';
    },
    getNextRouteName() {
      return this.getUserType === this.USER.RNOK ? '/rnok-auth' : '/pt';
    },
  },
  async mounted() {
    // 환자
    // test URL(1234) - 정상 : http://localhost:8080/#/phone-pt?pid=536ed33410408a87491559cfec3967a8dd4f5af1eedfe6a27381915830a607aa
    // test URL(1234) - 만료된 사용자 : http://localhost:8080/#/phone-pt?pid=636ed33410408a87491559cfec3967a8dd4f5af1eedfe6a27381915830a607aa

    // 상주보호자
    // test URL(정상) : http://localhost:8080/#/rnok?pid=736ed33410408a87491559cfec3967a8dd4f5af1eedfe6a27381915830a607aa
    // test URL(만료) : http://localhost:8080/#/rnok?pid=836ed33410408a87491559cfec3967a8dd4f5af1eedfe6a27381915830a607aa

    // 환자상세 발송 URL(환자)
    // test URL(정상) - 3239 : http://localhost:8080/#/phone-pt?pid=1dc20b75-8978-49ee-a02a-f60784454e35&type=S

    if (this.$route.name === 'phonePt') this.setUserType(this.USER.PT);
    else if (this.$route.name === 'rnokPhone') this.setUserType(this.USER.RNOK);

    if (this.$route.query?.pid) {
      this.pid = this.$route.query.pid;
    } else {
      this.$router.replace({ name: 'error' });
    }
  },
  watch: {
    lastPlcTelno: function (newVal) {
      // 숫자만 최대 4자리
      this.lastPlcTelno = newVal.substring(0, 4).replace(/[^0-9]/gi, '');
    },
  },
  methods: {
    ...mapMutations(['setUserType']),
    submit: debounce(
      async function () {
        // 입력값 확인
        if (!this.checkInput) return;

        //상주보호자의 문진이 off일경우 문진 시행이 아니라는 메시지 출력.
        // if(this.getUserType === this.USER.RNOK && this.getValidInquiryCnt == 0) {
        //   this.$modal.error(
        //       `${this.$t(
        //           "문진 시행기간이 아닙니다."
        //       )}`
        //   );
        //   return;
        // }

        try {
          const url = this.getSubmitURL;
          const targetPath = this.getNextRouteName;
          const response = await this.$axios.post(url, {
            lastPlcTelno: this.lastPlcTelno,
            pid: this.pid,
          });

          // if (!response.data.success) throw new Error('error');

          if (response.data.resultCd === 0) {
            if (this.$route.query?.type === 'S') {
              //type s로 넘어온 pid는 pid를 넘기지 않는다.
              this.pid = null;
              this.$router.push({
                path: targetPath,
                query: {
                  ptno: window.btoa(response.data.result.patNo),
                  vd: window.btoa(response.data.result.visitDate),
                },
              });
            } else {
              this.$router.push({
                path: targetPath,
                query: {
                  ptno: window.btoa(response.data.result.patNo),
                  vd: window.btoa(response.data.result.visitDate),
                  pid: this.pid,
                },
              });
            }
          } else if (response.data.resultCd === 305) {
            if (
              response.data.resultMsg &&
              typeof response.data.resultMsg === 'string' &&
              response.data.resultMsg !== '' &&
              response.data.resultMsg.includes('뒷자리')
            ) {
              this.$modal.error(
                this.$t(
                  '휴대전화_번호가_일치하지_않습니다_휴대전화_번호를_한번_더_확인해주세요'
                )
              );
              this.lastPlcTelno = '';
            } else {
              this.$router.push({
                name: 'error',
                params: {
                  titleText: this.$t('출입증_발급기한이_만료되었습니다'),
                },
              });
            }
          } else if (response.data.resultCd === 306) {
            // VisitDate 미도래
            this.$modal.error(response.data.resultMsg);
            this.lastPlcTelno = '';
          } else if (response.data.resultCd === 310) {
            // 당일 상주보호자의 환자가 퇴원수속을 끝낸 경우
            this.$modal.error(
              this.$t('퇴원환자의_경우_보호자_문진을_작성할_수_없습니다')
            );
            this.lastPlcTelno = '';
          } else throw new Error('알수없는 오류 발생');
        } catch (error) {
          console.log(error);
          this.$router.push({
            name: 'error',
            params: {
              titleText: this.$t('유효하지_않은_접근입니다'),
            },
          });
        }
      },
      3000,
      { leading: true, trailing: false }
    ),
  },
};
</script>

<style scoped>
input:focus {
  outline: none;
}
</style>