<template>
  <section class="container ask" :class="{wide : isWide}">
    <NormalHeaderNoPass :languageBtn="true" />   
    <div class="conts">
      <div class="wapper">
        <h2>{{ $t('출입등록') }}</h2>
        <blockquote v-html="$t('본인의_휴대전화_번호를_입력해주세요')" />
        <div class="ask_conts">
          <dl>
            <dd>
              <div class="num_box">
                <input
                  type="number"
                  pattern="\d*"
                  placeholder="01012345678"
                  v-model="identityNo"
                  maxlength="11"
                  :readonly="inputLock"
                />
              </div>
            </dd>
          </dl>
        </div>
        <!-- 수집 동의 -->
         <Terms
          ref="terms"
          v-model="isTermChecked"
          ruleText
          :pvc="privacyCode"
          :ssc="sensitiveCode"
          :key="componentKey"
        />
      </div>
      <div class="btn_area">
        <div class="btn" :class="{ disabled: !canNext }" @click="submit">
          {{ $t('확_인') }}
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import NormalHeaderNoPass from '../../components/v2/NormalHeaderNoPass.vue';
import Terms from '../../components/v2/Terms.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'normal_inq',
  components: {
    Terms,
    NormalHeaderNoPass,
   
  },
  data: () => {
    return {
      isTermChecked: false, // 약관 동의 여부(2개)
      identityNo: null,
      inputLock: false, // identityNo 값을 URL에 가지고 들어오면 Lock
      btnLock: false,
      visitDate: null,
      inquiryType : null,
      sensitiveCode: null,
      privacyCode: null,
      componentKey: 0,
      answerType: null,
      isWide : false,      
    };
  },
  computed: {
    ...mapState(['USER']),
    ...mapGetters(['getUserType', 'getWithTalk', 'getLanguage']),
    canNext: function () {
      return (
        // 전화번호 정상 여부 확인
        this.identityNo !== null &&
        this.identityNo !== '' &&
        this.identityNo.length > 9 &&
        // 약관 동의 여부확인
        this.isTermChecked
      );
    },
  },
  async beforeMount() {      

    if(this.$route.params.inqCode) {
       this.inquiryType = this.$route.params.inqCode.toUpperCase();
    }
      // userType 구분
    if(this.$route.query.type) {
      this.answerType = this.$route.query.type.toUpperCase();
    } else {
      this.answerType = this.inquiryType;
    }

    this.setUserType(null);
    
    this.visitDate = moment().format('YYYYMMDD');   
    if (this.visitDate && this.visitDate < moment().format('YYYYMMDD')) {
      this.$router.replace({ name: 'error' });
    }
    await this.checkInquiryInfo();
    await this.getPrivacyInfo();

    //와이드 화면 파라미터 
    if(this.$route.query.wide) {
      let wide = this.$route.query.wide;
      if(wide === 'Y') {
        this.isWide = true;
      }
    }
  },
  watch: {
    identityNo: function (newVal) {
      this.identityNo = newVal.replace(/[^0-9]/g, '').substring(0, 11);
    },
  },
  methods: {
    ...mapMutations(['setUserType', 'setWithTalk']),
    async checkInquiryInfo() {
      if (this.btnLock) return;
      try {
        this.btnLock = true;
        this.$modal.loading(true);
        const response = await this.$axios.post('/api/v1/check-valid-inq', {
          inquiryType : this.inquiryType
        });

        console.log(response);
        switch (response.data.resultCd) {
          case 300:
            // 조회된 행사 없음
            this.$router.push({
              name: 'error',
              params: {
                titleText: this.$t('유효하지_않은_접근입니다'),
              },
            });
            break;
          case 304 :
            await this.$router.replace({
                  name: 'error',
                  params: {
                    titleText: this.$t('유효하지_않은_문진입니다'),
                  },
                });
            break;
          case 0:
            if (response.data.result) {
              if(response.data.result.inquiryType.toUpperCase() != this.inquiryType.toUpperCase()) {
                await this.$router.replace({
                  name: 'error',
                  params: {
                    titleText: this.$t('유효하지_않은_문진입니다'),
                  },
                });
              }              
              // 집합행사 종료 후
            } else throw new Error('알수없는 에러');
            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        console.log(error);
        this.$router.push({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_접근입니다'),
          },
        });
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    goNextETC() {
      // 기타방문자 방문목적 선택 페이지로 이동
      const { privacyCode, sensitiveCode } = this.$refs.terms.getTermsCode();
      this.$router.push({
        name: 'guestEtc',
        params: {
          identityNo: this.identityNo,
          privacyCode: privacyCode,
          sensitiveCode: sensitiveCode,
          visitDate: this.visitDate,
        },
      });
    },    
    async getPrivacyInfo() {
      console.log("get Privacy code aldert");
      try {
        const params = {
          answerType : this.inquiryType,
          language: this.getLanguage,
        };
        const response = await this.$axios.get('/api/v1/term', { params });
        console.log(response.data.result);       
        if('ko' === this.getLanguage) {
           this.privacy = response.data.result.privacy.privacyContents;
           this.sensitive = response.data.result.sensitive.privacyContents;  
        } else {
          this.privacy = response.data.result.privacy.privacyContentsEng;
          this.sensitive = response.data.result.sensitive.privacyContentsEng; 
        }        
        this.privacyCode = response.data.result.privacy.privacyCode;
        this.sensitiveCode = response.data.result.sensitive.privacyCode;
        this.componentKey += 1;
      } catch (error) {
        console.log(error);
        this.$modal.error(         
          this.$t('개인정보_수집_정보를_불러오지_못하였습니다')
        );
      }
    },
    submit() {
      if (!this.canNext || this.btnLock) return;
      let query = {};
      if(this.isWide) {
          query = {
                type: window.btoa(this.answerType),
                itype: window.btoa(this.inquiryType),
                idn: window.btoa(this.identityNo),
                vd: window.btoa(this.visitDate),
                pvc: window.btoa(this.privacyCode),
                ssc: window.btoa(this.sensitiveCode),    
                wide: 'Y'            
              }
      } else {
           query = {
                type: window.btoa(this.answerType),
                itype: window.btoa(this.inquiryType),
                idn: window.btoa(this.identityNo),
                vd: window.btoa(this.visitDate),
                pvc: window.btoa(this.privacyCode),
                ssc: window.btoa(this.sensitiveCode),                
              }
      }
         
       this.$router.replace({
              name: 'inquiryNoPass',
              query : query
            });
    },
  },
};
</script>
<style scoped>
.num_box input[type='number'] {
  width: 100%;
  margin-top: 20px;
  font-size: 1.4em;
  border: none;
  border-bottom: 2px solid #cccccc !important;
  text-align: center;
  font-family: 'NEXONLv1GothicB';
  background: #f6f6f6;
  color: #333333;
}
.num_box input[type='number']::placeholder {
  color: #ccc;
}
.num_box input[type='number']:focus {
  color: #333333;
  border-bottom: 2px solid #509d9c !important;
}
.ask blockquote {
  width: 100%;
  line-height: 1.4;

  font-weight: 300;
  letter-spacing: 0px;
}
.ask .ask_conts {
  margin-top: 25px;
  min-height: 350px;
}
.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.ask dt {
  margin-bottom: 10px;
  font-size: 1em;
  color: #006769;
  font-weight: bold;
}
.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.btn_no {
  float: left;
  width: 48% !important;
  color: #006769 !important;
  border: 1px solid #006769 !important;
  background: #fff !important;
}
.btn_yes {
  float: left;
  margin-right: 4%;
  width: 48% !important;
  color: #fff !important;
  background-color: #006769 !important;
}
.ask dd li {
  padding: 0 0 15px 15px;
  line-height: 1.3;
  background: url('../../assets/images/ico_dot.svg') no-repeat;
  background-size: 1%;
  background-position: 3px 9px;
}
.ask dd li:last-child {
  padding-bottom: 30px;
}
.ask dd li p {
  font-size: 0.8em;
  line-height: 1.3;
}

.error_page h2 {
  height: 110px;
  line-height: 70px;
  padding-top: 30px;
  background: url('../../assets/images/ico_error.svg') no-repeat;
}
.btn_area .outlined {
  margin-top: 15px;
  border: 1px solid #006769;
  color: #006769;
  background: #ffffff;
}
.btn_area .btn:last-child {
  margin-top: 10px;
}
.wide {
  zoom: 150%;       /*  default */
}
</style>