<template>
    <section class="container" :class="{wide : isWide}">
        <div id="header">
            <div class="btn_home" style="left: 20px; right: unset;">
                <a href="javascript:void(0)" @click="goHome()">
                    {{$t('처음으로')}}</a>
            </div>
        </div>
        <div class="conts">
            <div class="wapper">
                <div class="info_page">
                    <h2 v-html="$t('작성을_완료하였습니다_감사합니다')"/>
                    <br/>
                     <p  v-html="$t('10초_후에_처음화면으로_되돌아갑니다', { '0': `${countDown}` })"/>
                </div>
               
            </div>
        </div>
        <CheckReInquiryPopup
            v-if="retryInquiryPopup"
            @closePopup="closePopup"
            @okBtn="okBtn"/>
    </section>
</template>
<script>
    import {mapGetters, mapMutations, mapState} from 'vuex';

    export default {
        name: 'Thankyou',
        data() {
            return {
                passCheck: null,
                retryInquiryPopup: false,
                visitDate: null,
                inqType: null,
                isWide: null,
                answerType: null,
                countDown: 10,
                setIntervalId: null
            };
        },
        computed: {
            ...mapState(['USER']),
            ...mapGetters(['getUserType']),
            isSubTitle() {
                return (
                    this.getUserType === this.USER.PT || this.getUserType === this.USER.NOK || this.getUserType === this.USER.GST || this.getUserType === this.USER.TMP || this.getUserType === this.USER.PEMP
                );
            }
        },
        mounted() {
            if (this.$route.query.inqType) {
                this.inqType = window.atob(this.$route.query.inqType);
            }

            if (this.$route.query.answerType) {
                this.answerType = window.atob(this.$route.query.answerType);
            }
            //와이드 화면 파라미터
            if (this.$route.query.wide) {
                let wide = this.$route.query.wide;
                if (wide === 'Y') {
                    this.isWide = true;
                }
            }
            document
                .querySelector('body')
                .scrollTo(0, 0);
            window.scrollTo(0, 0);
            this.setIntervalId = setInterval(() => {
                if (this.countDown > 0) {
                    this.countDown--;
                } else {
                    this.goHome();
                }
            }, 1000);
        },
        methods: {
            ...mapMutations(['setUserType']),
            goHome() {
                let query = {};
                if (this.isWide) {
                    query = {
                        wide: 'Y',
                        type: this.answerType
                            ? this
                                .answerType
                                .toLowerCase()
                            : null
                    }
                }

                this
                    .$router
                    .replace({
                        path: '/inquiry-research/' + this
                            .inqType
                            .toLowerCase(),
                        query: query,
                        type: this.answerType
                            ? this
                                .answerType
                                .toLowerCase()
                            : null
                    });
            }

        },
        beforeDestroy() {
            clearInterval(this.setIntervalId);
        }
    };
</script>
<style scoped="scoped">
    .info_page {
        padding: 0 10px;
    }
    .info_page h2 {
        margin-top: 25%;
        margin-bottom: 10px;
        height: auto;
        line-height: 1.2;
        font-size: 30px;
    }
    .info_page blockquote {
        margin-bottom: 60px;
    }
    .btn_area {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 6% 20px;
        width: 100%;
    }
    .btn_area .btn {
        width: 100%;
        height: 40px;
        font-size: 1em;
        color: #fff;
        background: #006769;
        border-radius: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: 'NEXONLv1GothicB';
    }
    .btn_area .outlined {
        border: 1px solid #006769;
        color: #006769;
        background: #ffffff;
    }
    .wide {
        zoom: 150%;
        /*  default */
    }
</style>