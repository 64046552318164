<template>
  <section class="container ask" :class="{wide : isWide}">    
    <div class="conts">
      <div v-if="!popup.inputCompanionPopup" class="wapper">
        <h2>{{ $t('문진') }}</h2>
        <div class="question_list">
          <div v-for="(item, index) in categoryListFilter" :key="index">
            <!-- 일반문항(기본) 형 -->
            <Normal
              v-if="item.categoryType === 'N'"
              :contents="item"
              :ref="`inquiry_${index}`"
              @setShow="setShow"
            />
            <!-- 일반문항(이미지) 형 -->
            <Normal
              v-else-if="item.categoryType === 'I'"
              :contents="item"
              :ref="`inquiry_${index}`"
              @setShow="setShow"
            />
            <!-- 객관식 형 -->
            <MultiChoice
              v-else-if="item.categoryType === 'M'"
              :contents="item"
              :ref="`inquiry_${index}`"
              @setShow="setShow"
            />
            <!-- 주관식 형 -->
            <ShortAnswer
              v-else-if="item.categoryType === 'T'"
              :contents="item"
              :ref="`inquiry_${index}`"
            />
            <!-- 안내문구 형 -->
            <Editor
              v-else-if="item.categoryType === 'E'"
              :contents="item"
              :ref="`inquiry_${index}`"
            />
            <!-- 방문력 형 -->
            <VisitHistory
              v-else-if="item.categoryType == 'H' && item.placeList !== null"
              :ref="`inquiry_${index}`"
              :contents="item"
            />
          </div>
        </div>
        <HomemateWrapper
          v-if="getUserType === USER.EMP && categoryList.length > 0"
          ref="homemate"
          :categoryList="categoryList"
          :visitHistoryTemplete="visitHistoryTemplete"
        />
        <div v-if="identityNo !== null" class="btn_area scroll">
          <div class="btn" @click="submit">{{ $t('완료') }}</div>
        </div>
      </div>
      <InputCompanion
        v-if="popup.inputCompanionPopup"
        v-model="accompanyId"
        @closePopup="submitPreAnswer"
      />
    </div>
  </section>
</template>
<script>
import InputCompanion from '../../components/v2/popup/inputCompanion.vue';
import Normal from '../../components/v2/inquiryType/Normal.vue';
import VisitHistory from '../../components/v2/inquiryType/VisitHistory.vue';
import MultiChoice from '../../components/v2/inquiryType/MultiChoice.vue';
import ShortAnswer from '../../components/v2/inquiryType/ShortAnswer.vue';
import Editor from '../../components/v2/inquiryType/Editor.vue';
import HomemateWrapper from '../../components/v2/homemateInquiry/homemateWrapper.vue';
import moment from 'moment';
import lodash from 'lodash';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'inquiry',
  components: {
    Normal,
    VisitHistory,
    InputCompanion,
    HomemateWrapper,
    MultiChoice,
    ShortAnswer,
    Editor,
  },
  data: () => {
    return {
      answerType: null,
      identityNo: null,
      inquiryType: null,
      visitDate: null,
      privacyCode: null,
      sensitiveCode: null,
      emergencyHistory: null,
      refId: null,
      partnerEmpId: null,
      detailId: null,
      categoryList: [],
      visitHistoryTemplete: null,
      accompanyId: '',
      popup: {
        inputCompanionPopup: false, // 동반보호자 팝업
      },
      btnLock: false, //버튼 잠금(제출중 일때 중복 제출 방지용),
      isWide : false
    };
  },
  computed: {
    ...mapState(['USER', 'InqType']),
    ...mapGetters(['getUserType', 'getLanguage', 'getKioskType']),
    categoryListFilter() {
      return this.categoryList.filter((item) => {
        if (item.categoryType === this.InqType.VisitHistory) {
          // 방문력은 한국어/영어 선택시에만 노출
          return this.getLanguage === 'ko' || this.getLanguage === 'en';
        } else {
          return (
            // 동거인 관련 카테고리
            item.categoryType !== this.InqType.Housemate &&
            item.categoryType !== this.InqType.HousemateRelation &&
            // 사전문진에서 제공 안하는 카테고리
            item.categoryType !== this.InqType.Etc &&
            item.show === true
          );
        }
      });
    },
    refreshIdx() {
      return this.$route.params.refreshIdx;
    },
  },
  mounted() {
    this.answerType = this.$route.query.type
      ? window.atob(this.$route.query.type)
      : null;
    !this.getUserType && this.setUserType(this.answerType);
    this.identityNo = this.$route.query.idn
      ? window.atob(this.$route.query.idn)
      : null;
    this.inquiryType = this.$route.query.itype
      ? window.atob(this.$route.query.itype)
      : null;
    this.visitDate = this.$route.query.vd
      ? window.atob(this.$route.query.vd)
      : moment().format('YYYYMMDD');
    this.refId = this.$route.query.pidn
      ? window.atob(this.$route.query.pidn)
      : null;   
    this.privacyCode = this.$route.query.pvc
      ? window.atob(this.$route.query.pvc)
      : null;
    this.sensitiveCode = this.$route.query.ssc
      ? window.atob(this.$route.query.ssc)
      : null;   
    if (this.inquiryType) this.getInquiryInfo();
    else if (this.refreshIdx !== undefined) {
      // 미리보기 팝업으로 실행된 경우
      this.getCategoryInfoFromAdmin();
    } else {
      this.$router.replace({
        name: 'error',
        params: {
          titleText: this.$t('유효하지_않은_접근입니다'),
        },
      });
    }

     //와이드 화면 파라미터 
    if(this.$route.query.wide) {
      let wide = this.$route.query.wide;
      if(wide === 'Y') {
        this.isWide = true;
      }
    }
  },
  methods: {
    ...mapMutations(['setUserType', 'setLanguage']),
    getCategoryInfoFromAdmin() {
      // 미리보기 팝업 시 호출주체로부터 데이터 입력받음
      const eventListenerFtn = async (e) => {
        if (e.data.method === 'needCategoryData') {
          this.$modal.loading(false);
          this.inquiryType = e.data.inquiryType;
          this.$i18n.locale = e.data.language;
          this.setLanguage(this.$i18n);
          const placeList = await this.getPlaceHistoryInfo();
          e.data.categoryList.forEach((item) => {
            if (item.categoryType === this.InqType.VisitHistory) {
              // 방문력은 최신데이터로 업데이트
              item.placeList = placeList;
            }
          });
          this.categoryList = e.data.categoryList;
          e.data.inquiryType === this.USER.EMP &&
            this.setUserType(this.USER.EMP); // EMP 의 경우 동거문진 표시를 위한 setUserType 필요
          this.visitHistoryTemplete = lodash.cloneDeep(
            this.categoryList.filter(
              (item) => item.categoryType === this.InqType.VisitHistory
            )
          );
          window.removeEventListener('message', eventListenerFtn);
        }
      };
      this.$modal.loading(true);
      window.addEventListener('message', eventListenerFtn);
      window.opener.postMessage({ method: 'needCategoryData' }, '*');
    },
    async getPlaceHistoryInfo() {
      try {
        const response = await this.$request.api_v1_inquiry({
          answerType: this.inquiryType,
          language: this.getLanguage,
        });
        return response.data.result.categoryList.filter(
          (item) => item.categoryType === this.InqType.VisitHistory
        )[0].placeList;
      } catch {
        this.$modal.error();
      }
    },
    async getInquiryInfo() {
      try {
        const response = await this.$request.api_v1_inquiry({
          answerType: this.inquiryType,
          language: this.getLanguage,
        });
        // if (!response.data.success) throw new Error('error');
        this.detailId = response.data.result.detailId;
        this.categoryList = response.data.result.categoryList;
        this.visitHistoryTemplete = lodash.cloneDeep(
          this.categoryList.filter(
            (item) => item.categoryType === this.InqType.VisitHistory
          )
        );
      } catch (error) {
        this.$modal.error();
      }
    },
    async submitPreAnswer() {
      if (this.btnLock) return;
      this.btnLock = true;
      try {
        this.$modal.loading(true);
        let famList = [];
        if (this.getUserType === this.USER.EMP) {
          famList = this.$refs.homemate?.submit();
        }
        const response = await this.$axios.post('/api/pre/v1/nia', {
          identityNo: this.identityNo,
          deviceType: 'M',
          categoryList: this.categoryList,
          answerType: this.answerType,
          visitDate: this.visitDate,
          accompanyId: this.accompanyId,
          completePlace: this.getLanguage === 'ko' || this.getLanguage === 'en',
          detailId: this.detailId,
          inqType: this.inquiryType,
          privacyCode: this.privacyCode,
          sensitiveCode: this.sensitiveCode,
          refId: this.refId,
          partnerEmpId: this.partnerEmpId,
          pid: this.$route.query.pid || null,
          fam1: famList.length > 0 ? famList[0] : null,
          fam2: famList.length > 1 ? famList[1] : null,
          fam3: famList.length > 2 ? famList[2] : null,
          defaultFam: this.$refs.homemate
            ? this.$refs.homemate?.makeNewFam()
            : null,
          kioskType: this.getKioskType,
        });
          switch (response.data.resultCd) {
            case 300:
            case 201:
              this.$router.replace({
                name: 'passForbidenNormal',
                query : {
                   pass: response.data.result.passCheck,                 
                   inqType: window.btoa(this.inquiryType),
                   answerType: window.btoa(this.answerType),
                   wide: 'Y'                  
                 } 
              });
              break;
            case 307:             
            case 0:         
              this.$router.replace({
                 name : 'thankyouNoPass',
                 query : {
                   pass: response.data.result.passCheck,                 
                   inqType: window.btoa(this.inquiryType),
                   answerType: window.btoa(this.answerType),
                   wide: 'Y'                  
                 }                 
              });
              break;
            default:
              throw new Error('정의되지 않은 resultCd 오류');         
        }
      } catch (error) {
        console.log(error);
        this.$router.replace({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_접근입니다'),
          },
        });
      } finally {
        this.btnLock = false;
        this.$modal.loading(false);
        this.popup.inputCompanionPopup = false;
      }
    },
    checkInput() {
      for (const key in this.$refs) {
        if (Object.hasOwnProperty.call(this.$refs, key)) {
          if (!key.includes('inquiry')) continue;
          const inquiryComponent = this.$refs[key][0];
          // 각 컴포넌트 별 데이터 업데이트 실행 후 true 리턴이 되는지 확인
          if (inquiryComponent?.saveData && !inquiryComponent?.saveData()) {
            inquiryComponent.$el.scrollIntoView({ behavior: 'smooth' }); // move scroll
            setTimeout(() => {
              this.$modal.error(
                this.$t('아직_응답하지_않은_질문이_남아있습니다')
              );
            }, 200);
            return false;
          }
        }
      }
      return true;
    },
    submit() {
      if (!this.checkInput()) return; // 데이터 정상 입력 여부 체크
        this.submitPreAnswer();     
    },
    setShow({ targetCategoryId, isShow, turnOffTargetCategoryId }) {
      for (const item of this.categoryList) {
        if (item.categoryId === targetCategoryId) {
          item.show = isShow;
        }
        if (item.categoryId === turnOffTargetCategoryId) {
          item.show = false;
        }
      }
    },
  },
  watch: {
    refreshIdx: function () {
      this.$router.go();
    },
  },
};
</script>
<style scoped="scoped">
.ask blockquote {
  width: 100%;
  line-height: 1.4;
  
  font-weight: 300;
  letter-spacing: 0px;
}
.ask .ask_conts {
  margin-top: 25px;
  min-height: 350px;
}
.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.ask dt {
  margin-bottom: 10px;
  font-size: 1em;
  color: #006769;
  font-weight: bold;
}
.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.btn_no {
  float: left;
  width: 48% !important;
  color: #006769 !important;
  border: 1px solid #006769 !important;
  background: #fff !important;
}
.btn_yes {
  float: left;
  margin-right: 4%;
  width: 48% !important;
  color: #fff !important;
  background-color: #006769 !important;
}
.ask dd li {
  padding: 0 0 15px 15px;
  line-height: 1.3;
  background: url('../../assets/images/ico_dot.svg') no-repeat;
  background-size: 1%;
  background-position: 3px 9px;
}
.ask dd li:last-child {
  padding-bottom: 30px;
}
.ask dd li p {
  font-size: 0.8em;
  line-height: 1.3;
}

 
.btn_area { 
  position: fixed; 
  bottom: 0; 
  left: 0%; 
  padding: 0 6% 20px 6%; 
  width: 100%; 
} 
.btn_area .btn { 
  width: 100%; 
  height: 40px; 
  font-size: 16px; 
  color: #fff; 
  background: #006769; 
  border-radius: 60px; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
  text-align: center; 
  font-family: 'NEXONLv1GothicB'; 
} 
.btn_area blockquote { 
  padding-bottom: 20px; 
} 
.btn_area .outlined { 
  margin-top: 15px; 
  border: 1px solid #006769; 
  color: #006769; 
  background: #ffffff; 
} 
.btn_area .btn:last-child { 
  margin-top: 10px; 
} 
.btn_area.scroll { 
  position: relative; 
  padding: 0 0 20px 0; 
} 
.btn_area.inside { 
  position: relative; 
  padding: 0; 
} 
.wide {
  zoom: 150%;       /*  default */
  margin-top: 15px;
}
.pointer {
  cursor: pointer;
}
</style>