<template>
  <section class="container">
    <div id="header"></div>
    <div class="conts">
      <div class="wapper">
        <div class="error_page">
          <h2 v-html="title"></h2>
          <blockquote v-if="showDetailText" v-html="getDetailText" />
          <!-- <div class="btn_area">
            <div class="btn">처음으로</div>
          </div> -->
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'error',
  props: {
    titleText: {
      type: String,
      default: '',
    },
    showDetailText: {
      type: Boolean,
      default: true,
    },
    detailText: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['getWithTalk']),
    title() {
      return this.titleText === ''
        ? this.$t('유효하지_않은_출입증입니다')
        : this.$t(this.titleText);
    },
    getDetailText() {
      if (this.detailText !== '') return this.detailText;
      if (this.getWithTalk)
        return this.$t(
          '링크를_다시_확인하시거나_대표번호_1688_7575_로_연락해주세요'
        );
      return this.$t('대표번호_1688_7575_로_연락해주세요');
    },
  },
};
</script>