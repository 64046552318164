var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"conts"},[_c('div',{staticClass:"wapper"},[_c('div',{staticClass:"info_page"},[_c('h2',{domProps:{"innerHTML":_vm._s(_vm.$t('문진_기록을_삭제하였습니다'))}}),_c('blockquote',{domProps:{"innerHTML":_vm._s(
            _vm.getWithTalk
              ? _vm.$t(
                  '기존에_받으신_알림톡_링크를_다시_눌러서_문진표를_재작성해주시기_바랍니다'
                )
              : _vm.$t(
                  '문진표를_재작성해주시기_바랍니다'
                )
          )}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"header"}},[_c('div',{staticClass:"btn_home"})])
}]

export { render, staticRenderFns }