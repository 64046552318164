var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',[_vm._v(_vm._s(_vm.$t('동거가족_문진')))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t( '동거가족이_증상_및_장소방문력이_있는_경우_해당_동거가족에_대한_문진을_추가하세요' ))+" ")]),_c('div',{staticClass:"question_list"},_vm._l((_vm.famList),function(contents,contentsIdx){return _c('ul',{key:contentsIdx},[_c('div',{staticClass:"family_chk",class:{ selected: _vm.famListActiveInfo[contentsIdx] },on:{"click":function($event){return _vm.toggleActive(contentsIdx)}}},[_c('div',{staticClass:"chk"}),_vm._v(" "+_vm._s(_vm.$t('동거가족1의_문진', { '0': `${contentsIdx + 1}` }))+" ")]),_c('div',{staticClass:"family_q",style:({
          display: _vm.famListActiveInfo[contentsIdx] ? 'block' : 'none',
        }),attrs:{"id":"family_q_1"}},_vm._l((contents),function(item){return _c('div',{key:item.inquiryMappingId},[(
              item.categoryType === _vm.InqType.HousemateRelation && item.show
            )?_c('Normal',{ref:`inquiry_${contentsIdx}`,refInFor:true,attrs:{"contents":item},on:{"setShow":function($event){return _vm.setShow($event, {
                contentsIdx,
                target: 'isCheckHousemateRelation',
              })}}}):(
              item.categoryType === _vm.InqType.HousemateDetail &&
              _vm.famNeedDiceaseInfo[contentsIdx].isCheckHousemateRelation
            )?_c('ShortAnswer',{ref:`inquiry_${contentsIdx}`,refInFor:true,attrs:{"contents":item}}):(
              item.categoryType === _vm.InqType.Housemate &&
              (_vm.famNeedDiceaseInfo[contentsIdx].isCheckHousemateRelation ||
                _vm.famNeedDiceaseInfo[contentsIdx].isCheckvisitHistory)
            )?_c('MultiChoice',{ref:`inquiry_${contentsIdx}`,refInFor:true,attrs:{"contents":item}}):_vm._e()],1)}),0)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }