<template>
  <section class="container ask">
    <NormalHeader :languageBtn="true" />
    <div class="conts">
      <div class="wapper">
        <h2>{{ $t('긴급_방문력_조사') }}</h2>
        <blockquote>
          <div
            v-if="isEMP"
            v-html="
              $t(
                '본인확인을_위해_문진_알림_메시지를_받으신_분의_사번을_입력해주세요'
              )
            "
          />
          <div
            v-else
            v-html="
              $t(
                '본인확인을_위해_알림_메시지를_받으신_분의_휴대전화_번호_마지막_4자리를_입력해주세요'
              )
            "
          />

          <div
            v-if="!isEMP"
            v-html="$t('예_010_1234_5678_인_경우_5678_입력')"
          />
        </blockquote>
        <div class="ask_conts">
          <dl>
            <dd>
              <div class="num_box">
                <input v-if="isEMP" type="text" readonly :value="identityNo" />
                <input
                  v-else
                  type="number"
                  placeholder="1234"
                  v-model="lastPlcTelno"
                />
              </div>
            </dd>
          </dl>
        </div>
        <!-- 수집 동의 -->
        <Terms
          ref="terms"
          v-model="isTermChecked"
          ruleText
          :pvc="privacyCode"
          :ssc="sensitiveCode"
        />
        <div
          class="btn_area"
          :class="{ disabled: !checkInput }"
          @click="submit"
        >
          <div class="btn">{{ $t('확_인') }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import NormalHeader from '../../components/v2/NormalHeader.vue';
import Terms from '../../components/v2/Terms.vue';
import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'EmerAuth',
  data: () => {
    return {
      // local
      lastPlcTelno: null, //전화번호 마지막 4자리
      isTermChecked: false, // 약관 동의 여부(2개)
      pid: null,
      btnLock: false,

      //query
      identityNo: null,
      // emergencyHistory: null,
      answerType: null,
      // visitDate: null,
      privacyCode: null,
      sensitiveCode: null,
    };
  },
  components: {
    NormalHeader,
    Terms,
  },
  computed: {
    ...mapState(['USER']),
    ...mapGetters(['getUserType']),
    isEMP() {
      return this.answerType === this.USER.EMP;
    },
    checkInput() {
      if (
        !this.isEMP &&
        (this.lastPlcTelno === null || this.lastPlcTelno.length < 4)
      ) {
        return false;
      }
      if (this.btnLock) return false;
      if (!this.isTermChecked) return false;
      return true;
    },
  },
  beforeMount() {
    this.identityNo = this.$route.query?.idn
      ? window.atob(this.$route.query.idn)
      : null;
    // this.emergencyHistory = this.$route.query?.emh ?? null;
    this.answerType = this.$route.query?.aid
      ? window.atob(this.$route.query.aid)
      : null;
    // this.visitDate = this.$route.query?.vd
    //   ? window.atob(this.$route.query.vd)
    //   : null;
    this.pid = this.$route.query?.pid ?? null;
    this.privacyCode = this.$route.query?.pvc
      ? window.atob(this.$route.query.pvc)
      : null;
    this.sensitiveCode = this.$route.query?.ssc
      ? window.atob(this.$route.query.ssc)
      : null;
  },
  methods: {
    ...mapMutations(['setUserType']),
    async submit() {
      if (this.btnLock) return;
      this.btnLock = true;
      try {
        this.$modal.loading(true);
        const param =
          this.answerType === this.USER.EMP
            ? {
                matchValue: this.identityNo,
                pid: this.pid,
              }
            : {
                matchValue: this.lastPlcTelno,
                pid: this.pid,
              };

        const response = await this.$axios.get('/api/v1/inquiry-pid', {
          params: param,
        });
        if (response.data.resultCd === 0) {
          this.$router.replace({
            name: 'emerInvestigation',
            params: {
              checkPid: true, // 정상적으로 PID 체크되었음을 표시
            },
            query: {
              ...this.$route.query,
            },
          });
        } else if (response.data.resultCd === 102) {
          // 잘못된 PID
        } else if (response.data.resultCd === 101) {
          // 번호 틀렸음
          if (this.isEMP) {
            // 직원번호 오류에 대한 에러 팝업
            this.$modal.error(this.$t('입력하신_사번이_확인되지_않습니다'));
          } else {
            // 휴대폰번호 뒷자리 오류에 대한 에러 팝업
            this.$modal.error(
              this.$t(
                '휴대전화_번호가_일치하지_않습니다_휴대전화_번호를_한번_더_확인해주세요'
              )
            );
            this.lastPlcTelno = '';
          }
        } else if (response.data.resultCd === 305) {
          // 만료된 문진 (사전에 emh 유효성 체크를 하고 오므로 발생되지는 않는 케이스)
          this.$router.replace({
            name: 'error',
            params: {
              titleText: this.$t('출입증_발급기한이_만료되었습니다'),
              showDetailText: false,
            },
          });
        } else throw new Error('알수없는 오류 발생');
      } catch (error) {
        console.log(error);
        this.$router.replace({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_접근입니다'),
          },
        });
      } finally {
        this.btnLock = false;
        this.$modal.loading(false);
      }
    },
  },
  watch: {
    lastPlcTelno: function (newVal) {
      // 숫자만 최대 4자리
      this.lastPlcTelno = newVal.substring(0, 4).replace(/[^0-9]/gi, '');
    },
  },
};
</script>

<style lang="scss" scoped>
.num_box {
  input[type='text'] {
    width: 100%;
    margin-top: 20px;
    color: #333333;
    font-size: 20px;
    border: none;
    border-bottom: 2px solid #cccccc !important;
    background: #f6f6f6;
    text-align: center;
  }
}
.container {
  min-height: 600px;
}
</style>