<template>
  <div class="pop_bg" style="display: block">
    <div id="popup_yes" class="pop pop_md">
      <!-- 유효성 검사 팝업 -->
      <div v-if="userList.length === 1" class="pop_head type2">
        {{ isPT ? $t('본인_확인') : $t('환자_확인') }}
        <p>
          {{
            isPT
              ? $t('본인이_맞는지_확인해주세요')
              : $t('환자_정보를_확인해주세요')
          }}
        </p>
      </div>
      <div v-else class="pop_head type2">{{ $t('이름을_선택해주세요') }}</div>
      <div class="pop_body">
        <div class="list_name">
          <dl
            v-for="(item, index) in userList"
            :key="item.patNum"
            :class="{
              radio: userList.length > 1,
              select: item.patNum === selectedPatNum,
            }"
            @click="selectItem($event, index)"
          >
            <dt>{{ maskingName(item.ptName) }}</dt>
            <dd>
              {{ $t('생년월일_', { '0': `${maskingBirth(item.birth)}` }) }}
              <br />
              {{
                $t('병원등록번호_', {
                  '0': `${maskingPatientId(item.patNum)}`,
                })
              }}
            </dd>
          </dl>
        </div>
      </div>
      <!-- 버튼 -->
      <div class="btn_area scroll">
        <div class="btn" @click="okBtn">{{ $t('다음') }}</div>
        <div class="btn outlined" @click="closePop">{{ $t('다시_입력') }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
export default {
  props: {
    userList: Array,
    byPid: Boolean,
  },
  data: () => {
    return { selectedPatNum: null };
  },
  computed: {
    ...mapState(['USER']),
    ...mapGetters(['getUserType', 'isRTL']),
    isPT() {
      return (
        this.getUserType === this.USER.PT || this.getUserType === this.USER.EMMA
      );
    },
  },
  mounted() {
    this.selectedPatNum = this.userList[0]?.patNum;
  },
  methods: {
    maskingPatientId(str) {
      if (!this.byPid) {
        return this.isRTL ? `${str.slice(4)}****` : `****${str.slice(4)}`; // 처음 4자리 *
      }
      return str;
    },
    maskingName(str) {
      if (this.byPid || (!this.byPid && this.userList.length < 2)) {
        return this.isRTL ? `*${str.slice(0, -1)}` : `${str.slice(0, -1)}*`; // 마지막한자리 *
      }
      return str;
    },
    maskingBirth(str) {
      if (!str) return '';
      if (this.byPid) {
        return this.isRTL
          ? `**-${moment(str).format('YYYY-MM')}`
          : `${moment(str).format('YYYY-MM-DD').slice(0, -2)}**`;
      }
      return moment(str).format('YYYY-MM-DD');
    },
    selectItem(_e, index) {
      this.selectedPatNum = this.userList[index].patNum;
    },
    okBtn() {
      this.$emit('okBtn', this.selectedPatNum);
    },
    closePop() {
      this.$emit('closePopup');
    },
  },
};
</script>
<style scoped="scoped">
.ask blockquote {
  width: 100%;
  line-height: 1.4;
  
  font-weight: 300;
  letter-spacing: 0px;
}
.ask .ask_conts {
  margin-top: 25px;
  min-height: 350px;
}
.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.ask dt {
  margin-bottom: 10px;
  font-size: 1em;
  color: #006769;
  font-weight: bold;
}
.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.btn_no {
  float: left;
  width: 48% !important;
  color: #006769 !important;
  border: 1px solid #006769 !important;
  background: #fff !important;
}
.btn_yes {
  float: left;
  margin-right: 4%;
  width: 48% !important;
  color: #fff !important;
  background-color: #006769 !important;
}
.ask dd li {
  padding: 0 0 15px 15px;
  line-height: 1.3;
  background: url('../../../assets/images/ico_dot.svg') no-repeat;
  background-size: 1%;
  background-position: 3px 9px;
}
.ask dd li:last-child {
  padding-bottom: 30px;
}
.ask dd li p {
  font-size: 0.8em;
  line-height: 1.3;
}

.error_page h2 {
  height: 110px;
  line-height: 70px;
  padding-top: 30px;
  background: url('../../../assets/images/ico_error.svg') no-repeat;
}

.btn_area {
  position: fixed;
  bottom: 0;
  left: 0%;
  padding: 0 6% 20px 6%;
  width: 100%;
}
.btn_area .btn {
  width: 100%;
  height: 40px;
  font-size: 1em;
  color: #fff;
  background: #006769;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'NEXONLv1GothicB';
}
.btn_area blockquote {
  padding-bottom: 20px;
}
.btn_area .btn {
  width: 100%;
  font-size: 1em;
}
.btn_area .outlined {
  margin-top: 15px;
  border: 1px solid #006769;
  color: #006769;
  background: #ffffff;
}
.btn_area button:last-child {
  margin-top: 10px;
}

.privacy_wrap {
  position: absolute;
  bottom: 60px;
  width: 90%;
  padding: 10px 0 20px 0;
  color: #222222;
}
.privacy_wrap.relative {
  position: relative;
  width: 100%;
  padding: 0 20px 20px 20px;
}
.privacy_wrap li {
  position: relative;
  padding: 5px;
}
.privacy_wrap .chk a {
  position: absolute;
  top: 0px;
  right: 0;
  text-decoration: underline;
  font-size: 0.8em;
}
.privacy_wrap .chk {
  position: relative;
  width: 100%;
  padding-left: 30px;
  background: url(../../../assets/images/ico_pri_unchecked.svg) no-repeat 0 0;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}
.privacy_wrap .chk.checked {
  background: url(../../../assets/images/ico_pri_check.svg) no-repeat 0 0;
  display: inline-block;
}

.pop_bg {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  background: rgb(0, 0, 0, 0.8);
}
.pop {
  position: absolute;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.2);
  z-index: 100;
}
.pop .btn_pop_close {
  position: absolute;
  top: 15px;
  right: 20px;
  width: 20px;
  height: 20px;
  background: url('../../../assets/images/btn_p_close.svg') no-repeat;
  overflow: hidden;
  text-indent: -1000px;
  display: inline-block;
}
.pop h2 {
  margin-top: 0px;
  margin-bottom: 0;
  font-size: 1em;
  text-align: left;
  color: #393939;
  height: 33px;
  font-weight: 500;
}

.pop .pop_body {
  padding: 20px 20px 20px 20px;
  width: 100%;
  height: 100%;
  text-align: left;
  overflow: hidden;
}

.pop .list_name dl {
  min-height: auto;
  margin-bottom: 20px;
}
.pop .list_name dl:last-child {
  margin-bottom: 0;
}
.pop .list_name .radio dt {
  padding-left: 25px;
  margin-bottom: 0px;
  font-size: 1em;
  background: url(../../../assets/images/ico_radio_unchk.svg) no-repeat left;
  cursor: pointer;
  display: block;
  vertical-align: middle;
  color: #666666;
}
.pop .list_name .radio dd {
  padding-left: 25px;
}
.pop .list_name .radio.select dt {
  margin-bottom: 0px;
  font-size: 1em;
  background: url(../../../assets/images/ico_radio_chk.svg) no-repeat left;
  display: block;
  color: #006769;
}
.pop .list_name dd {
  padding-left: 0px;
}
.pop .list_name input[type='radio'] {
  display: none;
}
.pop .list_name input[type='radio'] + label {
  background: url(../../../assets/images/ico_radio_unchk.svg) no-repeat left;
  cursor: pointer;
  display: block;
  vertical-align: middle;
}
.pop .list_name input[type='radio']:checked + label {
  background: url(../../../assets/images/ico_radio_chk.svg) no-repeat left;
  display: block;
}
.pop .list_name input[type='radio'] + label span {
  padding: 0 0 0 25px;
  width: 100%;
  display: block;
}
.pop .btn_area {
  text-align: center;
}
.pop .btn_area.scroll {
  position: relative;
}
</style>