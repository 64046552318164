<template>
  <section class="container ask">
    <NormalHeader :languageBtn="true"/>
    <div class="conts">
      <div class="wapper">
        <h2>{{ $t('출입등록') }}</h2>
        <blockquote
            v-html="
            $t(
              '안녕하세요_출입등록을_위해서는_아래_개인정보_및_민감정보_수집_내용을_확인하고_동의해야_합니다'
            )
          "
        />
        <!-- <div class="ask_conts">
          <dl>
            <dd>
              <div class="num_box">
                <input
                  type="number"
                  placeholder="12345678"
                  v-model="identityNo"
                  :readonly="inputLock"
                />
              </div>
            </dd>
          </dl>
        </div> -->
        <!-- 수집 동의 -->
        <AgreeTerms ref="terms" @input="checkNext" />
        <!-- <Terms ref="terms" v-model="isTermChecked"/> -->
      </div>
      <div class="btn_area">
        <div class="btn" :class="{ disabled: !canNext }" @click="submit">
          {{ $t('확_인') }}
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import NormalHeader from '../../components/v2/NormalHeader.vue';
import AgreeTerms from '../../components/v2/AgreeTerms.vue';

// import Terms from '../../components/v2/Terms.vue';
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import moment from 'moment';

export default {
  components: {
    // Terms,
    NormalHeader,
    AgreeTerms,
  },
  data: () => {
    return {
      inqType: null,
      isTermChecked: false, // 약관 동의 여부(2개)
      identityNo: null,
      visitDate: null,
      inputLock: false, // identityNo 값을 URL에 가지고 들어오면 Lock
      btnLock: false,
      refId: null,
    };
  },
  computed: {
    ...mapState(['USER']),
    ...mapGetters(['getUserType', 'getInquiryYn', 'getLanguage', 'getKioskType', 'getCnnHome']),
    canNext: function () {
      return (
          // 전화번호 정상 여부 확인
          this.identityNo !== null &&
          this.identityNo !== '' &&
          this.identityNo.length > 7 &&
          this.identityNo.length < 10 &&
          // 약관 동의 여부확인
          this.isTermChecked
      );
    },
  },
  async mounted() {
    if (this.getUserType === null) this.setUserType(this.USER.RNOK);
    const res = await this.InquiryUseCheck();
    this.inqType = res.data.result.masterId

    // ptno =
    this.identityNo = this.$route.query.ptno
        ? window.atob(this.$route.query.ptno)
        : null;
    if (this.identityNo) this.inputLock = true;
    // vd = MjAyMTA3Mjg=
    this.visitDate = this.$route.query.vd
        ? window.atob(this.$route.query.vd)
        : null;
    if (this.visitDate < moment().format('YYYYMMDD')) {
      this.$router.replace({name: 'error'});
    }
  },
  watch: {
    identityNo: function (newVal) {
      this.identityNo = newVal.replace(/[^0-9aA]/g, '').substring(0, 9);
    },
  },
  methods: {
    ...mapMutations(['setUserType']),
    ...mapActions(['InquiryUseCheck']),
    async submit() {
      if (!this.canNext || this.btnLock) return;
      this.btnLock = true;

      try {
        const response = await this.$axios.post('/api/v1/identity/rnok', {
          identityNo: this.identityNo,
          answerType: this.getUserType,
          visitDate: this.visitDate,
        });
        // if (!response.data.success) throw new Error('error');
        // const { sensitiveCheck } = this.$refs.terms.getSensitiveChecked();

        switch (response.data.resultCd) {
          case 200:
          case 201:
            // 201 - 증상있음 출입 금지. (emergencyHistory가 있으면 긴급방문 작성하러 이동해야 함)
            // 200 - 방문일에 해당하는 문진을 이미 완료했을 경우 (emergencyHistory가 있으면 긴급방문 작성하러 이동해야 함)
            // 방문일에 해당하는 문진을 이미 완료한 경우 thankyou 페이지로 이동
            this.$router.replace({
              name: 'thankyou',
              query: {
                pass: response.data.result.passCheck,
              },
            });
            break;
          case 0:
            // if (!sensitiveCheck){
            //   this.$modal.error(
            //     `${this.$t(
            //       "개인정보_및_민감정보_수집_이용에_동의_필수_해주시기_바랍니다."
            //     )}`
            //   );
            // } else {
            //   this.useInquiry(response.data.result, privacyCode, sensitiveCode);
            // }
            if(this.getInquiryYn) {
              this.useInquiry();
            } else {
              await this.noInquiry();
            }
            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        console.log(error);
        this.$router.replace({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_접근입니다'),
          },
        });
      } finally {
        this.btnLock = false;
      }
    },
    checkNext(status) {
      this.isTermChecked = status
    },
    useInquiry(){  // 문진 사용
        const {
          privacyCode,
          sensitiveCode
        } = this.$refs.terms.getTermsCode();

        this.$router.push({
          name: 'inquiry',
          query: {
            type: window.btoa(this.getUserType),
            itype: window.btoa(this.inqType),
            idn: window.btoa(this.identityNo),
            vd: window.btoa(this.visitDate),
            pid: this.$route.query.pid,
            pvc: window.btoa(privacyCode),
            ssc: window.btoa(sensitiveCode),
            pidn: this.refId ?  window.btoa(this.refId) : null,
          },
        });
      },
      async noInquiry(){  // 문진 미사용
        try {
          this.$modal.loading(true);
          let conn = this.getCnnHome;
          const {
            privacyCode,
            sensitiveCode
          } = this.$refs.terms.getTermsCode();
          const response = await this.$axios.post('/api/pre/v1/no-answer', {
            identityNo: this.identityNo,
            deviceType: 'M',
            answerType: this.getUserType,
            visitDate: this.visitDate,
            refId: this.refId,
            completePlace: this.getLanguage === 'ko' || this.getLanguage === 'en',
            inqType: this.inqType,
            privacyCode: privacyCode,
            sensitiveCode: sensitiveCode,
            partnerEmpId: this.partnerEmpId? this.partnerEmpId : null,
            pid: this.$route.query.pid || null,
            kioskType: this.getKioskType,
            conn: conn ? conn : null,
          });

          switch (response.data.resultCd) {
            case 300:
            case 201:
              this.$router.replace({
                name: 'thankyou',
                query: {
                  pass: response.data.result.passCheck,
                },
              });
              break;
            case 0:
              // 출입증 발급
              this.$router.replace({
                name: 'pass',
                query: {
                  pass: response.data.result.passCheck,
                  isDirect: 'N',
                  inqType: this.inqType
                },
              });
              break;
            default:
              throw new Error('정의되지 않은 resultCd 오류');
          }
        } catch (error) {
          this.$router.replace({
            name: 'error',
            params: {
              titleText: this.$t('유효하지_않은_접근입니다'),
            },
          });
        } finally {
          this.$modal.loading(false);
        }
      },
  },
};
</script>
<style scoped>
.num_box input[type='number'] {
  width: 100%;
  margin-top: 20px;
  font-size: 1.4em;
  border: none;
  border-bottom: 2px solid #cccccc !important;
  text-align: center;
  font-family: 'NEXONLv1GothicB';
  background: #f6f6f6;
  color: #333333;
}

.num_box input[type='number']::placeholder {
  color: #ccc;
}

.num_box input[type='number']:focus {
  color: #333333;
  border-bottom: 2px solid #509d9c !important;
}

.ask blockquote {
  width: 100%;
  line-height: 1.4;

  font-weight: 300;
  letter-spacing: 0px;
}

.ask .ask_conts {
  margin-top: 25px;
  min-height: 350px;
}

.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}

.ask dt {
  margin-bottom: 10px;
  font-size: 1em;
  color: #006769;
  font-weight: bold;
}

.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}

.btn_no {
  float: left;
  width: 48% !important;
  color: #006769 !important;
  border: 1px solid #006769 !important;
  background: #fff !important;
}

.btn_yes {
  float: left;
  margin-right: 4%;
  width: 48% !important;
  color: #fff !important;
  background-color: #006769 !important;
}

.ask dd li {
  padding: 0 0 15px 15px;
  line-height: 1.3;
  background: url('../../assets/images/ico_dot.svg') no-repeat;
  background-size: 1%;
  background-position: 3px 9px;
}

.ask dd li:last-child {
  padding-bottom: 30px;
}

.ask dd li p {
  font-size: 0.8em;
  line-height: 1.3;
}

.error_page h2 {
  height: 110px;
  line-height: 70px;
  padding-top: 30px;
  background: url('../../assets/images/ico_error.svg') no-repeat;
}

.btn_area .outlined {
  margin-top: 15px;
  border: 1px solid #006769;
  color: #006769;
  background: #ffffff;
}

.btn_area .btn:last-child {
  margin-top: 10px;
}
</style>