var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v(" "+_vm._s(_vm.questionContents)+" "),_c('p',[_vm._v(" "+_vm._s(_vm.categoryDesc)+" ")])]),_c('ul',[_vm._l((_vm.contents.inquiryList),function(item){return _c('li',{key:item.inquiryMappingId,class:{ selected: _vm.isIndvdCheck && item.check, pointer : _vm.$route.query.wide == 'Y' },on:{"click":function($event){return _vm.onClick($event, item)}}},[_vm._v(" "+_vm._s(item.langContents || item.inquiryContents)+" "),(_vm.isIndvdCheck)?_c('div',{staticClass:"chk"}):_vm._e()])}),(_vm.isIndvdCheck && !_vm.isNeedAnswer)?_c('li',{staticClass:"btn_area"},[_c('div',{class:[
          !_vm.initState && !_vm.existCheckedContents ? 'btn_chk' : 'btn_unchk',
          _vm.$route.query.wide?  'pointer' : _vm.$route.query.wide == 'Y'
        ],on:{"click":function($event){return _vm.allCheck(false)}}},[_c('i'),_vm._v(" "+_vm._s(_vm.$t('해당없음'))+" ")])]):(!_vm.isIndvdCheck)?_c('li',{staticClass:"btn_area type2"},[_c('div',{staticClass:"btn",class:{ outlined: _vm.initState || !_vm.existCheckedContents,
                  chked: _vm.normalYes
                  },on:{"click":function($event){return _vm.allCheck(true)}}},[_c('i'),_vm._v(" "+_vm._s(_vm.$t('해당있음'))+" ")]),_c('div',{staticClass:"btn",class:{
          outlined: _vm.initState || _vm.existCheckedContents || _vm.isNeedAnswer || _vm.normalYes,
          disabled: _vm.isNeedAnswer,
        },on:{"click":() => !_vm.isNeedAnswer && _vm.allCheck(false)}},[_c('i'),_vm._v(" "+_vm._s(_vm.$t('해당없음'))+" ")])]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }