<template>
  <section class="container ask">
    <NormalHeader :languageBtn="true" />
    <pid-check v-if="!pidCheck" :identityNo="identityNo" @check="setPidCheck" />
    <div v-else class="conts">
      <div class="wapper">
        <h2>{{ $t('출입등록') }}</h2>
        <blockquote v-html="$t('본인의_휴대전화_번호를_입력해주세요')" />
        <div class="ask_conts">
          <dl>
            <dd>
              <div class="num_box">
                <input
                  type="number"
                  placeholder="01012345678"
                  pattern="\d*"
                  maxlength="11"
                  v-model="identityNo"
                  :readonly="inputLock"
                  @keyup.enter="submit"
                />
              </div>
            </dd>
          </dl>
        </div>
        <!-- 수집 동의 -->
        <!-- <Terms ref="terms" v-model="isTermChecked" /> -->
      </div>
      <div class="btn_area">
        <div class="btn" :class="{ disabled: !canNext }" @click="submit">
          {{ $t('확_인') }}
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import NormalHeader from '../../components/v2/NormalHeader.vue';
// import Terms from '../../components/v2/Terms.vue';
import PidCheck from '../../components/v2/PidCheck.vue';
import { mapGetters, mapState, mapMutations} from 'vuex';
import moment from 'moment';

export default {
  name: 'Guest_New',
  components: {
    // Terms,
    NormalHeader,
    PidCheck,
  },
  data: () => {
    return {
      isTermChecked: true, // 약관 동의 여부(2개)
      identityNo: null,
      meetingId: null,
      inputLock: false, // identityNo 값을 URL에 가지고 들어오면 Lock
      btnLock: false,
      visitDate: null,
      openYn: 'N',
      pidCheck: false,
      meetingInfo: null,
      startDateTime: null,
    };
  },
  computed: {
    ...mapState(['USER']),
    ...mapGetters(['getUserType', 'getWithTalk', 'getLanguage','getCnnHome']),
    canNext: function () {
      return (
        // 전화번호 정상 여부 확인
        this.identityNo !== null &&
        this.identityNo !== '' &&
        this.identityNo.length > 9 &&
        // 약관 동의 여부확인
        this.isTermChecked
      );
    },
  },
  async beforeMount() {
    // 1. 비공개행사 방문자 링크
    // test URL : http://localhost:8080/#/gst/grpo?phone=MDEwNzMwMDMzMTE=&code=6a6277f9-3d5e-41f4-ae3e-1b3b84f9446e
    // 2. 기타방문자
    // test URL : http://localhost:8080/#/gst/grpo?phone=MDEwNzMwMDMzMTA=&vd=MjAyMTEwMjI=
    // idn = MDEwNzMwMDMzMTA= (01073003310)
    // 3. 공개집합행사 참석링크
    // test URL : http://localhost:8080/#/gst/grpo?code=3b148eb7-4ca4-46d6-b562-1bb91c269fed
    this.identityNo = this.$route.query.phone
      ? window.atob(this.$route.query.phone)
      : null;
    if (this.identityNo) this.inputLock = true;
    // code = 66f4e0c6-1220-494b-820d-99821a19e707
    this.meetingId = this.$route.query.code ?? null;

    this.visitDate = this.$route.query.vd
      ? window.atob(this.$route.query.vd)
      : null;
    if (this.visitDate && this.visitDate < moment().format('YYYYMMDD')) {
      this.$router.replace({ name: 'error' });
    }

    // userType 구분
    if (
      this.identityNo !== null &&
      this.meetingId === null &&
      this.visitDate !== null
    ) {
      // 기타 방문자(일반)
      this.setUserType(this.USER.TMP);
    } else if (
      this.identityNo !== null &&
      this.meetingId !== null &&
      this.visitDate === null
    ) {
      // 비공개 집합행사 방문자
      this.setUserType(this.USER.GST);
      await this.checkMeetingInfo(this.meetingId);
    } else if (
      this.identityNo === null &&
      this.meetingId !== null &&
      this.visitDate === null
    ) {
      // 공개 집합행사 방문자
      this.setUserType(this.USER.GST);
      this.openYn = 'Y';
      await this.checkMeetingInfo(this.meetingId);
      this.pidCheck = true;
      // this.visitDate = moment().format('YYYYMMDD');
    } else if (
      this.identityNo === null &&
      this.meetingId === null &&
      this.visitDate !== null
    ) {
      // 홈화면으로 진입한 케이스 (GST 이며, 공개행사 참석 예정자로 분류해야함)
      this.setUserType(this.USER.GST);
      if(this.getCnnHome ==='C'){
        this.setWithTalk(true);
      }else{
        this.setWithTalk(false);
      }      
      this.openYn = 'Y';
      this.pidCheck = true;
      
    } else {
      this.$router.replace({ name: 'error' });
    }
  },
  watch: {
    identityNo: function (newVal) {
      this.identityNo = newVal.replace(/[^0-9]/g, '').substring(0, 11);
    },
  },
  methods: {
    ...mapMutations(['setUserType', 'setWithTalk']),

    setPidCheck() {
      this.pidCheck = true;
    },
    async checkMeetingInfo(meetingId) {
      if (this.btnLock) return;
      try {
        this.btnLock = true;
        this.$modal.loading(true);
        const response = await this.$axios.post('/api/v2/identity/gst-list-id', {
          meetingId,
          openYn: this.openYn,
        });

        switch (response.data.resultCd) {
          case 300:
            // 조회된 행사 없음
            this.$router.push({
              name: 'error',
              params: {
                titleText: this.$t('유효하지_않은_접근입니다'),
              },
            });
            break;
          case 0:
            if (response.data.result?.length > 0) {
              // 문진작성으로 이동
              this.meetingInfo = response.data.result[0];
              // 행사시작 전일 16시부터 작성 가능
              this.startDateTime = moment(this.meetingInfo.startDate)
                .subtract(1, 'days')
                .hour(16)
                .minute(0)
                .second(0);
              const endTime = moment(this.meetingInfo.endDate);
              const now = moment();
              if (now < this.startDateTime) {
                // 집합행사 기간 이전
                const detailText =
                  this.getLanguage === 'ko'
                    ? this.$t(
                        '출입증_발급은_아래_시간_이후_가능합니다_2021_10_19_12_10',
                        {
                          0: `[${this.startDateTime.format(
                            'YYYY. MM. DD (ddd)'
                          )}] ${this.startDateTime.format('A h')}시`,
                        }
                      )
                    : this.$t(
                        '출입증_발급은_아래_시간_이후_가능합니다_2021_10_19_12_10',
                        { 0: this.startDateTime.format('YYYY-MM-DD HH:MM') }
                      );

                await this.$router.replace({
                  name: 'error',
                  params: {
                    titleText: this.$t('아직_출입증_발급기간이_아닙니다'),
                    showDetailText: true,
                    detailText,
                  },
                });
              } else if (now > endTime) {
                // 집합행사 기간 이후
                await this.$router.replace({
                  name: 'error',
                  params: {
                    titleText: this.$t('출입증_발급기한이_만료되었습니다'),
                    showDetailText: true,
                    detailText: this.$t('행사가_종료되었습니다'),
                  },
                });
              }
              // 집합행사 종료 후
            } else throw new Error('알수없는 에러');

            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        console.log(error);
        this.$router.push({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_접근입니다'),
          },
        });
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    goNextETC() {
      // 기타방문자 방문목적 선택 페이지로 이동
      // const { privacyCode, sensitiveCode } = this.$refs.terms.getTermsCode();
      this.$router.push({
        name: 'guestEtc',
        params: {
          identityNo: this.identityNo,
          privacyCode: null,
          sensitiveCode: null,
          visitDate: this.visitDate,
        },
      });
    },
    async goNextGST() {
      if (this.btnLock) return;
      try {
        this.btnLock = true;
        this.$modal.loading(true);
        const response = await this.$axios.post('/api/v2/identity/gst-list', {
          meetingId: this.meetingId || null,
          openYn: this.openYn,
          visitDate: this.visitDate,
        });

        // if (!response.data.success) throw new Error('error');
        // const { privacyCode, sensitiveCode } = this.$refs.terms.getTermsCode();

        switch (response.data.resultCd) {
          case 300:
            // 조회된 행사 없음
            this.$router.push({
              name: 'guestPhone',
              params: {
                identityNo: this.identityNo,
                privacyCode:null,
                sensitiveCode:null,
                meetingInfo: [],
              },
              query: {
                vd: window.btoa(this.visitDate),
              },
            });
            break;
          case 0:
            if (response.data.result?.length > 0) {
              //visitDate가 null이면서 행사 시작일 전이면 visitDate를 행사 시작일로 세팅.
              if(this.visitDate == null) {        
                  let meetingStart = moment(this.meetingInfo.startDate);
                  let now = moment();
                  if(now.isBefore(meetingStart)) {                    
                    if( moment.duration(meetingStart.diff(now)).asDays() < 1) {
                      this.visitDate = moment(this.meetingInfo.startDate).format('YYYYMMDD');
                    }
                  }                 
              }              
              
              if(this.visitDate != null) {
                  this.$router.push({
                  name: 'guestPhone',
                  params: {
                    identityNo: this.identityNo,
                    privacyCod:null,
                    sensitiveCode:null,
                    meetingInfo: response.data.result,
                  },
                  query: {
                    vd:  window.btoa(this.visitDate),
                  },
                });
              } else {
                this.$router.push({
                  name: 'guestPhone',
                  params: {
                    identityNo: this.identityNo,
                    privacyCod:null,
                    sensitiveCode:null,
                    meetingInfo: response.data.result,
                  }
                }); 

              }
              
            }              
            else {throw new Error('알수없는 에러');}

            break;
          default:
            throw new Error('정의되지 않은 resultCd 오류');
        }
      } catch (error) {
        console.log(error);
        this.$router.push({
          name: 'error',
          params: {
            titleText: this.$t('유효하지_않은_접근입니다'),
          },
        });
      } finally {
        this.$modal.loading(false);
        this.btnLock = false;
      }
    },
    submit() {
      if (!this.canNext || this.btnLock) return;

      if (this.getUserType === this.USER.TMP) this.goNextETC();
      else if (this.getUserType === this.USER.GST) this.goNextGST();
    },
  },
};
</script>
<style scoped>
.num_box input[type='number'] {
  width: 100%;
  margin-top: 20px;
  font-size: 1.4em;
  border: none;
  border-bottom: 2px solid #cccccc !important;
  text-align: center;
  font-family: 'NEXONLv1GothicB';
  background: #f6f6f6;
  color: #333333;
}
.num_box input[type='number']::placeholder {
  color: #ccc;
}
.num_box input[type='number']:focus {
  color: #333333;
  border-bottom: 2px solid #509d9c !important;
}
.ask blockquote {
  width: 100%;
  line-height: 1.4;

  font-weight: 300;
  letter-spacing: 0px;
}
.ask .ask_conts {
  margin-top: 25px;
  min-height: 350px;
}
.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.ask dt {
  margin-bottom: 10px;
  font-size: 1em;
  color: #006769;
  font-weight: bold;
}
.ask dl {
  width: 100%;
  min-height: 160px;
  margin-bottom: 25px;
  border-radius: 11px;
  overflow: hidden;
}
.btn_no {
  float: left;
  width: 48% !important;
  color: #006769 !important;
  border: 1px solid #006769 !important;
  background: #fff !important;
}
.btn_yes {
  float: left;
  margin-right: 4%;
  width: 48% !important;
  color: #fff !important;
  background-color: #006769 !important;
}
.ask dd li {
  padding: 0 0 15px 15px;
  line-height: 1.3;
  background: url('../../assets/images/ico_dot.svg') no-repeat;
  background-size: 1%;
  background-position: 3px 9px;
}
.ask dd li:last-child {
  padding-bottom: 30px;
}
.ask dd li p {
  font-size: 0.8em;
  line-height: 1.3;
}

.error_page h2 {
  height: 110px;
  line-height: 70px;
  padding-top: 30px;
  background: url('../../assets/images/ico_error.svg') no-repeat;
}
.btn_area .outlined {
  margin-top: 15px;
  border: 1px solid #006769;
  color: #006769;
  background: #ffffff;
}
.btn_area .btn:last-child {
  margin-top: 10px;
}
</style>